import React, { useState, useEffect } from 'react';
import { Table, Popover, OverlayTrigger, Spinner, Pagination, Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import DatePicker from 'react-datepicker';
import { startOfDay, endOfDay } from 'date-fns';
import { FaFilter, FaPlus, FaBan } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { Label } from '../../../../helpers/labels/labelStyles';
import { Container, Content, FiltroButton, ArquivoButton, BloqueadoButton, InputsDiv, Input, AplicarButton, LimparFiltro, DateDiv, LimitsDiv, TableDiv, RowFilter } from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiCidadetran from '../../../../services/cidadetran';
import { formatDateBarWithHours, formatPrice } from '../../../../helpers/format';


function Transacoes({ history }) {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageActive, setPageActive] = useState(1);
    const [limitActive, setLimitActive] = useState(25);
    const [totalCount, setTotalCount] = useState(0);

    //filters
    const [filters, setFilters] = useState("");
    const [protocol, setProtocol] = useState("");
    const [name, setName] = useState("");
    const [startDatePicker, setStartDatePicker] = useState();
    const [endDatePicker, setEndDatePicker] = useState("");
    const filtroInicial = `&dt_ini=${startOfDay(new Date()).toISOString()}&dt_fin=${endOfDay(new Date()).toISOString()}`;

    useEffect(() => {
        // primeiro loading
        setFilters(filtroInicial);
        loadData(filtroInicial, pageActive, limitActive);
        //eslint-disable-next-line
    }, [])

    function aplicarFiltros() {
        setLoading(1);

        let filter = "";

        filter += name ? `&name=${name}` : ''
        filter += protocol ? `&protocol=${protocol}` : ''
        filter += startDatePicker && endDatePicker ? `&dt_ini=${startDatePicker.toISOString()}&dt_fin=${endOfDay(endDatePicker).toISOString()}` : ''

        setFilters(filter);
        setPageActive(1);
        loadData(filter, 1, limitActive);
    }

    function gerarArquivo (tipo) {
        Swal.fire({
            title: "Gerar Arquivo",
            html: tipo === 'past' ? 'Entrarão todos as transações que devem ser pagas, EXCETO as de hoje. Confirma?' : 'Entrarão todas as transações que devem ser pagas, INCLUINDO as de hoje. Confirma?',
            showCancelButton: true,
            confirmButtonText: `Gerar`,
            cancelButtonText: `Cancelar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiCidadetran.get('/cidadetran?type=' + tipo,
                    { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                        Swal.showValidationMessage('Já existe um arquivo sendo gerado. Por favor aguarde alguns minutos.')
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'O Arquivo está sendo gerado!',
                    'Aguarde alguns minutos e recarregue a página',
                    'success'
                )
            }
        })
    }

    function limparFiltros() {
        setFilters("");
        setName("");
        setProtocol("");
        setStartDatePicker("");
        setEndDatePicker("");
        setPageActive(1);
        loadData(filtroInicial, 1, limitActive);
    }

    async function loadData(filters, pageNumber, limit) {
        setLoading(1);
        const res = await apiCidadetran.get(`/cidadetran/records?limit=${limit}&page=${pageNumber}${filters}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e => Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao buscar dados!',
            }));

        if (res && res.data && res.data.records) {
            setRecords(res.data.records);
            setTotalCount(res.data.totalCount);
            const pagesData = [];
            const pagesLimit = Math.ceil(res.data.totalCount / res.data.limit)
            for (let i = 1; i <= pagesLimit; i++) {
                pagesData.push(<Pagination.Item
                    key={i}
                    active={pageNumber === i}
                    onClick={() => {
                        setPageActive(i);
                        loadData(filters, i, limit);
                    }}>{i}</Pagination.Item>)
            }
            setPages(pagesData);
        }
        setLoading(0);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Filtros</Popover.Title>
            <Popover.Content>
                <Input value={name} onChange={e => setName(e.target.value)} placeholder="Nome do arquivo" />
                <Input value={protocol} onChange={e => setProtocol(e.target.value)} placeholder="Protocolo ou Renavam" />
                <InputsDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Início" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={startDatePicker} onChange={date => setStartDatePicker(date)} />
                    </DateDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Fim" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDatePicker} onChange={date => setEndDatePicker(date)} />
                    </DateDiv>
                </InputsDiv>
                <LimparFiltro onClick={() => limparFiltros()}>limpar filtros</LimparFiltro>
                <AplicarButton onClick={() => aplicarFiltros()}>Aplicar</AplicarButton>
            </Popover.Content>
        </Popover>
    );

    const novoArquivoPopover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Novo Arquivo</Popover.Title>
            <Popover.Content>
                <AplicarButton onClick={() => gerarArquivo("past")}>Últimos Dias</AplicarButton>
                <AplicarButton onClick={() => gerarArquivo("present")}>Últimos Dias e Hoje</AplicarButton>
            </Popover.Content>
        </Popover>
    );


    return <Container>
        <HeaderLogged />
        <Content>
            <h1>Arquivos Cidadetran</h1>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Cidadetran</Breadcrumb.Item>
            </Breadcrumb>

            <RowFilter>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <FiltroButton type="button"><FaFilter color="#fff" />Filtros</FiltroButton>
                </OverlayTrigger>
                <BloqueadoButton type="button" onClick={() => history.push('/portal/cidadetran/bloqueados')}><FaBan color="#fff" />Bloqueados</BloqueadoButton>
                <OverlayTrigger trigger="click" placement="bottom" overlay={novoArquivoPopover}>
                    <ArquivoButton type="button"><FaPlus color="#fff" />Novo Arquivo</ArquivoButton>
                </OverlayTrigger>
            </RowFilter>


            {
                loading ?
                    (<Spinner animation="border" variant="secondary" />)
                    :
                    (
                        <>

                            <TableDiv>
                                <Table responsive="xl" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Nome do Arquivo</th>
                                            <th>Gerado Em</th>
                                            <th>Protocolos</th>
                                            <th>Filtro Início</th>
                                            <th>Filtro Fim</th>
                                            <th>Valor Total</th>
                                            <th>Juros de Débitos</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            records.map(record => (
                                                <tr key={record.guid}>
                                                    <td><Link to={`/portal/cidadetran/${record.file.name}`}>{record.file.name}</Link></td>
                                                    <td>{formatDateBarWithHours(record.date)}</td>
                                                    <td>{record.payments.total}</td>
                                                    <td>{formatDateBarWithHours(record.query.dateGte)}</td>
                                                    <td>{formatDateBarWithHours(record.query.dateLte)}</td>
                                                    <td>{formatPrice(record.file.amount.afterFees)}</td>
                                                    <td>{formatPrice(record.file.amount.difference)}</td>
                                                    <td>{record.status && record.status === "receipts-sent" ? <Label color="#2eb82e">COMPROV. ENVIADOS</Label> : <Label color="#1d55db">GERADO</Label>}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </TableDiv>
                            <LimitsDiv>
                                <label>visualizando</label>
                                <input value={limitActive} onChange={e => {
                                    if (e.target.value > 0 && e.target.value <= totalCount) {
                                        setLimitActive(e.target.value);
                                        loadData(filters, 1, e.target.value);
                                    }
                                }} type="number" />
                                <label>de {totalCount} registros</label>
                            </LimitsDiv>

                            <Pagination>
                                <Pagination.Prev disabled={pageActive > 1 ? false : true} onClick={() => {
                                    const p = pageActive > 1 ? (pageActive - 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }} />
                                {pages[pageActive - 1]}
                                {pages[pageActive]}
                                {pages[pageActive + 1]}
                                <Pagination.Next disabled={pageActive < pages.length ? false : true} onClick={() => {
                                    const p = pageActive < pages.length ? (pageActive + 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }}
                                />
                            </Pagination>
                        </>)
            }

        </Content>
    </Container>;
}

export default Transacoes;
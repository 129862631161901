import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 414px) {
    margin-bottom: 100px;
   form{
    width: 280px !important;
   }
  }


  form{
    display: flex;
    flex-direction: column;
    width: 350px;

    input, button{
      margin: 5px 0;
    }
  }

`;

export const ExcluirContaButton = styled.button`
      margin: 5px 0;
      border-radius: 4px;
      background-color: #FF4D4D !important;
      font-weight: bold;
      color: #fff;
`;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import api from 'axios';

import apiUser from '../../../services/api-user';
import { cepMask } from '../../../helpers/masks';
import { Container, EnderecoDiv, NovoEnderecoButton, RemoveEnderecoButton } from './styles';

function Endereco({ user }) {

  const { dados } = user;
  const { userId } = dados;
  const token = localStorage.getItem('token');

  const [enderecos, setEnderecos] = useState([]);
  const [novoEndereco, setNovoEndereco] = useState(false);
  const [editarEndereco, setEditarEndereco] = useState(false);

  //
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [addressId, setAddressId] = useState('');

  async function loadData() {
    const resp = await apiUser.get(`/v1/user/${userId}/address`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('erro ao carregar endereços'));

    if (resp.data) {
      const { data } = resp;
      const { addresses } = data;

      const array = [];
      addresses.map((item) => {
        const address = {
          id: item.id,
          rua: item.street,
          numero: item.number,
          bairro: item.neighborhood,
          cidade: item.city,
          estado: item.state,
          cep: item.zipCode,
          pais: item.country
        }
        return array.push(address);

      });

      setEnderecos(array);
    }

  }


  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  //busca cep
  useEffect(() => {
    async function loadCEP() {
      const resp = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      const { data } = resp;
      setCidade(data.localidade);
      setEstado(data.uf);
      setRua(data.logradouro);
      setBairro(data.bairro);
    }
    if (cep.length === 9) {
      loadCEP();
    }
  }, [cep]);

  async function salvarEndereco() {

    const newCep = cep.replace('-', '');

    const resp = await apiUser.post(`/v1/user/${userId}/address`, {
      addresses: [{
        number: numero,
        complement: complemento,
        street: rua,
        state: estado,
        city: cidade,
        neighborhood: bairro,
        country: "BRA",
        zipCode: newCep
      }]
    }, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('erro ao salvar endereço'));

    if (resp.status === 201) {
      toast.success('Endereço cadastrado com sucesso!');
      loadData();
      setNovoEndereco(false);
      setEditarEndereco(false);
    }
  }

  async function removerEndereco() {
    const resp = await apiUser.delete(`/v1/user/${userId}/address/${addressId}`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('erro ao remover endereço'));

    if (resp.status === 204) {
      toast.success('Endereço removido com sucesso!');
      loadData();
      setNovoEndereco(false);
      setEditarEndereco(false);
    }
  }

  async function atualizarEndereco() {
    const newCep = cep.replace('-', '');

    const resp = await apiUser.patch(`/v1/user/${userId}/address/${addressId}`, {
      number: numero,
      complement: complemento,
      street: rua,
      state: estado,
      city: cidade,
      neighborhood: bairro,
      country: "BRA",
      zipCode: newCep

    }, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('erro ao atualizar endereço'));

    if (resp.status === 204) {
      toast.success('Endereço atualizado com sucesso!');
      loadData();
      setNovoEndereco(false);
      setEditarEndereco(false);
    }
  }

  return (
    <Container>
      <h2>Meus <strong>endereços</strong></h2>
      {
        novoEndereco ?
          (<>
            <form>
              <input type="tel" maxLength={9} value={cepMask(cep)} onChange={e => setCep(e.target.value)} placeholder="CEP" />
              <input value={rua} onChange={e => setRua(e.target.value)} placeholder="Rua" />
              <input value={numero} onChange={e => setNumero(e.target.value)} placeholder="Numero" />
              <input value={complemento} onChange={e => setComplemento(e.target.value)} placeholder="Complemento" />
              <input value={bairro} onChange={e => setBairro(e.target.value)} placeholder="Bairro" />
              <div>
                <input value={cidade} onChange={e => setCidade(e.target.value)} placeholder="Cidade" />
                <input value={estado} onChange={e => setEstado(e.target.value)} placeholder="Estado" />
              </div>
              {
                editarEndereco ?
                  (
                    <>
                      <button type="button" onClick={() => atualizarEndereco()}>Atualizar</button>
                      <RemoveEnderecoButton type="button" onClick={() => removerEndereco()}>Remover</RemoveEnderecoButton>
                    </>
                  )
                  :
                  (
                    <button type="button" onClick={() => salvarEndereco()}>Salvar</button>
                  )
              }
              <span onClick={() => {
                setNovoEndereco(false);
                setEditarEndereco(false);
              }}>Voltar</span>
            </form>
          </>)
          :
          (<>

            {
              enderecos.map(item => (
                <EnderecoDiv key={item.id} onClick={() => {
                  setEditarEndereco(true);
                  setNovoEndereco(true);
                  setCep(item.cep);
                  setRua(item.rua);
                  setNumero(item.numero);
                  setComplemento(item.complemento);
                  setBairro(item.bairro);
                  setCidade(item.cidade);
                  setEstado(item.estado);
                  setAddressId(item.id);
                }}>
                  <span>{`${item.rua}, ${item.numero} - ${item.bairro}, ${item.cidade} - ${item.estado}`}</span>
                </EnderecoDiv>
              ))
            }
            <NovoEnderecoButton type="button" onClick={() => {
              setNovoEndereco(true);
              setCep('');
              setRua('');
              setNumero('');
              setComplemento('');
              setBairro('');
              setCidade('');
              setEstado('');
              setAddressId('');
            }}>Adicionar endereço</NovoEnderecoButton>
          </>)
      }
    </Container>
  );
}

export default connect(state => ({
  user: state.user,

}))(Endereco);

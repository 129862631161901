import React, { useState, useEffect } from 'react';
import { Table, Popover, OverlayTrigger, Spinner, Pagination, Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DatePicker from 'react-datepicker';
import { endOfDay } from 'date-fns';
import { FaRegCopy, FaFilter } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import {
    Container,
    Content,
    FiltroButton,
    InputsDiv,
    Input,
    ButtonInfo,
    ItemDescription,
    AplicarButton,
    LimparFiltro,
    DateDiv,
    LimitsDiv,
    TableDiv
} from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiUserToken from '../../../../services/api-userToken';
import { formatDateBar, formatDateBarWithHours, formatPrice } from '../../../../helpers/format';
import { cpfMask } from '../../../../helpers/masks';


function LinkPagamento() {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageActive, setPageActive] = useState(1);
    const [limitActive, setLimitActive] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    // console.log('pagina ativada', pageActive);

    //filters
    const [filters, setFilters] = useState("");
    const [linkIdentification, setLinkIdentification] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [cpfCliente, setCpfCliente] = useState("");
    const [description, setDescription] = useState("");
    const [startDatePicker, setStartDatePicker] = useState("");
    const [endDatePicker, setEndDatePicker] = useState("");


    useEffect(() => {
        loadData(filters, pageActive, limitActive);
        //eslint-disable-next-line
    }, [])

    function aplicarFiltros() {
        setLoading(1);

        let filter = "";

        if (linkIdentification) {
            filter += `&link=${linkIdentification}`
        }
        if (creatorName) {
            filter += `&creator=${creatorName}`
        }

        if (description) {
            filter += `&items=${description}`
        }
        if (startDatePicker && endDatePicker) {
            filter += `&dt_tran_ini=${startDatePicker.toISOString()}&dt_tran_fin=${endOfDay(endDatePicker).toISOString()}`
        }
        if (cpfCliente) {
            filter += `&cpf=${cpfCliente}`
        }

        setFilters(filter);
        setPageActive(1);
        loadData(filter, 1, limitActive);
    }

    function limparFiltros() {
        setFilters("");
        setCreatorName("");
        setLinkIdentification("");
        setDescription("");
        setStartDatePicker("");
        setEndDatePicker("");
        setCpfCliente("");
        setPageActive(1);
        loadData("", 1, limitActive);
    }

    function somarTotalPorItem(items) {
        let total = 0;
        items.map(item => {
            if (item.originalAmount) {
                return total += item.originalAmount
            }
            return 0
        });
        return formatPrice(total);
    }

    async function loadData(filters, pageNumber, limit) {
        setLoading(1);
        const res = await apiUserToken.get(`/user/token?link=true&limit=${limit}&page=${pageNumber}${filters}`, { headers: { Authorization: `Bearer ${token}` } })
        .catch(e => Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erro ao buscar dados!',
          }) );

        if (res && res.data && res.data.records) {
            setRecords(res.data.records);
            setTotalCount(res.data.totalCount);
            const pagesData = [];
            const pagesLimit = Math.ceil(res.data.totalCount / res.data.limit)
            for (let i = 1; i <= pagesLimit; i++) {
                pagesData.push(<Pagination.Item
                    key={i}
                    active={pageNumber === i}
                    onClick={() => {
                        setPageActive(i);
                        loadData(filters, i, limit);
                    }}>{i}</Pagination.Item>)
            }
            // setPageActive(pageActive);
            setPages(pagesData);
        }
        setLoading(0);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Filtros</Popover.Title>
            <Popover.Content>
                <Input value={linkIdentification} onChange={e => setLinkIdentification(e.target.value)} placeholder="Link" />
                <InputsDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Início" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={startDatePicker} onChange={date => setStartDatePicker(date)} />
                    </DateDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Fim" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDatePicker} onChange={date => setEndDatePicker(date)} />
                    </DateDiv>
                </InputsDiv>
                <Input value={description} onChange={e => setDescription(e.target.value)} placeholder="Descrição Item" />
                <Input value={cpfCliente} onChange={e => setCpfCliente(e.target.value)} placeholder="CPF cliente" />
                <Input value={creatorName} onChange={e => setCreatorName(e.target.value)} placeholder="Criado por" />
                {/* <br /> */}
                <LimparFiltro onClick={() => limparFiltros()}>limpar filtros</LimparFiltro>
                <AplicarButton onClick={() => aplicarFiltros()}>Aplicar</AplicarButton>
            </Popover.Content>
        </Popover>
    );


    return <Container>
        <HeaderLogged />
        <Content>
            <h1>Links de pagamento</h1>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Links</Breadcrumb.Item>
            </Breadcrumb>

            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <FiltroButton type="button"><FaFilter color="#fff" />Filtros</FiltroButton>
            </OverlayTrigger>

            {
                loading ?
                    (<Spinner animation="border" variant="secondary" />)
                    :
                    (
                        <>
                            <TableDiv>
                                <Table responsive="xl" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Link</th>
                                            <th>Data Criação</th>
                                            <th>Data Expiração</th>
                                            <th>Itens</th>
                                            <th>Valor Original</th>
                                            <th>CPF Cliente</th>
                                            <th>Criado por</th>
                                            <th>Protocolo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            records.map(record => (
                                                <tr key={record.id}>
                                                    <td>
                                                        <CopyToClipboard text={`${document.location.origin}/simulacoes-por-valor/${record.id}`}>
                                                            <ButtonInfo onClick={() => toast.success('Copiado para área de transferência')}><FaRegCopy size={20} /></ButtonInfo>
                                                        </CopyToClipboard>
                                                    </td>
                                                    <td>{formatDateBar(record.date)}</td>
                                                    <td>{formatDateBarWithHours(record.dueDate)}</td>
                                                    <td>{
                                                        record.items ? record.items.map(item => (
                                                            <div key={item.identification}>
                                                                <ItemDescription><strong>Item:</strong> {item.identification}</ItemDescription>
                                                                <br />
                                                            </div>
                                                        )) : (<></>)
                                                    }</td>
                                                    <td>{
                                                        somarTotalPorItem(record.items ? record.items : [])
                                                    }</td>
                                                    <td>{cpfMask(record.user.documents[0].identification)}</td>
                                                    <td>{record.creator.name}</td>
                                                    <td><Link to={`/portal/transacoes/${record.transactionId}`}>{record.transactionId}</Link></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </TableDiv>
                            <LimitsDiv>
                                <label>visualizando</label>
                                <input value={limitActive} onChange={e => {
                                    if (e.target.value > 0 && e.target.value <= totalCount) {
                                        setLimitActive(e.target.value);
                                        loadData(filters, 1, e.target.value);
                                    }
                                }} type="number" />
                                <label>de {totalCount} registros</label>
                            </LimitsDiv>

                            <Pagination>
                                <Pagination.Prev disabled={pageActive > 1 ? false : true} onClick={() => {
                                    const p = pageActive > 1 ? (pageActive - 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }} />
                                {pages[pageActive - 1]}
                                {pages[pageActive]}
                                {pages[pageActive + 1]}
                                <Pagination.Next disabled={pageActive < pages.length ? false : true} onClick={() => {
                                    const p = pageActive < pages.length ? (pageActive + 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }}
                                />
                            </Pagination>
                        </>)
            }

        </Content>
    </Container>;
}

export default LinkPagamento;
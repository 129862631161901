export default {
	"takipay": {
		"companyId" : "dcebde82-f1c1-4d6d-97a5-76ca672535f7",
		"merchantId" : "f69b545a-2069-4757-989a-4357bfd30e88"
	},
	"escoladeautomacao": {
		"companyId": "f757b0fe-bd41-4c5b-8e74-3570b2366fc0",
		"merchantId": "dbfafe71-0a14-4822-874a-1c9f88ba23b5"
	},
	"paulacosta": {
		"companyId": "f757b0fe-bd41-4c5b-8e74-3570b2366fc0",
		"merchantId": "dbfafe71-0a14-4822-874a-1c9f88ba23b5"
	},
	"meumentor": {
		"companyId": "787f6a1d-81e8-482a-bc63-65de7a182da9",
		"merchantId": "39cf262c-acd4-45e8-bb97-f483da2ef584"
	},
	"larissamoschetta": {
		"companyId": "a79e68b4-070c-4bae-8449-fe7d8fe4c99d",
		"merchantId": "c120b318-f754-42ae-afac-49e3beb29a34"
	},
	"ifly": {
		"companyId": "ae5577d7-8b1d-4a6e-b079-97c3c96f7254",
		"merchantId": "2943f417-b32c-48a3-a1c6-9fa75a7946fe"
	}
}
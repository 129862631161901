import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

`;

export const PerfilContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  width: 660px;

  @media (max-width: 414px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

export const MenuLateral = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 414px) {
    flex-direction: row;
    margin-bottom: 20px;
  }

`;

export const MenuItem = styled.div.attrs(props => ({
  ative: props.ative
}))`
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 10px 15px;

    span{
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    @media (max-width: 414px) {
    margin: 0px;
    padding: 8px 8px;
    span{
      display: none;
    }
    svg{
      height: 30px;
      align-self: center;
    }
  }

    ${(props) => props.ative
    && css`
      background-color: #ee0066!important;
      svg{
        color: #fff !important;
      }
      span{
        color: #fff;
      }
      border-radius: 4px;
      width: 200px;

      @media (max-width: 414px) {
        width: auto;
      }
    `}

    &:hover{
      cursor: pointer;
    }

`;

export const Modal = styled.div`
    display: flex;
    position: fixed !important;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    visibility: visible;
    background-color: rgba(0, 0 ,0 , 0.7);
    z-index: 99;


    div{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 220px;
      width: 300px;
      background-color: #441188;
      border-radius: 4px;
      padding: 20px;

      @media(max-width: 414px){
        height: 200px;
        width: 250px;
      }


      div{
        display: flex;
        flex-direction: row !important;
        button{
          background-color: #ee0066;
          color: #fff;
          margin: 4px;

          &:hover{
            background-color: ${lighten(0.08, '#ee0066')};
          }
        }
      }
      h3{
        color: #fff;
        font-size: 28px;
        text-align: center;
      }
    }
`;

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FaShoppingBag } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import {
  Payment,
  PaymentContent,
  ImgSecurity,
} from './styles';
import FormUser from './formUser';
import FormPayer from './formPayer';
import FormAddress from './formAddress';
import FormPayment from './formPayment';
import StepsIndicator from '../StepsIndicator';

import apiIp from '../../services/api-ip';
import apiTransacao from '../../services/transacao';
import apiCoupon from '../../services/api-coupon';

import imgSecurity from '../../assets/brands/security.png'

function FormPagamento({ user, cart, callbackTotal }) {
  const { simulacao } = cart
  const { seller } = simulacao || {}
  const token = localStorage.getItem('token');
  const visitorId = window.takipay ? window.takipay.getVisitor() : ""
  const dispatch = useDispatch()
  const history = useHistory()

  //steps
  // 1 - usuário
  // 2 - pagador
  // 3 - endereço
  // 4 - pagamento
  const [step, setStep] = useState(1);
  const [stepsDone, setStepsDone] = useState(1);

  // dados form
  const [userData, setUserData] = useState(Object);
  const [payerData, setPayerData] = useState(Object);
  const [paymentData, setPaymentData] = useState(Object);
  // const [addressData, setAddressData] = useState(Object);

  function nextStep() {
    // e.preventDefault();
    if (step < 4) {
      setStep(step + 1)
      setStepsDone(stepsDone +1)
    }
  }

  function previousStep() {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  function renderSteps() {
    switch (step) {
      case 1: return <FormPayment
        callbackPayment={(paymentData) => setPaymentData(paymentData)}
        paymentData={paymentData}
        callbackNextStep={() => nextStep()}
        callbackNewTotal={(paymentData) => callbackTotal(paymentData)}
        simulacao={simulacao}
      />

      // STEP 2 - USER
      case 2: return <FormUser
        callbackPreviousStep={() => previousStep()}
        callbackUser={(user) => setUserData(user)}
        callbackNextStep={() => nextStep()}
        userData={userData}
        userSigned={user.signed ? user.dados : {}}
      />

      // STEP 3 - PAGADOR
      case 3: return <FormPayer
        callbackPayer={(payer) => setPayerData(payer)}
        userForm={userData}
        payerData={payerData}
        callbackNextStep={() => nextStep()}
        callbackPreviousStep={() => previousStep()}
      />

      // STEP 4 - ENDEREÇO
      case 4: return <FormAddress
        // callbackAddress={(address) => setAddressData(address)}
        callbackPreviousStep={() => previousStep()}
        user={user}
        callbackPayment={(data) => finalizarPagamento(data)}
      />
      //default
      default: break;

    }
  }

  function updatePaymentStatus() {
    dispatch({
      type: '@cart/UPDATE_VALUES',
    });
    history.push("/pagamento/status-obrigado")
  }

  async function finalizarPagamento(addressData) {
    let ip = null;
    let payload = null;
    let coupon = null;

    const respIp = await apiIp.get('/').catch(e => {
      return;
    });

    if (respIp && respIp.data) {
      ip = respIp.data.ip;
    }

    if (simulacao && simulacao.coupon && simulacao.coupon.name) {
      const res = await apiCoupon.get(`coupon/${simulacao.coupon.name}`).catch(e => alert('Cupom expirado'));
      coupon = {
        id: simulacao.coupon._id,
        name: simulacao.coupon.name
      }

      if (!res) {
        return;
      }
    }
    let mpDeviceId = window.MP_DEVICE_SESSION_ID;
    payload = {
      description: simulacao.description,
      ...(!user.signed ? { userToken: simulacao.userToken } : {}),
      user: {
        ip,
        ...(mpDeviceId ? {mpDeviceId} : {}),
        visitorId,
        ...(user.signed && user.dados ? {  id: user.dados.userId, createdAt: user.dados.createdAt } : {}),
        name: `${userData.nome} ${userData.sobrenome}`,
        email: userData.email,
        phones: [
          {
            type: userData.telefone.length ===  10 ? 'phone' : 'cellphone',
            number: userData.telefone,
          },
        ],
        documents: [
          {
            type: userData.cpfOrCpnj === 'CPF' ? 'CPF' : 'CNPJ',
            identification: userData.cpfOrCpnj === 'CPF' ? userData.cpf : userData.cnpj,
          },
        ],
        // } : {})
      },
      seller,
      payer: {
        email: payerData.email,
        name: `${payerData.nome} ${payerData.sobrenome}`,
        birthdate: payerData.dataNascimento,
        phones: [
          {
            type: payerData.telefone === 10 ? 'phone' : 'cellphone',
            number: payerData.telefone,
          },
        ],
        documents: [
          {
            type: payerData.cpfOrCpnj,
            identification: payerData.cpfOrCpnj === 'CPF' ? payerData.cpf : payerData.cnpj,
          },
        ],
        address: {
          street: addressData.logradouro,
          number: addressData.numero,
          complement: addressData.complemento,
          zipCode: addressData.cep,
          city: addressData.cidade,
          state: addressData.uf,
          neighborhood: addressData.bairro,
        },
      },
      payment: {
        type: 'CreditCard',
        installments: Number(paymentData.parcelaSelecionada),
        originalAmount: simulacao.originalAmount,
        grossAmount: Math.round(simulacao.parcelasIniciais[paymentData.parcelaSelecionada - 1]),
        discount: simulacao.coupon.discount ? Math.round(simulacao.parcelasIniciais[paymentData.parcelaSelecionada - 1] - simulacao.parcelas[paymentData.parcelaSelecionada - 1]) : 0,
        amount: Math.round(simulacao.parcelas[paymentData.parcelaSelecionada - 1]),

        description: simulacao.product === 'boleto' ? 'TAKIPAG' : 'DETRANTAKI',
        ...(coupon ? { coupon } : {})
      },
      card: {
        number: paymentData.numeroCartao,
        holder: paymentData.nomeCartao,
        expirationDate: `${paymentData.mesCartao}/${paymentData.anoCartao}`,
        securityCode: paymentData.cvv,
        brand: paymentData.cardBrand,
        saveCard: false,
      },
      simulation: {
        guid: simulacao.guid
      }
    };

    const response = await apiTransacao.post('/ecommerce/transaction', payload, {
      headers: { ...(token ? { Authorization: `Bearer ${token}` } : {}) },
    }).catch(e => {
      if (e.response) {
        const erro = e.response.data;

        if (erro.statusCode === '403' || erro.statusCode === '401') {
          localStorage.clear();
          toast.error('Sua sessão expirou');
          history.push('/')
        }

        if (erro.action === 'validate') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Houve um erro ao realizar a transação',
          })
          // alert('Houve um erro ao realizar a transação')
        }

        if (erro.action === 'reserve' || erro.action === 'capture' || erro.action === 'analyze') {
          updatePaymentStatus();
        } else if (erro.action === 'validate' && erro.status === 'error' && erro.blocked === true) {
          Swal.fire({
            icon: 'error',
            title: 'Não foi possível processar sua transação',
            text: 'Por favor tente novamente mais tarde',
          })
          // alert('Houve um erro ao realizar a transação')
          return
        }

        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Houve um erro ao realizar a transação',
          })
          // alert('Houve um erro ao realizar a transação')
          return
        }
      }

    });

    if (response && ((response.data.action === 'capture' && response.data.status === 'approved')
      || (response.data.action === 'analyze' && response.data.status === 'pending'))) {
      updatePaymentStatus();
    }

    return;
  }

  return <Payment>
    <Helmet>
      <script src="https://www.mercadopago.com/v2/security.js" view="checkout"></script>
      <meta name="kdt:page" content="checkout" />
    </Helmet>
    <PaymentContent>
      {
        cart.simulacao && cart.simulacao.items ?
          <>
            <StepsIndicator steps={['payment', 'user', 'payer', 'address']} stepsDone={stepsDone} callbackChange={step => setStep(step)} />
            {renderSteps()}
            <ImgSecurity src={imgSecurity} />
          </>
          :
          <>
            <h2>O seu carrinho está vazio <FaShoppingBag size={28} /></h2>
            <p>Volte para página inicial ou escolha outros produtos.</p>
            {
              user.signed ?
                <Link to="/">Voltar ao início</Link>
                :
                <a href="https://www.takipay.com.br">Voltar ao início</a>
            }
          </>
      }

    </PaymentContent>
  </Payment>;
}

export default connect((state) => ({
  cart: state.cart,
  user: state.user,
}))(FormPagamento);

import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

export const Payment = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    width: 60%;
    margin-bottom: 20px;

    @media (max-width: 1080px) {
        width: 86%;
    }
`;



export const Title = styled.div`
 margin-bottom: 10px;
 border-bottom: 2px solid #c3c3c3;
 width: 100%;
 h4{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    svg{
      margin-right: 10px;
    }
  }
`;


export const PaymentContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 20px; */
  width: 100%;
  @media (max-width: 1080px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const DataForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;

  input, select{
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #c1c1c1;
    padding: 10px;
  }

  @media (max-width: 414px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  margin-top: 20px;
  background-color: ${props => props.previous ? '#c1c1c1' : '#441188'};

  &:hover{
    background-color: ${props => props.previous ? darken(0.1, '#c3c3c3') : lighten(0.08, '#441188')};
  }
`;

export const InputsCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  select, input{
    height: 40px;
  }

   div{
    width: 30%;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: column;
  width: 50%;

  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px 0;
    padding: 20px;
    width: 300px;
    height: 180px;
    background-color: #441188;
    border-radius: 8px;
    color: #fff;

    span{

      font-size: 16px;
    }

    img{
      height: 80px;
      width: 97px;
    }
  }

  @media (max-width: 1300px) {
      div{
        width: 200px;
        height: 120px;
      }
      img{
        width: 70%;
      }
  }
  @media (max-width: 1080px) {
    margin-top: 50px;
    div{
        width: 300px;
        height: 180px;
    }
    img{
        width: 100%;
    }
  }
  @media (max-width: 414px) {
    display: none;
  }
`;

export const Total = styled.span`
  margin-top: 20px;
`;


export const TwoInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  div{
    width: 48%;

  }

`;

export const ImgBandeiras = styled.img`
  width: 100%;
  margin-top: 10px;
`;

export const ImgSecurity = styled.img`
  width: 55%;
  margin: 5px;

  @media(max-width: 414px){
    width: 100%;
  }
`;

export const TwoButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;



`;

export const InputLabel = styled.div`
  width: 100%;
  input, select{
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 100%;
  }
  label{
    margin: 0;
  }

  @media(max-width: 1080px){
    font-size: 12px;
  }
`;


// user is logged
export const SalvarCartao = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;

  input{
    width: 20px;
  }

  label{
    color: #000;
    margin: 0;
  }
`;

export const SelectButton = styled.button.attrs((props) => ({
  ative: props.ative,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 100%;

  margin: 10px 0px !important;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #441188;
  border-radius: 4px;

  svg{
    margin-right: 10px;
  }

  @media (max-width: 414px) {
    font-size: 12px;
    svg{
      display: none;
    }
  }

  ${(props) => props.ative
    && css`
      background-color: #441188 !important;
      span{
        color: #fff;
      }
      border: 1px solid #fff;
      svg{
        color: #fff !important;
      }
    `}
`;

export const PagarButton = styled.button`
  margin-top: 20px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28a745;
  font-size: 16px;

  &:hover{
    background-color: ${darken(0.08, '#28a745')};
  }
`;

export const PreviousButton = styled.button`
  text-decoration: underline;
  color: #441188;
  width: auto;
  background-color: transparent;
  margin: 0px;
  &:hover{
      background-color: transparent;
      color: #ee0066;
  }
`;

export const TooltipCVV = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const OptionParcelas = styled.option`
  display: flex;
  width: 100%;
  justify-content: space-between !important;
`;



import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
`;

export const TableDiv = styled.div`
    border: 1px solid #eee;
    border-radius: 4px;
    max-height: 600px !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    table{
        margin: 0;
        width: 100%;
        text-align: center;
        td, th{
            vertical-align: middle;
        }
        tr{
            text-align: center !important;
            /* word-break: break-all; */
        }
  }
    
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 60px 0;
    max-width: 1200px;
    width: 80%;
    /* margin-bottom: 80px; */
    nav{
        width: 100%;
      }

`;

export const ButtonsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media(max-width: 700px){
        flex-direction: column;
    }

`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    margin: 10px;
    background-color: ${props => props.color ? props.color : '#441188'};
    &:hover{
        background-color: ${props => props.color ? darken(0.1, props.color) : lighten(0.08, '#441188')};
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}
  }

  @media(max-width: 1080px){
        font-size: 12px;
    }
`;

export const BanButton = styled.button`
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 40px;
    margin: 0;
    padding: 2px 5px;
    background-color: ${props => props.color ? props.color : '#441188'};
    &:hover{
        background-color: ${props => props.color ? darken(0.1, props.color) : lighten(0.08, '#441188')};
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'}
  }

  @media(max-width: 1080px){
        font-size: 12px;
    }
`;

export const TooltipLabel = styled.label`
    display: flex;
    align-items: center;
    svg{
        margin-left: 5px;
        cursor: pointer;
    }
`;


export const Card = styled.div`
    width: 100%;
    /* background-color: #fff; */
    border: 1px solid #c1c1c1;
    margin: 10px;
    padding: 30px;
    border-radius: 4px;

    h3{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        border-bottom: 2px solid #666;
        text-align: left;
    }
    h4{
        text-align: left;
        font-size: 14px;
    }

    label{
        text-align: left;
    }


    h3, p, label {
        color: #000;
    }
`;

export const CardItem = styled.div`
        display: flex;

        div{
            width: 50%;
            display: flex;
            flex-direction: column;
           
        }

        @media(max-width: 650px){
            flex-direction: column;
        }
`;

export const ItemCategory = styled.div`
    display: flex;
    flex-direction: column;
    div{
        border-bottom: 1px solid #c1c1c1;
        margin: 5px;
        word-break: break-all;
    }
`;



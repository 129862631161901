import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const Content = styled.div`
    margin: 40px 0;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column;
    width: 80%;

    nav{
        width: 100%;
      }
   
  @media(max-width: 414px){
    width: 70%;
  }


`;

export const TableDiv = styled.div`
    border: 1px solid #eee;
    border-radius: 4px;
    height: 400px !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    table{
        margin: 0;
        width: 100%;
        text-align: center;
        td, th{
            vertical-align: middle;
        }
        tr{
            text-align: center !important;
            /* word-break: break-all; */
        }
  }
    
`;

export const ItemDescription = styled.span`
    margin: 5px;
    font-size: 12px;
`;

export const InputsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    width: 100%;
    input, select{
        height: 35px;
        margin: 5px 0;
    }
    select{
        width: 100%;
        border-radius: 4px;
    }
`;

export const LimitsDiv = styled.div`
    margin: 20px;
    input{
        height: 30px;
        width: 60px;
    }
    label{
        font-size: 12px;
        margin: 0 10px;
    }
`;

export const DateDiv = styled.div`
    width: 48%;
`;

export const Input = styled.input`
    margin: 5px 0;
    height: 35px;
`;

export const AplicarButton = styled.button`
    background-color: #ee0066;
    &:hover{
      background-color: ${lighten(0.08, '#ee0066')};
  }
`;

export const FiltroButton = styled.button`
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 10px;
    width: auto;
    color: #fff;
    border-radius: 4px;
    align-self: flex-start;
    cursor: pointer;
    svg{
        margin-right: 5px;
    }
`;

export const ButtonInfo = styled.div`
    background-color: transparent;
    height: auto;
    width: auto;
    cursor: pointer;

    &:hover{
        svg{
            color: #441188;
        }
    }
`;

export const LimparFiltro = styled.label`
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
    width: 100%;
    color: #441188;
`;

export const RowFilter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media(max-width: 1200px){
      flex-direction: column;
    }
`;

export const TotalPorStatus = styled.div`
    display: ${props => props.visible ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #441188;
    margin: 10px 0 20px 20px;
    width: auto;
  
    @media(max-width: 1200px){
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 20px 0;
        max-height: 300px;
        svg{
            display: none;
        }
    }
`;

export const SpanStatus = styled.span`
    margin: 0 5px;
    font-size: 12px;
    text-align: center;
    font-weight: ${props => props.important ? 'bold' : 500}
    
`;


import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getYear, getMonth } from 'date-fns';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

import apiUser from '../../../services/api-user';
import { cardMask } from '../../../helpers/masks';
import validarCartao from '../../../helpers/brandsCreditCard';
import { Container, CartaoDiv, NovoCartaoButton, CardImg, RemoveCartaoButton } from './styles';
import chip from '../../../assets/chip-card.svg';

import master from '../../../assets/brands/master.png';
import hiper from '../../../assets/brands/hiper.png';
import visa from '../../../assets/brands/visa.png';
import elo from '../../../assets/brands/elo.png';
import amex from '../../../assets/brands/amex.png';

function Cartao({ user }) {

  const { dados } = user;
  const { userId } = dados;
  const token = localStorage.getItem('token');

  const [cartoes, setCartoes] = useState([]);
  const [novoCartao, setNovoCartao] = useState(false);
  const [editarCartao, setEditarCartao] = useState(false);
  const [cardId, setCardId] = useState(0);

  //
  const [numeroCartao, setNumeroCartao] = useState('');
  const [nomeCartao, setNomeCartao] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [mes, setMes] = useState('');
  const [ano, setAno] = useState('');

  // loading
  const [loadingRemover, setLoadingRemover] = useState(false);
  const [loadingNewCard, setLoadingNewCard] = useState(false);

  async function loadData() {

    const resp = await apiUser.get(`/v1/user/${userId}/cards`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('erro ao carregar cartões'));

    if (resp.data) {
      const { data } = resp;
      const { cards } = data;
      setCartoes(cards);
    }
  }

  useEffect(() => {

    loadData();
    // eslint-disable-next-line
  }, []);

  // valida cartao
  useEffect(() => {

    if (numeroCartao.length >= 17) {
      const card = numeroCartao.replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '');
      const brand = validarCartao(card);
      setCardBrand(brand);
    }

  }, [numeroCartao]);

  function renderBrandImg() {
    switch (cardBrand) {
      case 'master':
        return (<img src={master} alt="brand-card" />)
      case 'mastercard':
        return (<img src={master} alt="brand-card" />)
      case 'visa':
        return (<img src={visa} alt="brand-card" />)
      case 'hipercard':
        return (<img src={hiper} alt="brand-card" />)
      case 'elo':
        return (<img src={elo} alt="brand-card" />)
      case 'amex':
        return (<img src={amex} alt="brand-card" />)
      default:
        break;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setLoadingNewCard(1);
    if (!ano || !mes || !nomeCartao || !numeroCartao) {
      toast.error('Preencha todos campos');
      return;
    }

    // pega ano atual e converte para numero
    const anoAtual = Number(getYear(new Date()));
    // pega mês atual e soma 1 => getMonth considera janeiro como indice 0
    const mesAtual = Number(getMonth(new Date())) + 1;

    if (ano < anoAtual || (Number(ano) === anoAtual && Number(mes) < mesAtual)) {
      toast.error('Data do cartão expirada');
      return;
    }

    const payload = {
      type: 'creditCard',
      brand: cardBrand,
      holder: nomeCartao,
      number: numeroCartao.replace(' ', '').replace(' ', '').replace(' ', ''),
      expirationDate: `${mes}/${ano}`,
      email: dados.email,
      cpf: dados.cpf,
    }

    const resp = await apiUser.post(`/v1/user/${userId}/cards`, payload, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('Erro ao salvar cartão'));

    if (resp.data) {
      setTimeout(() => {
        toast.success('Cartão foi cadastrado com sucesso!');
        setLoadingNewCard(0);
        loadData();
        setNovoCartao(false);
        setEditarCartao(false);
      }, 5000);
    }
    else{
      setLoadingNewCard(0);
    }

  }

  async function deleteCard() {
    setLoadingRemover(1);
    const resp = await apiUser.delete(`/v1/user/${userId}/cards/${cardId}`, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('Erro ao salvar cartão'));

    if (resp.status === 204) {
      toast.success('Cartão foi removido com sucesso!');
      loadData();
      setTimeout(() => {
        setNovoCartao(false);
        setEditarCartao(false);

      }, 1000);
    }

    setLoadingRemover(0);
  }

  return (
    <Container>
      <h2>Meus <strong>cartões</strong></h2>
      {
        novoCartao ?
          (
            <>
              <CardImg>
                <div>
                  <img src={chip} alt="chip cartão" />
                  {
                    renderBrandImg()
                  }
                </div>
                <span>{cardMask(numeroCartao)}</span>
                <span>{nomeCartao.toUpperCase()}</span>
                <span>{`${mes}/${ano}`}</span>
              </CardImg>
              <form onSubmit={handleSubmit}>

                <input required maxLength={30} disabled={editarCartao ? true : false} value={cardMask(numeroCartao)} onChange={e => setNumeroCartao(e.target.value)} placeholder="Número do cartão" />
                <input required maxLength={32} disabled={editarCartao ? true : false} value={nomeCartao} onChange={e => setNomeCartao(e.target.value)} placeholder="Nome impresso" />
                <div>
                  <input required disabled={editarCartao ? true : false} maxLength={2} minLength={2} value={mes} onChange={e => setMes(e.target.value)} placeholder="Mês" />
                  <input required disabled={editarCartao ? true : false} maxLength={4} minLength={4} value={ano} onChange={e => setAno(e.target.value)} placeholder="Ano" />
                </div>
                {
                  editarCartao ?
                    (<RemoveCartaoButton type="button" onClick={() => deleteCard()} >{
                      loadingRemover ?
                        (<Spinner size="sm" animation="border" variant="light" />)
                        :
                        (<>Remover</>)
                    }</RemoveCartaoButton>)
                    :
                    (<button type="submit">{
                      loadingNewCard ?
                        (<Spinner size="sm" animation="border" variant="light" />)
                        :
                        (<>Salvar</>)
                    }</button>)
                }
                <span onClick={() => {
                  setNovoCartao(false);
                  setEditarCartao(false);
                }}>Voltar</span>
              </form>
            </>
          )
          :
          (
            <>
              {
                cartoes.map(item => (
                  <CartaoDiv key={item.cardId} onClick={() => {
                    setEditarCartao(true);
                    setNovoCartao(true);
                    setCardBrand(item.brand);
                    setNumeroCartao(item.number);
                    setNomeCartao(item.holder);
                    const expiration = item.expirationDate.split('/');
                    setMes(expiration[0]);
                    setAno(expiration[1]);
                    setCardId(item.cardId);
                  }}>
                    <span>{`${item.number}`}</span>
                    <img src={item.brand === 'visa' ? visa : master} alt="card" />
                  </CartaoDiv>
                ))
              }
              <NovoCartaoButton type="button" onClick={() => {
                setNovoCartao(true);
                setCardBrand('');
                setNumeroCartao('');
                setNomeCartao('');
                setMes('');
                setAno('');
                setCardId(0);
              }}>Adicionar cartão</NovoCartaoButton>
            </>
          )
      }

    </Container>
  );
}

export default connect(state => ({
  user: state.user,

}))(Cartao);

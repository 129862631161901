import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #441188;
  width: 100%;
  padding: 10px 50px;


  @media (max-width: 950px) {
    justify-content: center !important;
    align-items: center;
    span{
      display: none;
    }
  }

`;

export const Logo = styled.img`
  width: 80px;
`;

export const DataConsulta = styled.div`
  span {
    color: #fff;
  }
`;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

import apiUser from '../../../services/api-user';
import apiLogin from '../../../services/login';
import { Container } from './styles';

function Senha({ user }) {
  const { dados } = user;
  const { userId } = dados;
  // const token = localStorage.getItem('token');

  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');

  // AUX
  const [loading, setLoading] = useState(false);

  async function atualizarSenha(e) {
    e.preventDefault();
    setLoading(1);

    if (novaSenha !== confirmarSenha) {
      toast.error('Senhas novas não conferem');
      setLoading(0);
      return;
    }

    const responseLogin = await apiLogin.post('/login', {
      username: user.dados.userName,
      password: senhaAtual,
    }).catch((e) => {
      if (e.response && e.response.data && e.response.data.error === 'invalid_grant') {
        toast.error('Senha atual invalida');
      } else {
        toast.error('Erro ao atualizar senha');
      }
    });

    if (responseLogin && responseLogin.data) {
      const resp = await apiUser.patch(`/v1/user/${userId}`, {
        password: novaSenha,
      }, { headers: { Authorization: `Bearer ${responseLogin.data.data.access_token}` } })
        .catch((e) => toast.error('Erro ao atualizar senha'));

      if (resp.status === 204) {
        toast.success('Senha atualizada com sucesso!');
      }
    }

    setLoading(0);
  }

  return (
    <Container>
      <h2>Minha <strong>senha</strong></h2>
      <form onSubmit={atualizarSenha}>
        <input required type="password" value={senhaAtual} onChange={(e) => setSenhaAtual(e.target.value)} placeholder="Senha atual" />
        <input minLength={6} required type="password" value={novaSenha} onChange={(e) => setNovaSenha(e.target.value)} placeholder="Nova senha" />
        <input minLength={6} required type="password" value={confirmarSenha} onChange={(e) => setConfirmarSenha(e.target.value)} placeholder="Confirme a nova senha" />
        <button type="submit">{
          loading
            ? (<Spinner size="sm" animation="border" variant="light" />)
            : (<>Atualizar senha</>)
        }</button>
      </form>
    </Container>
  );
}

export default connect((state) => ({
  user: state.user,

}))(Senha);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

`;

export const Content = styled.div`
  margin: 150px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  form{
    width: 400px;
    input, select{
        height: 40px;
        margin: 5px 0;
        width: 100%;
        text-align: center;
        text-align-last: center;
    }
  }

  @media (max-width: 414px){
    margin: 50px;
    form{
      width: 280px;
    }
  }



  @media (max-width: 1500px) {
    margin-top: 100px !important;
  }

`;


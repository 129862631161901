import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { store, persistor } from './store';

function App() {
  return (
    <Provider store={store}>
      {/* Script konduto */}
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `var __kdt = __kdt || []; __kdt.push({"public_key": '${process.env.REACT_APP_KEY_KONDUTO}' }); (function() { var kdt = document.createElement("script");kdt.id = "kdtjs"; kdt.type = "text/javascript";kdt.async = true;    kdt.src = "https://i.k-analytix.com/k.js";var s = document.getElementsByTagName("body")[0];s.parentNode.insertBefore(kdt, s);})();`
        }]} />
      <PersistGate loading={null} persistor={persistor}>

        <Routes />
        <GlobalStyle />
        <ToastContainer autoClose={3000} hideProgressBar />
      </PersistGate>
    </Provider>
  );
}

export default App;

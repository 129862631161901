import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Content } from './styles';
import Header from '../../components/Header';

function NotFound() {
  return (
    <Container>
      <Header />
      <Content>
        <h2><strong>Oops!</strong></h2>
        <h1><strong>404</strong></h1>
        <h3>PÁGINA NÃO ENCONTRADA</h3>
        <Link to="/">Página inicial</Link>
      </Content>
    </Container>)
}

export default NotFound;

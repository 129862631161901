import React, { useState } from 'react';
import { FaSearchDollar } from 'react-icons/fa';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import { endOfToday } from 'date-fns';
import { isValid } from 'cpf';
import { Spinner } from 'react-bootstrap';

import {
    Container,
    Content,
    FormValor,
    LinkPorValorDiv,
    Buttons,
    PreviewButton,
    ParcelasCard,
    FormData,
    LabelDiv,
    DatePickerDiv,
    TwoButtons,
    Button,
    DescricaoInput
} from './styles';
import HeaderLogged from '../../../components/HeaderLogged';
import { priceMask, phoneMask, cpfMask, numberMask } from '../../../helpers/masks';

import { formatPrice } from '../../../helpers/format';
import apiUserToken from '../../../services/api-userToken';
import apiItems from '../../../services/simulacoes';
import whitelabel from '../../../helpers/whitelabel';
const company = localStorage.getItem('company')

function LinkPorValor({ user, history }) {
    const [formUserActive, setFormUserActive] = useState(false);
    const [valor, setValor] = useState('0');
    const [taxes, setTaxes] = useState([]);
    const [descricao, setDescricao] = useState('');
    const [categorySelected, setCategorySelected] = useState('');
    const token = localStorage.getItem('token');

    // loadings
    const [loadingRequest, setLoadingRequest] = useState(0);
    const [loadingSimulation, setLoadingSimulation] = useState(0);

    // form cliente
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');

    // Datepicker
    const [endDatePicker, setEndDatePicker] = useState(endOfToday());
    const minDatePicker = endOfToday();

    // seller
    const seller = {
        companyId: whitelabel[company].companyId,
        merchantId: whitelabel[company].merchantId
    }

    async function sendPreview() {
        setLoadingSimulation(1);
       
        if (!validarValor()) {
            toast.error('Insira valor');
            setLoadingSimulation(0);
            return;
        }

        const res = await apiItems.post('/simulation/items', {
            seller,
            user: {
                name: user.dados.fullName,
                email: user.dados.email,
                phones: [
                    {
                        type: user.dados.phones[0] ? user.dados.phones[0].type : '',
                        number: user.dados.phones[0] ? user.dados.phones[0].number : '',
                    },
                ],
                documents: [
                    {
                        type: 'CPF',
                        identification: user.dados.cpf,
                    },
                ],
            },
            //   transactionLink: false,
            items: [{
                category: categorySelected,
                identification: descricao,
                plate: 'ABC1234',
                originalAmount: Number(numberMask(valor)),
            }],
        }, { headers: { Authorization: `Bearer ${token}` } }).catch((e) => {
            toast.error('Erro ao simular');
            setLoadingSimulation(0);
        });

        if (res && res.data && res.data.items && res.data.items[0].taxes) {
            setTaxes(res.data.items[0].taxes);
        }
        setLoadingSimulation(0);
    }

    async function sendRequestGenerateLink(e) {
        e.preventDefault();
        setLoadingRequest(1);

        if (!isValid(cpf)) {
            toast.error('CPF inválido');
            setLoadingRequest(0);
            return;
        }
        const newPhone = phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '');

        const newCpf = cpf.replace('.', '').replace('.', '').replace('-', '');

        const res = await apiUserToken.post('/user/token', {
            seller,
            user: {
                name,
                email,
                phones: [
                    {
                        type: newPhone.length === 10 ? 'phone' : 'cellphone',
                        number: newPhone,
                    },
                ],
                documents: [
                    {
                        type: 'CPF',
                        identification: newCpf,
                    },
                ],
            },
            transactionLink: true,
            creator: {
                id: user.dados.userId,
                name: user.dados.fullName,
            },
            dueDate: endDatePicker,
            from: 'TAKIPAY_MINHACONTA_VALOR',
            items: [{
                category: categorySelected,
                identification: descricao,
                plate: 'ABC1234',
                originalAmount: Number(numberMask(valor)),
            }]
        }).catch((e) => {
            toast.error('Erro ao gerar link');
        });

        if (res && res.data) {
            history.push(`/link/simulacoes-por-valor/${res.data.id}`)
        }


        setLoadingRequest(0);
    }

    function validarValor() {
        if (Number(numberMask(valor)) > 0) {
            return true;
        } else {
            return false;
        }
    }

    function submitSimulation(e){
        e.preventDefault();
            if (!validarValor()) {
                toast.error('Insira valor');
                return;
            }
            else {
                setFormUserActive(true);
            }
        
    }
 
    return <Container>
        <HeaderLogged />
        {
            !formUserActive
                ? (
                    <Content>
                        <h1>Simulação por valor</h1>
                        <FormValor onSubmit={submitSimulation}>
                            <LinkPorValorDiv>
                                <div>
                                    <label>Valor</label>
                                    <input required value={priceMask(valor)} onChange={(e) => setValor(priceMask(e.target.value))} />
                                </div>
                                <div>
                                    <label>Categoria de taxa</label>
                                    <select value={categorySelected} onChange={e => setCategorySelected(e.target.value)}>
                                        <option value="" disabled selected>Selecione</option>
                                    {
                                        company === 'takipay' ?
                                        <>
                                        <option value={'vehicle-debits-sp'}>Débitos veiculares</option>
                                        <option value={'boletos'}>Boletos</option>
                                        <option value={'vip'}>Vip</option>
                                        </>
                                        : company === 'escoladeautomacao' ?
                                        <option value={'info-produto'}>Escola de Automação</option>
                                        : company === 'paulacosta' ?
                                        <option value={'e-commerce'}>Quadro</option>
                                        : company === 'ifly' ?
                                        <option value={'info-produto'}>Info Produto</option>
                                        : company === 'meumentor' ?
                                        <option value={'info-produto'}>Info Produto</option>
                                        : company === 'larissamoschetta' ?
                                        <option value={'info-produto'}>Info Produto</option>
                                        : <></>
                                    }
                                    </select>
                                </div>
                            </LinkPorValorDiv>
                            <DescricaoInput required placeholder="Nome do produto que será vendido pelo link" value={descricao} onChange={e => setDescricao(e.target.value)} />
                            <Buttons>
                                {
                                    loadingSimulation ?
                                        (<PreviewButton disabled><Spinner animation="border" variant="light" size="sm" /></PreviewButton>)
                                        :
                                        (<PreviewButton type="button" onClick={() => sendPreview()}>
                                            <span>Pré visualização</span>
                                            <FaSearchDollar color="#fff" size={14} />
                                        </PreviewButton>)
                                }
                                <button type="submit" >
                                    Próximo
                            </button>
                            </Buttons>

                        </FormValor>
                        <ParcelasCard>
                            <ul>
                                {
                                    taxes[0] ? taxes.map((item, i) => (
                                        <li key={item}>
                                            <label><strong>{i + 1}x</strong> de {formatPrice(item / (i + 1))}</label><span>{formatPrice(item)}</span>
                                        </li>

                                    ))
                                        : (<>pré visualização</>)
                                }
                            </ul>
                        </ParcelasCard>
                    </Content>

                )
                : (<Content>

                    <h1>Dados do cliente</h1>
                    <FormData onSubmit={sendRequestGenerateLink}>
                        <LabelDiv>
                            <label>Nome: </label>
                            <input required value={name} maxLength="45" onChange={(e) => setName(e.target.value)} placeholder="Ex: José dos Santos" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>CPF: </label>
                            <input required type="tel" minLength={14} maxLength={14} value={cpfMask(cpf)} onChange={(e) => setCpf(e.target.value)} placeholder="Ex: 000.000.000-00" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>E-mail: </label>
                            <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="contato@takipay.com.br" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>Telefone: </label>
                            <input required type="tel" minLength={14} maxLength={15} value={phoneMask(phone)} onChange={(e) => setPhone(e.target.value)} placeholder="Ex: (11) 0000-0000" />
                        </LabelDiv>

                        <DatePickerDiv>
                            <label>Expira em:</label>
                            <DatePicker showTimeInput minDate={minDatePicker} dateFormat="dd/MM/yyyy" required selected={endDatePicker} onChange={(date) => setEndDatePicker(date)} />
                        </DatePickerDiv>

                        <TwoButtons>
                            <Button type="button" onClick={() => setFormUserActive(false)}>Voltar</Button>
                            {
                                loadingRequest
                                    ? (<Button disabled><Spinner animation="border" variant="light" size="sm" /></Button>)
                                    : (<Button type="submit">Gerar link</Button>)
                            }
                        </TwoButtons>

                    </FormData>

                </Content>)
        }
    </Container>;
}

export default connect((state) => ({
    user: state.user,
}))(LinkPorValor);

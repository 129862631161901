import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import GoogleLogin from 'react-google-login';
// import { FcGoogle } from 'react-icons/fc';
// import { FaFacebookF } from 'react-icons/fa';

// eslint-disable-next-line object-curly-newline
import { Container, Form, Logo, Input, AreaSpan,
  // DivSociaLogin, ButtonSocialGoogle, ButtonSocialFb
} from './styles';
import api from '../../services/login';
import logo from '../../assets/taki.png';
const company = window.location.href.split('/').pop() || 'takipay'

export default function Login({ history }) {
  const [loading, setLoading] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  async function load(event) {
    event.preventDefault();
    setLoading(1);
    if (username && password) {
      try {
        const response = await api.post('/login', {
          username,
          password,
          company
        });
        setLoading(0);

        localStorage.setItem('token', response.data.data.access_token);
        localStorage.setItem('refresh_token', response.data.data.refresh_token);
        localStorage.setItem('company', company);
        window.location.href = '/dashboard';
      } catch (e) {
        toast.error('Usuário ou senha inválida!');
        setLoading(0);
      }
    } else {
      toast.error('Preencha todos os campos');
      setLoading(0);
    }
  }

  return (
    <Container>
      <Helmet>
        <meta name="kdt:page" content="home" />
        <script src="https://www.mercadopago.com/v2/security.js" view="home"></script>
        {/* <script src="https://storage.googleapis.com/script-antifraude/pages.js" view="home" data="taki" /> */}
      </Helmet>
      <Form onSubmit={load}>
        <Logo src={logo} alt="logo-taki" />
        <Input
          type="text"
          onChange={(event) => setUsername(event.target.value)}
          placeholder="Digite seu usuário"
        />
        <Input
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Digite sua senha"
        />
        <button type="submit">
          {loading ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            <>Entrar</>
          )}
        </button>

        {
          window.location.href.split('/').pop().length === 0 ?
          <AreaSpan>
            <Link to="/cadastro">Criar conta <strong>agora</strong>!</Link>
            <Link to="/redefinir-senha">esqueci minha senha</Link>
          </AreaSpan>
          : <></>
        }
      </Form>


    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as EmailValidator from 'email-validator';
import Spinner from 'react-bootstrap/Spinner';

import apiUser from '../../../services/api-user';
import { cpfMask, phoneMask } from '../../../helpers/masks';
import {
  Container,
  // ExcluirContaButton
 } from './styles';

function Dados({ user }) {

  //redux
  const { dados } = user;
  const { userId } = dados;
  const token = localStorage.getItem('token');

  //DADOS
  const [username, setUsername] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [oldPhone, setOldPhone] = useState('');
  const [oldPhoneId, setOldPhoneId] = useState(0);

  //AUX
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { dados } = user;
    const { userName, fullName, cpf, email, phones } = dados;
    const [firstname, lastname] = fullName.split(' ');

    setUsername(userName);
    setCpf(cpf);
    setNome(firstname);
    setSobrenome(lastname);
    setEmail(email);
    if (phones[0]) {
      setTelefone(phones[0].number);
      setOldPhone(phones[0].number);
      setOldPhoneId(phones[0].id);
    }
    //eslint-disable-next-line
  }, []);

  async function atualizarDados(e) {
    e.preventDefault();
    setLoading(1);

    if (!EmailValidator.validate(email)) {
      toast.error('E-mail inválido');
      setLoading(0);
      return;
    }

    let resp = await apiUser.patch(`/v1/user/${userId}`, {
      firstName: nome,
      lastName: sobrenome,
      email,
    }, { headers: { Authorization: `Bearer ${token}` } })
      .catch(e => toast.error('Erro ao atualizar dados'));

    if (oldPhone !== telefone) {
      const newTel = telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

      await apiUser.patch(`/v1/user/${userId}/phones/${oldPhoneId}`, {
        number: newTel,
        type: newTel.length === 10 ? 'phone' : 'cellphone'
      }, { headers: { Authorization: `Bearer ${token}` } })
        .catch(e => toast.error('Erro ao atualizar telefone'));
    }

    if (resp.status === 204) {
      toast.success('Dados atualizados com sucesso!');
    }

    setLoading(0);

  }

  // async function desativar() {
  //   // let resp = await apiUser.delete(`/v1/user/${userId}`, { headers: { Authorization: `Bearer ${token}` } })
  //   //   .catch(e => toast.error('erro ao desativar conta'));

  //   // if (resp.status === 204) {
  //   //   toast.success('Conta desativada com sucesso!');
  //   //   localStorage.clear();

  //   // }
  // }

  return (
    <Container>
      <h2>Meus <strong>dados</strong></h2>
      <form onSubmit={atualizarDados}>
        <input maxLength={30} value={nome} onChange={e => setNome(e.target.value)} placeholder="Nome" />
        <input maxLength={30} value={sobrenome} onChange={e => setSobrenome(e.target.value)} placeholder="Sobrenome" />
        <input value={cpfMask(cpf)} onChange={e => setCpf(e.target.value)} disabled placeholder="CPF" />
        <input value={username} onChange={e => setUsername(e.target.value)} hidden placeholder="Username" />
        <input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
        <input type="tel" value={phoneMask(telefone)} onChange={e => setTelefone(e.target.value)} placeholder="Telefone" />
        <button type="submit">{
          loading ?
            (<Spinner size="sm" animation="border" variant="light" />)
            :
            (<>Atualizar dados</>)
        }</button>
        {/* <ExcluirContaButton disabled type="button" onClick={() => desativar()}>Desativar minha conta</ExcluirContaButton> */}
      </form>
    </Container>
  );
}


export default connect(state => ({
  user: state.user,
}))(Dados);

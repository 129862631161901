import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Content = styled.div`
    margin: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 48%;

  @media(max-width: 414px){
    width: 70%;
  }
`;

export const CopyDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input {
        border-radius: 4px 0px 0 4px;
        height: 41px;
        width: 70%;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 4px 4px 0px;
        height: 41px;
        width: 30%;
        svg{
            margin-left: 5px;
        }
    }

`;

import styled from 'styled-components';

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    font-weight: 500;
    background-color: ${props => props.color ? props.color : "#8c8c8c"};
    width: 100%;
    font-size: ${props => props.size ? `${props.size}px` : '13px' };
    max-width: 150px;
    margin: 5px 0;

    @media(max-width: 650px){
      width: 100px;
    }

`;

export const LabelAproved = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    background-color: #2eb82e;
    width: 100%;
    max-width: 120px;
    margin: 5px 0;

`;

export const LabelDeclined = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    background-color: #ff4d4d;
    width: 100%;
    max-width: 120px;
    margin: 5px 0;
`;

export const LabelPending = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    background-color: #80aaff;
    width: 100%;
    max-width: 120px;
    margin: 5px 0;
`;

export const LabelNotAuthorized = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    background-color: #ffc34d;
    width: 100%;
    max-width: 120px;
    margin: 5px 0;
`;

export const LabelDefault = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    white-space: nowrap;
    padding: 2px 5px;
    color: #fff !important;
    background-color: #8c8c8c;
    width: 100%;
    max-width: 120px;
    margin: 5px 0;
`;

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container, Logo } from './styles';

import logo from '../../assets/taki-white.png';

function Header({user}) {
  return (
    <Container>
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KMZPSV9');`
        }]} />

      {
        user.signed ?
          (<>
            <a href="/"><Logo src={logo} alt="logo-taki" /></a>
          </>)
          :
          (<>
            <a href="http://www.takipay.com.br"><Logo src={logo} alt="logo-taki" /></a>
          </>)
      }

    </Container>
  );
}


export default connect(state => ({
  user: state.user,

}))(Header);

import React, { useState } from 'react';
import { FaLock, FaMapMarkerAlt, FaRegCreditCard, FaCogs, FaPowerOff } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import { Container, PerfilContent, MenuLateral, MenuItem, Modal } from './styles';
import HeaderLogged from '../../components/HeaderLogged';

// 0 - Dados
// 1 - Senha
// 2 - Endereco
// 3 - Cartao
import DadosComponent from '../../components/Perfil/Dados';
import SenhaComponent from '../../components/Perfil/Senha';
import EnderecoComponent from '../../components/Perfil/Endereco';
import CartaoComponent from '../../components/Perfil/Cartao';
import { validateRole } from '../../helpers/roles';

export default function Perfil({ history }) {
  const company = localStorage.getItem('company')
  const [arrayMenu, setArrayMenu] = useState([!validateRole('noPersonalData'), validateRole('noPersonalData'), false, false, false]);
  const [menuSelecionado, setMenuSelecionado] = useState(!validateRole('noPersonalData') ? 0 : 1);
  const [statusLoggout, setStatusLoggout]  = useState(false);

  function renderMenu() {
    switch (menuSelecionado) {
      case 0:
        return (<DadosComponent />)
      case 1:
        return (<SenhaComponent />)
      case 2:
        return (<EnderecoComponent />)
      case 3:
        return (<CartaoComponent />)
      default:
        break;
    }
  }

  return (
    <>
      {
        statusLoggout ?
          (
            <Modal>
              <div>
                <h3>Deseja mesmo <strong>sair</strong>?</h3>
                <div>
                  <button type="button" onClick={() => {
                    localStorage.clear();
                    window.location.href = company === "takipay" ? '/' : ('/' + company);
                  }}>Sim</button>
                  <button type="button" onClick={() => setStatusLoggout(false)}>Não</button>
                </div>
              </div>
            </Modal>
          )
          :
          (<></>)
      }
      <Container>
      <Helmet>
        <meta name="kdt:page" content="account" />
      </Helmet>
        <HeaderLogged />
        <PerfilContent>

          <MenuLateral>
            {
              !validateRole('noPersonalData') ? 
              <>
              <MenuItem ative={arrayMenu[0]} onClick={() => {
                const array = [];
                array[0] = true;
                setArrayMenu(array);
                setMenuSelecionado(0);
              }}>
                <FaCogs color="#441188" size={36} /><span>Geral</span>
              </MenuItem>
              </>
              : <></>
            }
            
            <MenuItem ative={arrayMenu[1]} onClick={() => {
              const array = [];
              array[1] = true;
              setArrayMenu(array);
              setMenuSelecionado(1);
            }}>
              <FaLock color="#441188" size={36} /><span>Senha</span>
            </MenuItem>
            {
              !validateRole('noPersonalData') ? 
              <>
              <MenuItem ative={arrayMenu[2]} onClick={() => {
                const array = [];
                array[2] = true;
                setArrayMenu(array);
                setMenuSelecionado(2);
              }}>
                <FaMapMarkerAlt color="#441188" size={36} /> <span>Endereços</span>
              </MenuItem>
              <MenuItem ative={arrayMenu[3]} onClick={() => {
                const array = [];
                array[3] = true;
                setArrayMenu(array);
                setMenuSelecionado(3);
              }}>
                <FaRegCreditCard color="#441188" size={36} /> <span>Cartões</span>
              </MenuItem>
              </>
              : <></>
            }
            <MenuItem onClick={() => setStatusLoggout(true)} >
              <FaPowerOff color="#441188" size={36} /> <span>Sair</span>
            </MenuItem>
          </MenuLateral>
          {
            renderMenu()
          }

        </PerfilContent>
      </Container>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import {
  FaCreditCard,
  FaInfoCircle,
  FaRegCreditCard,
} from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import Swal from 'sweetalert2'


import validarCartao from '../../helpers/brandsCreditCard';

import {
  DataForm, Title, InputsCard, InputLabel, Button, TooltipCVV,
  OptionParcelas, ImgBandeiras
} from './styles';


import { cardMask, numberMask } from '../../helpers/masks';
import { formatPrice } from '../../helpers/format';
import bandeiras from '../../assets/brands/bandeiras-aceitas.png';

function FormPayer(props) {


  const [parcelaSelecionada, setParcelaSelecionada] = useState(props.simulacao.parcelaSelecionada);

  //dados cartão
  const [numeroCartao, setNumeroCartao] = useState(props.paymentData.numeroCartao ? props.paymentData.numeroCartao : '');
  const [nomeCartao, setNomeCartao] = useState(props.paymentData.nomeCartao ? props.paymentData.nomeCartao : '');
  const [mesCartao, setMesCartao] = useState(props.paymentData.mesCartao ? props.paymentData.mesCartao : '');
  const [anoCartao, setAnoCartao] = useState(props.paymentData.anoCartao ? props.paymentData.anoCartao : '');
  const [cvv, setCVV] = useState(props.paymentData.cvv ? props.paymentData.cvv : '');
  const [cardBrand, setCardBrand] = useState(props.paymentData.cardBrand ? props.paymentData.cardBrand : '');


  const tooltip = <Tooltip id="tooltip-cvv">
    <TooltipCVV>
      Digite o código de segurança de 3 ou 4 digítos que está no verso do cartão.
  <FaRegCreditCard size={20} />
    </TooltipCVV>

  </Tooltip>

  useEffect(() => {
    props.callbackNewTotal(parcelaSelecionada)

    //eslint-disable-next-line
  }, [parcelaSelecionada])

  // valida cartao
  useEffect(() => {
    if (numeroCartao.length >= 1) {
      const card = numeroCartao
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '');
      const brand = validarCartao(card);
      setCardBrand(brand);

    }
  }, [numeroCartao]);

  async function submit(e) {
    e.preventDefault();
    // setLoadingPayment(1)

    if (!cardBrand) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Bandeira do cartão não identificada!',
      })
      // setLoadingPayment(0);
      return
    }


    await props.callbackPayment({
      numeroCartao: numeroCartao
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', ''),
      nomeCartao,
      mesCartao,
      anoCartao,
      cvv,
      cardBrand,
      parcelaSelecionada
    });
    props.callbackNextStep();

    // setLoadingPayment(0);


  }

  return <DataForm onSubmit={submit}>
    <Title>
      <h4><FaCreditCard /> Dados do cartão de crédito</h4>
    </Title>
    <InputLabel>
      <label>Número do Cartão*</label>
      <input
        type="tel"
        required
        // minLength="16"
        // maxLength="19"
        value={cardMask(numeroCartao)}
        onChange={(e) => setNumeroCartao(e.target.value)}
      ></input>
    </InputLabel>

    <InputLabel>
      <label>TItular do Cartão*</label>
      <input
        required
        maxLength="40"
        value={nomeCartao}
        onChange={(e) => setNomeCartao(e.target.value)}
      ></input>
    </InputLabel>
    <InputsCard>
      <InputLabel>
        <label>Mês*</label>
        <select required value={mesCartao} onChange={e => setMesCartao(e.target.value)}>
          <option></option>
          <option value="01">01</option>
          <option value="02">02</option>
          <option value="03">03</option>
          <option value="04">04</option>
          <option value="05">05</option>
          <option value="06">06</option>
          <option value="07">07</option>
          <option value="08">08</option>
          <option value="09">09</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
        </select>
      </InputLabel>

      <InputLabel>
        <label>Ano*</label>
        <select required value={anoCartao} onChange={e => setAnoCartao(e.target.value)}>
          <option></option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
          <option value="2028">2028</option>
          <option value="2029">2029</option>
          <option value="2030">2030</option>
          <option value="2031">2031</option>
        </select>
      </InputLabel>

      <InputLabel>
        <label>CVV* <OverlayTrigger placement="top" overlay={tooltip}><FaInfoCircle /></OverlayTrigger></label>
        <input
          type="tel"
          required
          minLength="3"
          maxLength="4"
          value={numberMask(cvv)}
          onChange={(e) => setCVV(e.target.value)}
        ></input>
      </InputLabel>
    </InputsCard>
    <InputLabel>
      <label>Parcelas</label>
      <select value={parcelaSelecionada} onChange={e => setParcelaSelecionada(e.target.value)}>
        {
          props.simulacao.parcelas.map((item, index) => (
            <OptionParcelas key={item} value={index + 1}>
              {index + 1}x de {formatPrice((item / (index + 1)))}
            </OptionParcelas>
          ))
        }
      </select>
    </InputLabel>
    <ImgBandeiras src={bandeiras} alt="bandeiras cartão" />
    <Button type="submit">Próximo</Button>


  </DataForm>;
}

export default FormPayer;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';

import apiUser from '../../services/api-user';
import { Container, Content } from './styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';


export default function SolicitaRedefinirSenha() {
  const [email, setEmail] = useState('');
  const [enviado, setEnviado] = useState(false);
  const [loading, setLoading] = useState(0);

  async function redefinir(e) {
    e.preventDefault();
    setLoading(1);
    const resp = await apiUser.post('/v1/user/resetpassword', {
      email,
    }).catch((e) => {
      if (e.response.data.statusCode === '1008') {
        toast.error('E-mail não encontrado');
      } else {
        toast.error('Erro ao solicitar recuperação de senha');
      }
    });

    if (resp) {
      setEnviado(true);
    }
    setLoading(0);
  }

  return (
    <Container>
      <Helmet>
        {/* <meta name="kdt:page" content="password-reset" /> */}
        {/* <script src="https://storage.cloud.google.com/script-antifraude/pages.js" view="password-reset"></script> */}
        <script src="https://storage.googleapis.com/script-antifraude/pages.js" view="password-reset" data="taki" />
      </Helmet>
      <Header />
      <Content>
        <h1>Redefinir <strong>Senha</strong></h1>
        {
          enviado
            ? (<>
              <span>Recebemos sua <strong>solicitação</strong>! Em breve você receberá um e-mail com as instruções para redefinir sua <strong>senha</strong>.</span>
              <Link to="/">voltar a página inicial</Link>
            </>)
            : (
              <>
                <span>Insira seu endereço de <strong>e-mail</strong> para redefinir sua <strong>senha</strong></span>
                <form onSubmit={redefinir}>
                  <input required value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Digite seu e-mail" />
                  <button type="submit">
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>Solicitar</>
                    )}
                  </button>
                </form>
              </>
            )
        }
      </Content >
      <Footer />
    </Container >
  );
}

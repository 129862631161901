import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background-color: #EEE;
  min-height: 100%;
`;

export const MensagemMultas = styled.div`
    width: 100%;
    margin: 10px;
	font-size: 12px;
    text-align: left;
`;

export const PagamentoProcessado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1200px;

  margin-top: 50px;

  @media (max-width: 1300px) {
    width: 1000px;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 880px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    width: 580px;
  }

  @media (max-width: 414px) {
    width: 100%;
  }

`;

export const Simulacao = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  width: 60%;
  /* max-height: 600px; */

  @media (max-width: 1080px) {
    width: 86%;
  }

  @media (max-width: 414px) {
    /* max-height: 920px !important; */
  }

`;

export const SeparatorDiv = styled.div`
    border-top: 2px solid #c3c3c3;
`;

export const SimulacaoHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px;
  h4 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  div{
    display: flex;
    align-items: center;
    color: #c3c3c3;

    span{
      font-size: 12px;
    }

    img {
      margin-right: 5px;
      height: 20px;
    }
  }
`;

export const Parcelas = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 400px;

  flex-wrap: wrap;
  align-items: center;

  input {
    margin-right: 10px;
  }

  @media (max-width: 414px) {
    flex-wrap: nowrap;
    height: 750px;
  }

`;

export const ParcelasMobile = styled.ul`
  display: flex;
  flex-direction: column;
  height: 240px;
  flex-wrap: nowrap;
  align-items: center !important;
  justify-content: center !important;


`;

export const LinkMaisOpcoes = styled.span`
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;

    &:hover{
      color: #441188;
    }
`;

export const ParcelaItem = styled.button.attrs((props) => ({
  ative: props.ative,
  alter: props.alter,
}))`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  width: 280px;
  background-color: #dadee0;
  border-radius: 4px;
  margin: 5px;
  height: 50px;
  svg {
        color: #441188;
      }

  img {
    margin-left: 5px;
  }

  @media (max-width: 1300px) {
    width: 280px;
    img{
      display: none;
    }
  }

  @media (max-width: 700px) {
    width: 180px;
  }

  @media (max-width: 414px) {
    width: 230px;
  }

  &:hover{
    span{
      color: #FFF;
    }
  }

  ${(props) => props.ative
    && css`
      background-color: rgba(46, 204, 113, 0.2) !important;
      border: 2px solid #2ecc71;
      span{
      /* color: #FFF; */
    }
      svg {
        color: #fff;
      }
    `}

  ${(props) => props.alter
    && css`
      background-color: #fff;
    `}
`;

export const TextTaxas = styled.span`
  font-size: 12px;
`;

export const PagarButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  width: 250px;
  height: 50px;
  background: #28a745;

    &:hover{
        background-color: ${darken(0.08, '#28a745')};
    }

    @media (max-width: 1300px) {
        align-self: center;
        width: 200px;
        margin-right: 0px;
        font-size: 12px;
    }

    @media (max-width: 1080px) {
        width: 100%;
    }

  @media (max-width: 1300px) {
    align-self: center;
    width: 200px;
    margin-right: 0px;
    font-size: 12px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

// Debitos

export const DataInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* height: 700px; */
  height: auto;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px;
  width: 38%;

  @media (max-width: 1080px) {
    width: 86%;
  }
`;

export const Debitos = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* padding: 15px 0px 0px 5px; */
`;

export const DadosProprietario = styled.table`
    align-self: flex-start;
    margin: 20px 0px;
    font-size: 12px;
    td,
    th {
        padding: 0 10px;
    }
`;

export const DebitoItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0;
  border-top: 2px solid #441188;

`;

export const Fee = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 10px 0px 0px 0px;
  padding: 5px 0px 5px 15px;
  border-top: 1px solid #EEEEEE;
  margin-left: 10px;

  span {
    margin: 5px;
  }

`;

export const FeeRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;



    input{
      width: 16px;
      height: 16px;
    }

    div{
      display: flex;
      align-items: center;
    }
`;

export const FeeCheckBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 10px 0px 0px 0px;
  padding: 5px 0px 5px 15px;
  input {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }

`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #eee;
  margin-top: 10px;
  border-radius: 4px;
  padding-top: 10px;

  span {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const LoadingSimulacao = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
height: 80vh;
img{
  color: #441188;
}
span{
  margin-top: 15px;
  font-size: 18px;
}
`;

export const ErrorSimulacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;

  span, h1{
    text-align: center;
  }

  span{
    font-size: 16px;
  }

`;

export const PagamentoAprovado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 700px;
  margin-bottom: 40px;
  margin-top: 60px;
  text-align: center;

  img{
    height: 130px;
  }
  span{
    color: #441188;
    background-color: #F0FF0F;
  }

  h2{
    font-weight: bold;
    text-transform: uppercase;
    color: #441188;
    /* font-size: 40px; */
  }
  p{
    font-size: 22px;
  }

  @media (max-width: 414px){
    width: 300px;
    img {
    height: 80px;
   }
   h3{
    font-size: 30px;
   }
    p{
    font-size: 16px;
    }
  }

`;

export const H1Falha = styled.h1`
  color: #ee0066;
`;

export const LinkTryAgain = styled.a`
  margin-top: 20px;
  text-decoration: underline;

  &:hover{
    color: #3EA1B5;
  }
`;

export const CupomDiv = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  div{
        display: flex;
        justify-content: space-between;
        align-items: center;
  }

  label{
      align-self: flex-start;
      margin: 0;
  }

  input, button{
    width: 130px;
    display: flex;
    justify-content: center;
  }

  input{
    height: 41px;
    margin-right: 10px;
  }

  button{
    background-color: #441188;
    height: auto;
    margin: 0px;
  }

  @media (max-width: 1080px){
    input, button{
    width: 80px;
  }
   }

`;

export const FooterSimulacao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  margin-top: 10px;

  @media (max-width: 1080px){
    flex-direction: column;
   }
`;

export const LabelDesconto = styled.label`
  padding: 20px 5px;
  color: #28a745;

  @media (max-width: 1080px){
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

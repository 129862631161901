
export function renderGateway(gateway) {
  switch (gateway) {
    case 'mercadopago_vehicledebits_antifraud': return "Mercado Pago";
    case 'mercadopago_vehicledebits': return "Mercado Pago";
    case 'mercadopago_boleto_antifraud': return "Mercado Pago";
    case 'mercadopago_boleto': return "Mercado Pago";
    case 'mercadopago': return "Mercado Pago";
    case 'adiq': return "Adiq";
    case 'adiq_sefaz': return "Adiq";
    case 'cielo': return "Cielo";
    case 'konduto': return "Konduto";
    default: return "";
  }
}

export function renderCategory(category) {
  switch (category) {
    case 'vehicle-debits': return 'Débitos Veiculares';
    case 'vehicle-debits-ac': return 'Débitos Veiculares - AC';
    case 'vehicle-debits-al': return 'Débitos Veiculares - AL';
    case 'vehicle-debits-ap': return 'Débitos Veiculares - AP';
    case 'vehicle-debits-am': return 'Débitos Veiculares - AM';
    case 'vehicle-debits-ba': return 'Débitos Veiculares - BA';
    case 'vehicle-debits-ce': return 'Débitos Veiculares - CE';
    case 'vehicle-debits-es': return 'Débitos Veiculares - ES';
    case 'vehicle-debits-go': return 'Débitos Veiculares - GO';
    case 'vehicle-debits-ma': return 'Débitos Veiculares - MA';
    case 'vehicle-debits-mt': return 'Débitos Veiculares - MT';
    case 'vehicle-debits-ms': return 'Débitos Veiculares - MS';
    case 'vehicle-debits-mg': return 'Débitos Veiculares - MG';
    case 'vehicle-debits-pa': return 'Débitos Veiculares - PA';
    case 'vehicle-debits-pb': return 'Débitos Veiculares - PB';
    case 'vehicle-debits-pr': return 'Débitos Veiculares - PR';
    case 'vehicle-debits-pe': return 'Débitos Veiculares - PE';
    case 'vehicle-debits-pi': return 'Débitos Veiculares - PI';
    case 'vehicle-debits-rj': return 'Débitos Veiculares - RJ';
    case 'vehicle-debits-rn': return 'Débitos Veiculares - RN';
    case 'vehicle-debits-rs': return 'Débitos Veiculares - RS';
    case 'vehicle-debits-ro': return 'Débitos Veiculares - RO';
    case 'vehicle-debits-rr': return 'Débitos Veiculares - RR';
    case 'vehicle-debits-sc': return 'Débitos Veiculares - SC';
    case 'vehicle-debits-sp': return 'Débitos Veiculares - SP';
    case 'vehicle-debits-se': return 'Débitos Veiculares - SE';
    case 'vehicle-debits-to': return 'Débitos Veiculares - TO';
    case 'vehicle-debits-df': return 'Débitos Veiculares - DF';
    case 'boletos': return 'Boletos';
    case 'recurrence': return 'Recorrência';
    default: return category;
  }
}

export function renderActions(action) {
  switch (action) {
    case 'reserve': return 'Reserva';
    case 'capture': return 'Captura';
    case 'cancel': return 'Cancelamento';
    case 'analyze': return 'Análise';
    case 'webhook': return 'Webhook';
    case 'validate': return 'Validação';
    case 'manual': return 'Manual';
    case 'admin': return 'Manual';
    default: return '';
  }
}

export function renderStatus(label) {
  switch (label) {
    case 'approved': return "Aprovado";
    case 'declined': return "Recusado";
    case 'canceled': return "Cancelado";
    case 'cancel': return "Cancelado";
    case 'pending': return "Pendente";
    case 'fraud': return "Fraude";
    case 'capture': return "Captura";
    case 'transactioning': return "Transacionando";
    case 'not_authorized': return "Não autorizado";
    case 'error': return "Erro";
    default: return "";
  }
}

export function renderTypePayment(label) {
  switch (label) {
    case 'CreditCard': return 'Crédito';
    case 'DebitCard': return 'Débito';
    default: return "";
  }
}

export function renderIdentificationDebits(label) {

  if (label.includes("#LICENSING")) {
    return label.split("#")[0] + ' Licenciamento ' + label.split("#")[2];
  }

  else if (label.includes("#IPVA")) {
    return label.split("#")[0] + ' IPVA ' + label.split("#")[2];
  }

  else if (label.includes("#DPVAT")) {
    return label.split("#")[0] + ' DPVAT ' + label.split("#")[2];
  }

  else if (label.includes("#TICKET")) {
    return label.split("#")[0] + ' Multa ' + label.split("#")[2];
  }

  else if (label.includes("#CRLV")) {
    return label.split("#")[0] + ' CRLV ' + label.split("#")[2];
  }

  return label;


}

export function renderStatusAction(action){
  switch (action) {
    case 'capture-approved': return 'Captura - Aprovada';
    case 'capture-error': return 'Captura - Erro';
    case 'capture-declined': return 'Captura - Recusada';
    case 'reserve-approved': return 'Reserva - Aprovada';
    case 'reserve-error': return 'Reserva - Erro';
    case 'reserve-declined': return 'Reserva - Recusada';
    case 'cancel-approved': return 'Cancelamento - Aprovada';
    case 'cancel-error': return 'Cancelamento - Erro';
    case 'cancel-declined': return 'Cancelamento - Recusada';
    case 'validate-approved': return 'Validação - Aprovada';
    case 'validate-error': return 'Validação - Erro';
    case 'validate-declined': return 'Validação - Recusada';
    case 'analyze-approved': return 'Análise - Aprovada';
    case 'analyze-pending': return 'Análise - Pendente';
    case 'analyze-error': return 'Análise - Erro';
    case 'analyze-canceled': return 'Análise - Cancelado';
    case 'analyze-declined': return 'Análise - Recusada';
    case 'analyze-fraud': return 'Análise - Fraude';
    case 'analyze-not_authorized': return 'Análise - Não autorizada';
    case 'webhook-approved': return 'Webhook - Aprovada';
    case 'webhook-pending': return 'Webhook - Pendente';
    case 'webhook-error': return 'Webhook - Erro';
    case 'webhook-canceled': return 'Webhook - Cancelado';
    case 'webhook-declined': return 'Webhook - Recusada';
    case 'webhook-fraud': return 'Webhook - Fraude';
    case 'webhook-not_authorized': return 'Webhook - Não autorizada';
    case 'subscription-approved': return 'Assinatura - Aprovada';
    case 'subscription-declined': return 'Assinatura - Recusada';
    case 'subscription-pending': return 'Assinatura - Pendente';
    case 'subscription-error': return 'Assinatura - Erro';
    case 'total': return 'Total';
    default: return action;
  }
}







import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import Parcelas from '../../../components/Parcelas';
import apiBoleto from '../../../services/simulacoes';
import {
    Container,
    Content,
    DataInfo,
    Debitos,
    LoadingSimulacao,
    DadosBoleto,
    Total,
    ErrorSimulacao,
    LinkTryAgain,
    H1Falha,
    DebitoRow,
} from './styles';
import HeaderDate from '../../../components/HeaderDate';
import Footer from '../../../components/Footer';
import { formatPrice } from '../../../helpers/format';

// imgs
import valor from '../../../assets/produtos/valorpayment.png';


function SimulacoesBoleto(props) {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    // simulacao
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [guid, setGuid] = useState('');

    // #Data hubspot
    const [userTokenData, setUserTokenData] = useState(Object);
    const [seller, setSeller] = useState(Object);

    // error
    const [erroConsulta, setErroConsulta] = useState(false);
    const [messageError, setMessageError] = useState('');

    // parcelas
    const [parcelaSelecionada, setParcelaSelecionada] = useState(12);
    const [parcelas, setParcelas] = useState([]);
    const [parcelasIniciais, setParcelasIniciais] = useState([]);
    const [cp, setCp] = useState(Object);

    function recalculateTotal(coupon) {
        let totalDebitosCalculado = 0;
        const newItems = items;
        newItems.map((item) => totalDebitosCalculado += item.originalAmount);

        const parcelasRecalculadas = newItems.map((each) => each.taxes.map((tax) => {
            if (coupon && coupon.service === 'fee') {
                return ((100 - coupon.discount) / 100) * (tax - each.originalAmount) + each.originalAmount;
            }

            return tax;
        }))
            .reduce((total, next) => total.map((each, i) => each + next[i]), [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]).map(item => Math.round(item))


        const parcelasRecalculadasSemDesconto = items.map((each) => each.taxes)
            .reduce((total, next) => total.map((each, i) => each + next[i]), [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]).map(item => Math.round(item))

        setParcelasIniciais(parcelasRecalculadasSemDesconto);
        setParcelas(parcelasRecalculadas);
        setTotal(totalDebitosCalculado);
    }

    // load data
    useEffect(() => {
        setLoading(1);
        // if (window.screen.width <= 1024) {
        //   setMobileState(true);
        // }

        async function loadData() {
            const { userToken } = props.match.params;
            let resp = null;

            resp = await apiBoleto.post('/simulation/items', {
                userToken
            }).catch((e) => {
                setErroConsulta(true);
                if (e.response && e.response.data) {
                    setMessageError(renderErrorMessage(e.response.data.statusCode));
                }

                setLoading(0);
            });

            if (resp) {
                const { data } = resp;

                setSeller(data.seller);
                if (data.user) {
                    setUserTokenData(data.user);
                }

                if (data.items.length === 0 || (data.items.length === 1 && data.items[0].originalAmount === 0)) {
                    setErroConsulta(true);
                    setMessageError(data.message ? data.message : '');
                    setLoading(0);
                    return;
                }

                if (data.items.length === 1 && !data.items[0].taxes) {
                    setErroConsulta(true);
                    setMessageError(data.items[0].data.message ? data.items[0].data.message : '');
                    setLoading(0);
                    return;
                }

                let total = 0;
                const totalTaxas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                resp.data.items.map((item) => {
                    total += item.originalAmount;
                    return item.taxes.map((tax, index) => totalTaxas[index] += tax);
                });

                setParcelas(totalTaxas);
                setParcelasIniciais(totalTaxas);
                setItems(data.items);
                setGuid(data.guid);
                setTotal(total);
                setLoading(0);
            }
        }
        loadData();
        // eslint-disable-next-line
    }, []);

    function renderErrorMessage(statusCode) {
        switch (statusCode) {
            case '1': return 'Confira o código de barras do boleto e tente novamente';
            case '2': return 'Confira seus dados e tente novamente';
            case '3': return 'Confira seus dados e tente novamente';
            case '4': return 'Link de simulação expirado';
            case '5': return 'Link de simulação expirado';
            case '6': return 'Confira o código de barras do boleto e tente novamente';
            case '7': return 'Nenhum boleto encontrado';
            default: return 'Erro ao simular, tente novamente mais tarde';
        }
    }


    function renderSimulacao() {

        if (erroConsulta === false) {
            return (
                <Content>
                    <DataInfo>
                        <Debitos>
                            <img src={valor} alt="valor" />
                            <DadosBoleto />
                            {
                                items.map((item, i) => (
                                    <DebitoRow key={i}>

                                        {
                                            item.message
                                                ? (<>
                                                    <span><strong>Descrição:</strong> {item.identification}</span>
                                                    <span><strong>Erro: </strong>{item.data.message}</span>
                                                </>)
                                                : (<>
                                                    {/* <span><strong>Código de barras: </strong>{item.data.barCode}</span> */}
                                                    <span><strong>Descrição:</strong> {item.identification}</span>
                                                    <div>
                                                        <span><strong>Valor: </strong></span>
                                                        <span>{formatPrice(item.originalAmount)}</span>
                                                    </div>
                                                </>
                                                )
                                        }


                                    </DebitoRow>
                                ))
                            }


                        </Debitos>
                        <Total>
                            <span>Total de Débitos:</span>
                            <span>{formatPrice(total)}</span>
                        </Total>
                    </DataInfo>
                    <Parcelas parcelas={parcelas}
                        callbackParcelas={(parcel) => setParcelaSelecionada(parcel)}
                        callbackRecalculate={(coupon) => {
                            recalculateTotal(coupon);
                            setCp(coupon)
                        }}
                        callbackHandleSubmit={() => handleSubmit()}
                        taxesDescription={
                            items[0].category.includes('vehicle-debits') ?
                                ["* Custo da operação de 2,95% a.m. aplicável para parcelamento em 12x. Para demais parcelas, consulte.",
                                    "** Não Cobramos Taxa de Despachante"]
                                :
                                ["* Custo da operação de 3,15% a.m. aplicável para parcelamento em 12x. Para demais parcelas, consulte"]
                        }
                    />

                </Content>
            );
        }


        return (

            <ErrorSimulacao>
                <H1Falha>Falha ao realizar simulação</H1Falha>
                <span><strong>Erro:</strong> {messageError}</span>
                <LinkTryAgain href={`/simulacoes-por-valor/${props.match.params.userToken}`}>Tentar novamente</LinkTryAgain>
            </ErrorSimulacao>

        );
    }

    async function handleSubmit() {

        const { userToken } = props.match.params;

        const totalSelecionadoComDesconto = parcelas[parcelaSelecionada - 1];
        const valorParcelaSelecionadaComDesconto = Math.round(parcelas[parcelaSelecionada - 1] / parcelaSelecionada);

        const totalSelecionado = parcelasIniciais[parcelaSelecionada - 1];
        const valorParcelaSelecionada = Math.round(parcelasIniciais[parcelaSelecionada - 1] / parcelaSelecionada);

        dispatch({
            type: '@cart/UPDATE_VALUES',
            simulacao: {
                product: items[0].category,
                description: 'Pagamento Valor',
                guid,
                parcelaSelecionada,
                valorParcelaSelecionada,
                valorParcelaSelecionadaComDesconto,
                totalSelecionado,
                totalSelecionadoComDesconto,
                coupon: cp,
                parcelas,
                parcelasIniciais,
                userToken,
                userTokenData,
                seller,
                originalAmount: total,
                items,
            },
        });

        props.history.push(`/pagamento/`)
    }

    return (
        <Container>
            <HeaderDate />
            {
                loading
                    ? (<LoadingSimulacao>
                        <Spinner animation="border" variant="primary" />
                        <span>Consultando dados, aguarde...</span>
                    </LoadingSimulacao>)
                    : <>
                        {renderSimulacao()}
                    </>
            }
            <Footer />
        </Container>
    );
}

export default connect((state) => ({
    user: state.user,
    pagamento: state.pagamento,
}))(SimulacoesBoleto);

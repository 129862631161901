import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { FaFileInvoice } from 'react-icons/fa'

import {
    Container,
    Content,
    Cards,
    CardItem,
    CacheForm
} from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import CancelarPagamentoPOS from '../../../../components/CancelarPagamentoPOS';
import apiItems from '../../../../services/simulacoes';
import { validateRole } from '../../../../helpers/roles';

import imgLinkPagamento from '../../../../assets/portal/valorpayment.png';
import imgTransacoes from '../../../../assets/portal/transacoes.png';
import imgCache from '../../../../assets/portal/cache.png';
import imgRecurrence from '../../../../assets/portal/recurrence.png';
import imgPos from '../../../../assets/portal/pos.png';
import imgPosCancel from '../../../../assets/portal/cancel.svg';

function Portal({ history }) {
    const token = localStorage.getItem('token');

    //pos
    const [statusPOS, setStatuPOS] = useState(0);

    // cache
    const [statusCacheForm, setStatusCacheForm] = useState(0);
    const [placeholder, setPlaceholder] = useState('Identificação');
    const [categoryCache, setCategoryCache] = useState('');
    const [identification, setIdentification] = useState('');
    const [loadingCache, setLoadingCache] = useState(0);

    async function removeCache(e) {
        e.preventDefault();
        setLoadingCache(1);
        const res = await apiItems.delete(`simulation/items/cache/${categoryCache}/${identification}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e => toast.error('Cache não encontrado'));

        if (res && res.status) {
            toast.success('Cache removido com sucesso');
            setIdentification("")
        }
        setLoadingCache(0);
    }

    function showPOS(){
        setStatuPOS(!statusPOS);
        setStatusCacheForm(false);
    }

    function showCacheForm(){
        setStatusCacheForm(!statusCacheForm);
        setStatuPOS(false);
    }


    return <Container>
        <HeaderLogged />
        <Content>
            <h1>Portal Atendimento</h1>
            <Cards>
                {
                    validateRole('usersTransactionsRead') ?
                    <CardItem onClick={() => history.push('/portal/transacoes')}>
                        <img src={imgTransacoes} alt="link pagamento" />
                        <span>Transações</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('usersRecurrencesRead') ? 
                    <CardItem onClick={() => history.push('/portal/recorrencia')}>
                        <img src={imgRecurrence} alt="Recorrência" />
                        <span>Recorrência</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('transactionLinkRead') ? 
                    <CardItem onClick={() => history.push('/portal/links')}>
                        <img src={imgLinkPagamento} alt="link pagamento" />
                        <span>Links de Pagamento</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('posRead') ? 
                    <CardItem onClick={() => history.push('/portal/pos')}>
                        <img src={imgPos} alt="pos" />
                        <span>Consulta POS</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('posCancel') ? 
                    <CardItem onClick={showPOS}>
                        <img src={imgPosCancel} alt="cancel-pos" />
                        <span>Cancelar POS</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('cacheVehicleDebitsDelete') ? 
                    <CardItem onClick={showCacheForm}>
                        <img src={imgCache} alt="link pagamento" />
                        <span>Limpar cache</span>
                    </CardItem>
                    : (<></>)
                }
                {
                    validateRole('cidadetranRead') ? 
                    <CardItem onClick={() => history.push('/portal/cidadetran')}>
                        <FaFileInvoice color="#441188" size={50} />
                        <span>Cidadetran</span>
                    </CardItem>
                    : (<></>)
                }
            </Cards>
            {
                statusPOS ? 
                (<CancelarPagamentoPOS />)
                :
                (<></>)
            }

            {
                statusCacheForm ?
                    (<CacheForm onSubmit={removeCache}>
                        <select required onChange={e => {
                            setCategoryCache(e.target.value)
                            if (e.target.value === 'boletos') {
                                setPlaceholder("Digite o código de barras")
                            }
                            if (e.target.value === 'vehicle-debits') {
                                setPlaceholder("Digite o renavam")
                            }
                        }}>
                            <option value="">Selecione Categoria</option>
                            <option value="vehicle-debits">Débitos Veiculares</option>
                            <option value="boletos">Boletos</option>
                        </select>
                        <input required value={identification} onChange={e => setIdentification(e.target.value)} placeholder={placeholder} />
                        <button type="submit">
                            {
                                loadingCache ?
                                    (<Spinner animation="border" variant="light" size="sm" />)
                                    :
                                    (<>Remover cache</>)
                            }
                        </button>
                    </CacheForm>)
                    :
                    (<></>)
            }
        </Content>
    </Container>;
}

export default Portal;
/* eslint-disable no-sparse-arrays */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { decode } from 'jsonwebtoken';
import { connect, useDispatch } from 'react-redux';

import apiUsers from '../../services/api-user';
import HeaderLogged from '../../components/HeaderLogged';
import { validateRole } from '../../helpers/roles';

import {
  Container,
  Content,
  ProdutoItem,
} from './styles';

import car from '../../assets/produtos/debitoveicular.png';
import boleto from '../../assets/produtos/boleto.png';
import valor from '../../assets/produtos/valor.png';
import portal from '../../assets/produtos/logo-portal.png';

function Dashboard({ history, simulacao }) {
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadDataUser() {
      const token = localStorage.getItem('token');
      // const refresh = localStorage.getItem('refresh_token');

      const tokenDecoded = decode(token);

      const idUser = tokenDecoded.sub;
      const user = await apiUsers.get(`/v1/user/${idUser}`, { headers: { Authorization: `Bearer ${token}` } });

      dispatch({
        type: '@user/USER_DATA',
        dados: user.data,
      });
    }

    loadDataUser();
  }, [dispatch]);


  return (
    <Container>
      <HeaderLogged />
      <Content>
        <h1>Selecione um <strong>produto</strong></h1>
        <div>
          {
            !validateRole('noVehicleDebitsSimulate') ?
            <Link to="/debitos-veiculares">
              <ProdutoItem>
                <img src={car} alt="car" />
                <span>Débitos Veiculares</span>
              </ProdutoItem>
            </Link>
            : (<></>)
          }
          {
            !validateRole('noBoletosSimulate') ?
            <Link to="/boletos" >
              <ProdutoItem>
                <img src={boleto} alt="boleto" />
                <span>Boletos</span>
              </ProdutoItem>
            </Link>
            : (<></>)
          }
         {
         validateRole('transactionLinkCreate')
           ? (
               <>
           <Link to="/simulacoes-por-valor" >
            <ProdutoItem>
              <img src={valor} alt="valor" />
              <span>Simulação por valor</span>
            </ProdutoItem>
          </Link>
          </>
          )
           : (
             <></>
           )
          }
          {
            validateRole('usersTransactionsRead') || validateRole('usersRecurrencesRead') || validateRole('transactionLinkRead') || validateRole('posRead') || validateRole('posCancel') || validateRole('cacheVehicleDebitsDelete') || validateRole('cidadetranRead') ?
            (
               <>
          <Link to="/portal" >
            <ProdutoItem>
              <img src={portal} alt="portal" />
              <span>Portal Atendimento</span>
            </ProdutoItem>
          </Link>
          </>
          )
           : (
             <></>
           )
          }

        </div>
      </Content>
      {/* <Footer /> */}
    </Container>
  );
}

export default connect((state) => ({
  user: state.user,
  simulacao: state.taxas,

}))(Dashboard);

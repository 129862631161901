import React, { useState, useEffect } from 'react';
import {
    FaUser, FaUserTie, FaCheckCircle,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api-user';
import { isValid } from 'cpf';
import { validate } from 'cnpj';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';


import { cnpjMask, cpfMask, phoneMask, cepMask } from '../../helpers/masks';
import {
    Container, Content, Input, NextButton, PersonType, Etapa1, PessoaFisica, PessoaJuridica, Form, Finish,
} from './styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import StepsIndicator from '../../components/StepsIndicator';

const schema = Yup.object().shape({
    email: Yup.string()
        .email('Insira um e-mail válido')
        .required('O e-mail é obrigatório'),
    password: Yup.string()
        .min(6, 'A senha deve conter no minimo 6 caracteres')
        .required('A senha é obrigatória'),
});

export default function Cadastro() {
    const [typePerson, setTypePerson] = useState('');

    //
    const [checkPessoaFisica, setCheckPessoaFisica] = useState(false);
    const [checkPessoaJuridica, setCheckPessoaJuridica] = useState(false);

    //steps
    // 1 - usuário
    // 2 - pagador
    // 3 - endereço
    // 4 - pagamento
    const [step, setStep] = useState(1);
    const [stepsDone, setStepsDone] = useState(1);

    // bar progress
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(0);

    // data user
    const [nome, setNome] = useState('');
    const [sobrenome, setSobreNome] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [telefone, setTelefone] = useState('');
    const [username, setUsername] = useState('');
    const [cpf, setCpf] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [complemento, setComplemento] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    useEffect(() => {
        async function loadCEP() {
            const resp = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
            const { data } = resp;
            setCidade(data.localidade);
            setEstado(data.uf);
            setRua(data.logradouro);
            setBairro(data.bairro);
        }
        if (cep.length >= 9) {
            loadCEP();
        }
    }, [cep]);


    useEffect(() => {

    }, [typePerson]);

    async function handleSubmit() {
        setLoading(1);

        const newTel = telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        const newCpf = cpf.replace('.', '').replace('.', '').replace('-', '');
        const newCep = cep.replace('-', '');

        const response = await api.post('/v1/user', {
            userName: username,
            email,
            firstName: nome,
            lastName: sobrenome,
            cpf: newCpf,
            password,
            phones: [{
                type: newTel.length === 10 ? 'phone' : 'cellphone',
                number: newTel,
            }],
            addresses: [{
                number: numero,
                complement: complemento,
                street: rua,
                state: estado,
                city: cidade,
                neighborhood: bairro,
                zipCode: newCep,
                country: 'BRA',
            }],

        }).catch((e) => {
            if (e.response && e.response.data.message === 'Cpf already exists') {
                toast.error('Esse CPF já foi cadastrado');
                setLoading(0);
                return;
            }

            if (e.response && e.response.data.message === 'User exists with same username') {
                toast.error('Esse username já está em uso');
                setLoading(0);
                return;
            }

            if (e.response && e.response.data.message === 'User exists with same email') {
                toast.error('Esse email já está em uso');
                setLoading(0);
                return;
            }

            toast.error('Erro ao realizar cadastro! ');
            setLoading(0);
        });

        if (response) {
            if (response.status === 201) {
                setFinish(true);
                setLoading(0);
            }
        }
    }

    function renderSwitch() {
        switch (step) {
            case 1:
                return (
                    <Etapa1>
                        <PersonType>
                            <PessoaFisica>
                                <FaUser size={50} color="#441188" />
                                <span>Pessoa Física</span>
                                <input type="radio" checked={checkPessoaFisica} onChange={() => {
                                    setTypePerson('fisica');
                                    setCheckPessoaFisica(true);
                                    setCheckPessoaJuridica(false);
                                }} name="pessoa" />
                            </PessoaFisica>
                            <PessoaJuridica>
                                <FaUserTie size={50} color="#441188" />
                                <span>Pessoa Jurídica</span>
                                <input disabled type="radio" checked={checkPessoaJuridica} onChange={() => {
                                    setTypePerson('juridica');
                                    setCheckPessoaJuridica(true);
                                    setCheckPessoaFisica(false);
                                }} name="pessoa" />
                            </PessoaJuridica>

                        </PersonType>

                        <NextButton onClick={() => {
                            if (!typePerson) {
                                toast.error('Selecione uma opção');
                                return;
                            }
                            setStep(2);
                            setStepsDone(2);
                        }}>AVANÇAR</NextButton>
                    </Etapa1>
                );
            case 2:

                return (<Form>
                    {
                        typePerson === 'fisica'

                            ? (<>
                                <Input type="text" required value={nome} onChange={(e) => setNome(e.target.value)} placeholder="*Digite seu nome" />
                                <Input type="text" required value={sobrenome} onChange={(e) => setSobreNome(e.target.value)} placeholder="*Digite seu sobrenome" />
                                <Input type="tel" value={cpfMask(cpf)} onChange={(e) => setCpf(e.target.value)} placeholder="*Digite seu CPF" />
                            </>)
                            : (<>
                                <Input type="text" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} placeholder="*Razão Social" />
                                <Input type="text" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} placeholder="*Nome Fantasia" />
                                <Input type="tel" value={cnpjMask(cnpj)} onChange={(e) => setCnpj(e.target.value)} placeholder="*Digite seu CNPJ" />
                            </>)
                    }
                    <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="*Escolha um nome de usuário" />
                    <Input type="tel" value={phoneMask(telefone)} onChange={(e) => setTelefone(e.target.value)} placeholder="*Digite seu telefone" />
                    <NextButton onClick={() => {
                        if (typePerson === 'fisica') {
                            if (!nome || !telefone || !cpf) {
                                toast.error('Preencha todos os campos');
                                return;
                            }
                            if (!isValid(cpf)) {
                                toast.error('CPF inválido');
                                return;
                            }
                        } else {
                            if (!nomeFantasia || !telefone || !cnpj) {
                                toast.error('Preencha todos os campos');
                            }

                            if (!validate(cnpj)) {
                                toast.error('CNPJ inválido');
                                return;
                            }
                        }

                        if (telefone.length < 14) {
                            toast.error('Telefone inválido');
                            return;
                        }
                        setStep(3);
                        setStepsDone(3);
                    }}>AVANÇAR</NextButton>

                </Form>);

            case 3:
                return (
                    <Form>
                        <Input type="tel" value={cepMask(cep)} onChange={(e) => setCep(e.target.value)} placeholder="*Digite seu CEP" />
                        <Input type="text" value={rua} onChange={(e) => setRua(e.target.value)} placeholder="*Digite o nome da rua" />
                        <Input type="text" value={numero} onChange={(e) => setNumero(e.target.value)} placeholder="*Digite o número" />
                        <Input type="text" value={complemento} onChange={(e) => setComplemento(e.target.value)} placeholder="Complemento" />
                        <Input type="text" value={bairro} onChange={(e) => setBairro(e.target.value)} placeholder="*Digite seu bairro" />
                        <Input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)} placeholder="*Digite sua cidade" />
                        <Input type="text" value={estado} onChange={(e) => setEstado(e.target.value)} placeholder="*Digite seu estado" />

                        <NextButton onClick={() => {
                            if (!cep || !rua || !numero || !bairro || !cidade || !cidade || !estado) {
                                toast.error('Preencha todos os campos obrigatórios');
                                return;
                            }
                            setStep(4);
                            setStepsDone(4);
                        }}>AVANÇAR</NextButton>

                    </Form>
                );

            case 4:
                return (
                    <form onSubmit={async (e) => {
                        e.preventDefault();
                        if (password !== passwordConfirm) {
                            toast.error('As senhas não coincidem');
                            return;
                        }


                        schema.validate({ email, password }).catch((err) => {
                            toast.error(err.errors[0]);
                        });


                        if (await schema.isValid({ email, password })) {
                            await handleSubmit();
                        }
                    }}>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="*Digite seu endereço de e-mail" />
                        <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="*Digite sua senha" />
                        <Input type="password" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} placeholder="*Confirme a senha" />
                        <NextButton type="submit">{
                            loading
                                ? <Spinner animation="border" variant="light" size="sm" />
                                : <>FINALIZAR CADASTRO</>
                        }</NextButton>

                    </form>
                );
            default: break;
        }
    }

    return (
        <Container>
            <Helmet>
                <meta name="kdt:page" content="account_creation" />
            </Helmet>
            <Header />

            {
                !finish
                    ? (<Content>
                        <h1>Realize seu <strong>cadastro!</strong></h1>
                  
                        <StepsIndicator steps={['file', 'user', 'address', 'lock']} stepsDone={stepsDone} callbackChange={step => setStep(step)} />
                        {
                            renderSwitch()
                        }


                    </Content>)
                    : (<Content>
                        <Finish>
                            <h1>Seu <strong>cadastro</strong> foi realizado com <span>sucesso</span>!</h1>
                            <FaCheckCircle color="#41AD49" size={56} />
                            <Link to="/">Realize seu primeiro login!</Link>
                        </Finish>

                    </Content>)
            }

            <Footer />
        </Container>


    );
}

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'

import {
    Container,
    Content,
    LoadingDiv,
    Header,
    DivPayer,
    DivItems,
    LabelsItem,
    ItemRow,
    DivPayment,
    DivPaymentContent,
    Footer,
    DownloadCRLVButton,
    DivError
} from './styles';
import apiTransaction from '../../services/transacao';
import { formatPrice, formatDateBarWithHours } from '../../helpers/format';
import { renderCategory, renderTypePayment, renderIdentificationDebits } from '../../helpers/labels/renders';
import logo from '../../assets/taki.png';

function Comprovante({ match }) {

    const [loading, setLoading] = useState(0);
    const [transaction, setTransaction] = useState(Object);

    useEffect(() => {
        loadData();

        //eslint-disable-next-line
    }, []);

    async function loadData() {
        setLoading(1);
        const res = await apiTransaction.get(`/ecommerce/transaction/${match.params.guid}`).catch(e => Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erro ao buscar dados!',
        }));

        if (res && res.data && res.data.items) {
            setTransaction(res.data);
        }
        setLoading(0);
    }

    async function loadCRLV() {
        const renavam = transaction.items[0].data.renavam

        const res = await apiTransaction.get(`/ecommerce/crlve/${renavam}`).catch(e => Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erro baixar o documento!',
        }));

        if (res && res.data) {
            const pdf = res.data.pdf;
            let hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:application/pdf;base64,' + encodeURI(pdf);
            hiddenElement.target = '_blank';
            hiddenElement.download = `crlv-${renavam}.pdf`;
            hiddenElement.click();
        }


    }

    return (<Container>
        {
            loading ?
                (
                    <LoadingDiv>
                        <Spinner animation="border" variant="info" />
                    </LoadingDiv>
                )
                :
                (<Content>

                    {
                        transaction.items ?
                            (<>
                                <Header>
                                    <img src={logo} alt="logo" />
                                    <div>
                                        <h1>Comprovante de transação bancária</h1>
                                        {/* <h2>Débitos de Veículos (IPVA, DPVAT, licenciamento, multas e outras taxas / serviços)</h2> */}
                                    </div>
                                </Header>

                                <DivPayer>
                                    <h3>Pagador</h3>
                                    <div>
                                        <label><strong>Nome: </strong></label>
                                        <label>{transaction.payer.name}</label>
                                    </div>
                                    <div>
                                        <label><strong>E-mail: </strong></label>
                                        <label>{transaction.payer.email}</label>
                                    </div>
                                </DivPayer>
                                <DivItems>
                                    <h3>Itens</h3>
                                    {
                                        transaction.items ? transaction.items.map(item => (
                                            <ItemRow key={item.identification}>
                                                <LabelsItem sm>
                                                    <label><strong>Categoria:</strong></label>
                                                    <label>{renderCategory(item.category)}</label>
                                                </LabelsItem>
                                                <LabelsItem>
                                                    <label><strong>Descrição:</strong></label>
                                                    <p>{item.identification ? renderIdentificationDebits(item.identification) : item.description}</p>
                                                </LabelsItem>
                                                <LabelsItem sm>
                                                    <label><strong>Valor:</strong></label>
                                                    <label>{formatPrice(item.originalAmount)}</label>
                                                </LabelsItem>
                                            </ItemRow>
                                        ))
                                            :
                                            (<></>)
                                    }
                                </DivItems>
                                <DivPayment>
                                    <h3>Dados do pagamento</h3>
                                    <DivPaymentContent>
                                        <div>
                                            <label><strong>Valor original:</strong></label>
                                            <label>{transaction.payment.originalAmount ? formatPrice(transaction.payment.originalAmount) : ""}</label>
                                        </div>
                                        <div>
                                            <label><strong>Valor pago:</strong></label>
                                            <label>{formatPrice(transaction.payment.amount)}</label>
                                        </div>
                                        <div>
                                            <label><strong>Desconto:</strong></label>
                                            <label>{transaction.payment.discount ? formatPrice(transaction.payment.discount) : formatPrice(0)}</label>
                                        </div>
                                        <div>
                                            <label><strong>Parcelas:</strong></label>
                                            <label>{transaction.payment.installments}x</label>
                                        </div>
                                        <div>
                                            <label><strong>Data do pagamento:</strong></label>
                                            <label>{formatDateBarWithHours(transaction.date)}</label>
                                        </div>
                                        <div>
                                            <label><strong>Forma de pagamento:</strong></label>
                                            <label>{renderTypePayment(transaction.payment.type)} - {transaction.card.brand}</label>
                                        </div>
                                    </DivPaymentContent>
                                </DivPayment>
                                {
                                    transaction.crlveCreated
                                        ?
                                        <DownloadCRLVButton type="button" onClick={loadCRLV}>GERAR CRLVE</DownloadCRLVButton>
                                        :
                                        (<></>)
                                }
                            </>)
                            :
                            (<>
                                <img src={logo} alt="logo" />
                                <DivError>Comprovante não encontrado...</DivError>
                            </>)
                    }
                    <Footer>
                        <label><strong>Horário de atendimento:</strong></label>
                        <label>Segunda a sexta: das 8h às 18h</label>
                        <a href="https://www.takipay.com.br" target="_blank" rel="noopener noreferrer">www.takipay.com.br</a>
                    </Footer>
                </Content>)
        }
    </Container>);
}

export default Comprovante;
import React, { useState, useEffect } from 'react';
import { Spinner, Breadcrumb, Table } from 'react-bootstrap';
import { FaUnlock } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Container, Content, TableDiv, Button } from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiCidadetran from '../../../../services/cidadetran';

function Bloqueados ({ match }) {
    const token = localStorage.getItem('token');
    const [protocols, setProtocols] = useState(Array);
    const [loading, setLoading] = useState(0);


    useEffect(() => {
        async function loadData() {
            setLoading(1);
            const res = await apiCidadetran.get(`/cidadetran/blocked`, { headers: { Authorization: `Bearer ${token}` } })
                .catch(e => Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro ao buscar dados!',
                }));

            if (res && res.data) {
                setProtocols(res.data);
            }
            setLoading(0);
        }
        loadData();
        //eslint-disable-next-line
    }, [])


    async function unblock(guid) {
        Swal.fire({
            title: "Desbloquear Pagamento",
            html: 'Deseja desbloquear o protocolo ' + guid + '?',
            showCancelButton: true,
            confirmButtonText: `Desbloquear`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiCidadetran.get(`/cidadetran/unblock/${guid}`, { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possível desbloquear o protocolo.',
                    })
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Desbloqueado',
                    'O Protocolo entrará no próximo arquivo gerado.',
                    'success'
                )
            }
            document.querySelector('#protocol_' + guid).remove()
        })
    }

    return <Container>
        <HeaderLogged />
        <Content>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal/cidadetran" exact>
                    <Breadcrumb.Item>Cidadetran</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Protocolos Bloqueados</Breadcrumb.Item>
            </Breadcrumb>
            {
                !loading ?
                    (<>
                        <TableDiv>
                            <Table responsive="xl" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Protocolo</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        protocols.map(each => (
                                            <tr key={each.guid} id={`protocol_${each.guid}`}>
                                            <td><Link to={`/portal/transacoes/${each.guid}`}>{each.guid}</Link></td>
                                            <td style={{textAlign: 'center', padding: '3px', display: 'block', margin: 'auto'}}><Button color="#ff4d4d" style={{display: 'inline-block', width: '40px', margin: '0', padding: '2px 5px'}} type="button" onClick={() => unblock(`${each.guid}`)}><FaUnlock /></Button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </TableDiv>
                    </>)
                    :
                    (<Spinner animation="border" variant="secondary" />)
            }
        </Content>
    </Container>;
}

export default Bloqueados;
import React from 'react';
import { connect } from 'react-redux';
import { Container, Content, PagamentoAprovado } from './styles';
import HeaderDate from '../../components/HeaderDate';
import Footer from '../../components/Footer';
import paymentImg from '../../assets/payment-img.svg';

function PagamentoProcessado({ user }) {


    return <Container>
        <HeaderDate />
        <Content>
            <PagamentoAprovado>
                <img src={paymentImg} alt="payment-card" />
                <h2>Obrigado, seu pedido foi realizado e está sendo processado!</h2>
                <p>Enviaremos um e-mail com as intruções para acompanhar o status do seu pagamento.</p>
                {
                    user.signed ?
                        (<>
                            <a href="/">Voltar a página inicial</a>
                        </>)
                        :
                        (<>
                            <a href="http://www.takipay.com.br">Voltar a página inicial</a>
                        </>)
                }


            </PagamentoAprovado>
        </Content >
        <Footer />
    </Container>;
}

export default connect((state) => ({
    user: state.user
}))(PagamentoProcessado);
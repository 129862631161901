import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #EEE;
  height: 100vh;

  @media(max-width: 414px){
    justify-content: flex-start;  
    /* min-height: 100%; */
  }
`;


export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


export const PagamentoAprovado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 700px;
  margin-bottom: 40px;
  margin-top: 60px;
  text-align: center;

  img{
    height: 130px;
  }
  span{
    color: #441188;
    background-color: #F0FF0F;
  }

  h2{
    font-weight: bold;
    text-transform: uppercase;
    color: #441188;
    /* font-size: 40px; */
  }
  p{
    font-size: 22px;
  }

  @media (max-width: 414px){
    width: 300px;
    img {
    height: 80px;
   }
   h3{
    font-size: 30px;
   }
    p{
    font-size: 16px;
    }
  }
`;
// const INITIAL_STATE = {
//   items: [],
// };

export default function cart(state = {}, action) {

  switch (action.type) {

    case '@cart/UPDATE_VALUES':
      // console.log(state, action)
      return {
        ...state,
        simulacao: action.simulacao
      };
    default:
      return state;
  }

}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Logo, PerfilArea, ProfileName, Cart, RightSide } from './styles';
import { FaUserCircle, FaShoppingCart, FaPowerOff } from 'react-icons/fa'
import { validateRole } from '../../helpers/roles';
import logo from '../../assets/taki-white.png';
const company = localStorage.getItem('company')

function Header({ history, user }) {

  const [nome, setNome] = useState('');

  useEffect(() => {
    if (user.dados) {
      const nomeSplit = user.dados.fullName.split(' ');

      setNome(nomeSplit[0]);
    }

    // eslint-disable-next-line
  }, [user]);


  return (
    <Container>
      <Helmet
        script={[{
          type: 'text/javascript',
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KMZPSV9');`
        }]} />
      {
        user.dados ?
          (
            <>
              <Link to="/"><Logo src={logo} alt="logo-taki" /></Link>
              <RightSide>
                {
                  !validateRole('noPersonalData') ?
                  (<><Link to='/perfil'>
                    <PerfilArea>
                    <FaUserCircle color="#fff" size={32} />
                      <div>
                        <ProfileName>{nome}</ProfileName>
                        <span>Meu Perfil</span>
                      </div>

                    </PerfilArea>
                  </Link>
                  <Link to="/pagamento">
                    <Cart>
                      <FaShoppingCart color="#fff" size={32} />
                    </Cart>
                  </Link></>)
                : (<>
                    <PerfilArea onClick={() => {
                          localStorage.clear();
                          window.location.href = company === "takipay" ? '/' : ('/' + company);
                        }}>
                      <FaPowerOff color="#fff" size={32} />
                      <div>
                        <ProfileName>{nome}</ProfileName>
                        <span>Sair</span>
                      </div>
                    </PerfilArea>
                </>)}
              </RightSide>
            </>
          )
          :
          (<></>)

      }

    </Container>
  );
}


export default connect(state => ({
  user: state.user,

}))(Header);

import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';


export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #eee;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 360px) {
    width: 270px;
    img {
      height: 70px;
    }
  }
`;

export const Logo = styled.img`
  margin-bottom: 15px;
  height: 95px;
`;

export const AreaSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  a {
    margin: 20px 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

`;

export const Input = styled.input`
  margin: 10px;
`;

export const DivSociaLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonSocialGoogle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
  width: 47%;
  margin: 10px 0;

  svg{
    margin-right: 5px;
  }

`;

export const ButtonSocialFb = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a538a;
  color: #fff;
  border-radius: 4px;

  width: 47%;
  margin: 10px 0;

  svg{
    margin-right: 5px;
  }

`;


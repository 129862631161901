import React, { useState } from 'react';
import {
  FaAddressCard,
} from 'react-icons/fa';
import { isValid } from 'cpf';
import * as EmailValidator from 'email-validator';
import Swal from 'sweetalert2'

import {
  DataForm, Title, TwoInputs, InputLabel, TwoButtons,
  SelectButton, Button, PreviousButton
} from './styles';


import { birthMask, cpfMask, cnpjMask, phoneMask } from '../../helpers/masks';

function FormPayer(props) {

  const [newPayer, setNewPayer] = useState(false);

  // dados payer
  const [cpfOrCpnj, setCpfOrCpnj] = useState(props.payerData.cpfOrCpnj ? props.payerData.cpfOrCpnj : 'CPF');
  const [cpf, setCpf] = useState(props.payerData.cpf ? props.payerData.cpf : '');
  const [cnpj, setCnpj] = useState(props.payerData.cnpj ? props.payerData.cnpj : '');
  const [nome, setNome] = useState(props.payerData.nome ? props.payerData.nome : '');
  const [sobrenome, setSobrenome] = useState(props.payerData.sobrenome ? props.payerData.sobrenome : '');
  const [dataNascimento, setDataNascimento] = useState(props.payerData.dataNascimento ? props.payerData.dataNascimento : '');
  const [telefone, setTelefone] = useState(props.payerData.telefone ? phoneMask(props.payerData.telefone) : '');
  const [email, setEmail] = useState(props.payerData.email ? props.payerData.email : '');
  const [confirmEmail, setConfirmEmail] = useState(props.payerData.confirmEmail ? props.payerData.confirmEmail : '');

  function submit(e) {
    e.preventDefault();

    if (newPayer && !isValid(cpf)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'CPF inválido!',
      })
      // alert('CPF inválido')
      return
    }

    if (newPayer &&  telefone && telefone.length < 14) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Telefone incompleto!',
      })
      // alert('Telefone incompleto')
      return;
    }

    if (newPayer && !EmailValidator.validate(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'E-mail inválido!',
      })
      // alert('E-mail inválido')
      return;
    }

    if (newPayer &&  email !== confirmEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Os e-mails não coincidem!',
      })
      // alert('Os e-mails não coincidem')
      return;
    }

    props.callbackPayer(newPayer ? {
      cpfOrCpnj,
      cpf: cpf.replace('.', '').replace('.', '').replace('-', ''),
      cnpj: cnpj.replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', ''),
      nome,
      sobrenome,
      dataNascimento: dataNascimento.replace('/', '-').replace('/', '-'),
      telefone: telefone.replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', ''),
      email,
      confirmEmail
    }
      :
      props.userForm)
    props.callbackNextStep();
  }

  return <DataForm onSubmit={submit}>
    <Title>
      <h4><FaAddressCard /> Dados do titular do cartão</h4>
    </Title>
    <span>Selecione uma opção</span>
    <SelectButton type="button" ative={newPayer ? false : true} onClick={() => setNewPayer(false)}>
      <span>Eu sou o titular do cartão</span>
    </SelectButton>
    <SelectButton type="button" ative={newPayer ? true : false} onClick={() => setNewPayer(true)}>
      <span>O cartão é de outra pessoa</span>
    </SelectButton>
    {
      !newPayer ?
        (<>
        </>)
        :
        (<>
          <TwoInputs>
            <InputLabel>
              <label>Nome*</label>
              <input
                required
                maxLength="30"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              ></input>
            </InputLabel>
            <InputLabel>
              <label>Sobrenome*</label>
              <input
                required
                maxLength="30"
                value={sobrenome}
                onChange={(e) => setSobrenome(e.target.value)}
              ></input>
            </InputLabel>
          </TwoInputs>
          <InputLabel>
            <label>Data de Nascimento*</label>
            <input
              type="tel"
              required
              value={birthMask(dataNascimento)}
              onChange={(e) => setDataNascimento(birthMask(e.target.value))}
            />
          </InputLabel>
          <InputLabel>
            <label>E-mail*</label>
            <input
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </InputLabel>
          <InputLabel>
            <label>Confirme o E-mail*</label>
            <input
              required
              type="email"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            ></input>
          </InputLabel>
          <InputLabel>
            <label>Telefone do Titular*</label>
            <input
              type="tel"
              required
              minLength={15}
              maxLength={15}
              value={phoneMask(telefone)}
              onChange={(e) => setTelefone(e.target.value)}
              placeholder="Ex: (11) 0000-0000"
            ></input>
          </InputLabel>
          <TwoInputs>
            <InputLabel>
              <label>Documento</label>
              <select
                value={cpfOrCpnj}
                onChange={(e) => setCpfOrCpnj(e.target.value)}
              >
                <option defaultValue="CPF">CPF</option>
                <option defaultValue="CNPJ">CNPJ</option>
              </select>
            </InputLabel>
            {cpfOrCpnj === 'CPF' ? (
              <InputLabel>
                <label>CPF*</label>
                <input
                  type="tel"
                  required
                  value={cpfMask(cpf)}
                  onChange={(e) => setCpf(e.target.value)}
                ></input>
              </InputLabel>
            ) : (
                <InputLabel>
                  <label>CNPJ*</label>
                  <input
                    type="tel"
                    required
                    value={cnpjMask(cnpj)}
                    onChange={(e) => setCnpj(e.target.value)}
                  ></input>
                </InputLabel>
              )}
          </TwoInputs>
        </>)
    }

    <TwoButtons>
      <Button type="submit">Próximo</Button>
      <PreviousButton type="button" onClick={props.callbackPreviousStep}>Voltar</PreviousButton>
    </TwoButtons>
  </DataForm>;
}

export default FormPayer;

import styled from 'styled-components';
import { lighten } from 'polished';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TableOverFlow = styled.div`
  width: 100%;
  table{
      text-align: center;
    }
    td{
      vertical-align: middle;
      word-break: break-all;
    }
  /* @media(max-width: 414px){

    table td {
      vertical-align: middle;
      word-break: break-all;
    }
  } */

`;

export const Content = styled.div`
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 45%;

  @media(max-width: 414px){
    width: 70%;
  }
`;

export const FormBarCode = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px;

  input{
    width: 65%;
  }
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
`;

export const FormData = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;

`;

export const LabelDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    label{
      width: 100px;
    }
    @media (max-width: 414px) {
      label, input{
        width: 100% !important;
      }

      flex-direction: column;
    }
    input{
      width: 450px;
      margin: 10px 0;
    }

`;

export const RemoveButton = styled.div`
  background-color: transparent;
  width: auto;
  height: auto;
  cursor: pointer;

  &:hover{
    svg{
      color: ${lighten(0.08, '#ee0066')};
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
  width: 120px;
`;

export const TwoButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoletoDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const DatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
    label{
      width: 100px;
    }

  input{
      width: 450px;
      margin: 10px 0;
    }

  @media (max-width: 414px) {
      label, input{
        width: 100% !important;
      }

      flex-direction: column;
    }
`;

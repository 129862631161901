import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* height: 100vh; */

  @media (max-width: 414px){
    justify-content: center;
    height: auto;
  }

`;

export const Content = styled.div`
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 500px;

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    a{
      text-decoration: none !important;
    }

  }

  @media (max-width: 414px){
    width: 280px;
    margin: 50px 0;
    h1{
      font-size: 30px;
    }
  }



  /* @media (max-width: 1500px) {
    margin-top: 100px !important;
  } */

`;


export const ProdutoItem = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap !important;
    background-color: #fff;
    height: 200px;
    width: 200px !important;
    border-radius: 4px;
    border: 2px solid #441188;
    margin: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover{
      border: 2px solid #EE0066;
      span{
        color: #EE0066;
      }
    }
    span{
      font-weight: bold;
    }

    img{
      height: 150px;
      width: 150px;
    }

    @media (max-width: 414px){
      height: 130px;
      width: 130px !important;

      img{
        height: 80px;
        width: 80px;
      }
  }


`;

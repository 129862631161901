import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FaShoppingCart, FaPlus, FaMinus } from 'react-icons/fa';
import { Accordion } from 'react-bootstrap';
import {
    Container,
    Content,
    ColumnDebits,
    DataInfo,
    Debitos,
    TotalColumn,
    DebitoRow,
    Total,
    SeparatorDiv,
    AccordionDiv,
    AccordionButton,
    BorderDiv,
    SpanHonorario
} from './styles';
import HeaderDate from '../../components/HeaderDate';
import FormPagamento from '../../components/FormPagamento';
import Footer from '../../components/Footer';

import { formatPrice } from '../../helpers/format';
import { renderIdentificationDebits } from '../../helpers/labels/renders'

function Pagamento({ cart }) {

    // const dispatch = useDispatch()
    const { simulacao } = cart

    //totais
    const [parcelaSelecionada, setParcelaSelecionada] = useState(simulacao?.parcelaSelecionada);
    const [totalSelecionado, setTotalSelecionado] = useState(simulacao?.totalSelecionado);
    const [desconto, setDesconto] = useState(simulacao?.totalSelecionado - simulacao?.totalSelecionadoComDesconto)

    // accordion
    const [accordionActive, setAccordionActive] = useState(0);
    const [mobileState, setMobileState] = useState(0);

    useEffect(() => {
        if (window.screen.width <= 1024) {
            setMobileState(1);
            setAccordionActive(0);
        }
        else {
            setMobileState(0);
            setAccordionActive(1);
        }
    }, [])

    return <Container>
        <HeaderDate />
        <Content>
            <ColumnDebits>
                <DataInfo>
                    <FaShoppingCart size={20} color="#441188" />
                    <BorderDiv />
                    {
                        simulacao && simulacao.items ?
                            <Accordion as={AccordionDiv} defaultActiveKey={`${mobileState}`} >
                                <Accordion.Toggle as={AccordionButton} eventKey={`${mobileState}`} onClick={() => setAccordionActive(!accordionActive)}>
                                    <span>Débitos selecionados ({simulacao.items.length}) </span>
                                    {
                                        !accordionActive ?
                                            <FaPlus />
                                            :
                                            <FaMinus />

                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`${mobileState}`}>
                                    <Debitos>
                                        {
                                            simulacao && simulacao.items && simulacao.items.map(item => (
                                                <DebitoRow key={item.identification}>
                                                    <span><strong>Descrição: </strong>{renderIdentificationDebits(item.identification)}</span>
                                                    <span><strong>Valor: </strong>{formatPrice(item.originalAmount)}</span>
                                                </DebitoRow>
                                            ))
                                        }
                                        {/* {
                                            simulacao ?
                                                (<RemoverItens onClick={() => dispatch({
                                                    type: '@cart/UPDATE_VALUES'
                                                })}>Limpar carrinho</RemoverItens>)
                                                :
                                                (<></>)
                                        } */}
                                    </Debitos>
                                </Accordion.Collapse>
                            </Accordion>
                            :
                            (<></>)
                    }
                    <SeparatorDiv />
                    <Total>
                        <span>Total de Débitos:</span>
                        <span>{formatPrice(simulacao ? simulacao.originalAmount : "")}</span>
                    </Total>
                </DataInfo>

                <DataInfo>
                    {/* <FaCalculator size={20} color="#441188" /> */}
                    {/* <BorderDiv /> */}
                    <TotalColumn>
                        {
                            simulacao && simulacao.items && (simulacao.items[0].category.startsWith('vehicle-debits') || simulacao.items[0].category === 'boletos') ?
                            (<>
                                <div>
                                    <span>Honorários:</span>
                                    <SpanHonorario>Grátis</SpanHonorario>
                                </div>
                                <div>
                                    <span>Honorários Adicionais:</span>
                                    <SpanHonorario>Grátis</SpanHonorario>
                                </div>

                                <SeparatorDiv />
                            </>)
                            :
                            (<>

                            </>)
                        }


                        {
                            simulacao && simulacao.coupon && simulacao.coupon.discount ?
                                (<>
                                    <div>
                                        <span>Cupom:</span>
                                        {/* <span>{`${simulacao.coupon.service === 'fee' ? `${simulacao.coupon.discount}% sobre as taxas` : `${formatPrice(simulacao.coupon.discount)}`}`} */}
                                        <span>{simulacao.coupon.name}</span>
                                    </div>
                                    <div>
                                        <span>Desconto:</span>
                                        <span>{formatPrice(desconto)}</span>
                                    </div>
                                </>)
                                :
                                (<>
                                    <div>
                                        <span>Cupom:</span>
                                        <span>-</span>
                                    </div>
                                    <div>
                                        <span>Desconto:</span>
                                        <span>R$ 0,00</span>
                                    </div>
                                </>)
                        }
                        <SeparatorDiv />
                        {
                            simulacao && simulacao.items && (simulacao.items[0].category.startsWith('vehicle-debits') || simulacao.items[0].category === 'boletos') ? 
                            (simulacao && simulacao.totalSelecionado && simulacao.originalAmount ? 
                            (
                                <>
                                    <div>
                                        <span>Encargos e Taxas Operação:</span>
                                        <span>{formatPrice(simulacao.totalSelecionado - simulacao.originalAmount)}</span>
                                    </div>
                                    <SeparatorDiv />
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        <span>Encargos e Taxas Operação:</span>
                                        <span>-</span>
                                    </div>
                                    <SeparatorDiv />
                                </>
                            ))
                            : 
                            (<>

                            </>)
                        }
                       
                            {
                                simulacao && parcelaSelecionada ?
                                    (
                                        <>
                                            <Total>
                                                <span>Parcelas:</span>
                                                <span>
                                                    {`${parcelaSelecionada}x de ${formatPrice(
                                                        simulacao.parcelas[parcelaSelecionada - 1] / parcelaSelecionada
                                                    )}`}
                                                </span>
                                            </Total>
                                            <Total>
                                                <span>Total:</span>
                                                <span>
                                                    {formatPrice(totalSelecionado)}
                                                </span>
                                            </Total>

                                        </>
                                    )
                                    :
                                    (
                                        <>
                                        <div>
                                        <span>Total:</span>
                                            <span>-</span>
                                        </div>
                                            
                                        </>
                                    )
                            }

                    </TotalColumn>
                </DataInfo>
            </ColumnDebits>

            <FormPagamento callbackTotal={parcela => {
                setParcelaSelecionada(parcela)
                setTotalSelecionado(simulacao.parcelas[parcela - 1])
                setDesconto(simulacao.coupon.discount ? Math.round(simulacao.parcelasIniciais[parcela - 1] - simulacao.parcelas[parcela - 1]) : 0)
            }} />
        </Content>

        <Footer />
    </Container>
}

export default connect((state) => ({
    cart: state.cart,
    user: state.user,
    paymentStatus: state.pagamento
}))(Pagamento);
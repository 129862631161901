import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Content = styled.div`
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;

    /* margin-bottom: 80px; */
    nav{
        width: 100%;
      }

`;

export const TableDiv = styled.div`
    border: 1px solid #eee;
    border-radius: 4px;
    height: 400px !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    table{
        margin: 0;
        width: 100%;
        text-align: center;
        td, th{
            vertical-align: middle;
        }
        tr{
            text-align: center !important;
            /* word-break: break-all; */
        }
  }
    
`;

export const FiltrosDiv = styled.div`
    display: flex;


    select, input{
        height: 40px;
        border-radius: 4px;
        width: 200px;
        align-self: flex-start;
        margin: 10px 5px;
    }

    button{
        height: 40px;
        width: auto;
        margin: 10px 5px;
    }

    @media(max-width: 414px){
        flex-direction: column;
    }


`;


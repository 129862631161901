import styled from 'styled-components';

export const Container = styled.div`
  form{
    margin-top: 20px;
    margin-bottom: 100px;
    width: 350px;
  }
  button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    input{
      margin: 5px 0;
    }
`;

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { Container } from './styles';
import apiPortal from '../../services/api-portal';

function CancelarPagamentoPOS() {
  const token = localStorage.getItem('token');
  const [nsu, setNsu] = useState('');
  const [serial, setSerial] = useState('');
  const [loading, setLoading] = useState(0)


  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(1);
    const res = await apiPortal.post(`/cancelarpagamento`, {
      serial_number: serial,
      nsu_client: nsu
    }, { headers: { Authorization: `Bearer ${token}` } }).catch(e => Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Erro ao buscar dados!',
    }));


    if (res && res.data) {
      Swal.fire({
        icon: 'success',
        title: res.data.token,
        text: 'Token de cliente gerado com sucesso!',
      })
    }

    setLoading(0);
    setNsu('');
    setSerial('');
  }

  return <Container>
    <form onSubmit={handleSubmit}>
      <input required placeholder="Número do NSU do cliente" value={nsu} onChange={(e) => setNsu(e.target.value)} />
      <input required value={serial} placeholder="Número do serial da máquina" onChange={(e) => setSerial(e.target.value)} />
      {
        loading ?
          (<button disabled><Spinner animation="border" variant="light" size="sm" /></button>)
          :
          (<button type="submit">Gerar token</button>)
      }

    </form>

  </Container>;
}

export default CancelarPagamentoPOS;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 100vh; */

`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 400px;
  width: 400px;
  margin: 20px 0;
  h1{
      margin: 25px 0;
  }
  span{
    font-size: 18px;
  }
  a{
    margin-top: 10px;
    text-decoration: underline;
  }

  @media (max-width: 380px) {
    width: 270px;
    span{
      text-align: center !important;
      width: 250px;
      }
      h1{
        text-align: center !important;
      }

    }



  form{
    width: 100%;
    display: flex;
    flex-direction: column;

    input, button{
      margin: 10px 0;
    }


  }`;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';

import {
  Simulacao,
  SimulacaoHeader, Parcelas, ParcelasMobile,
  ParcelaItem, PagarButton, LinkMaisOpcoes,
  FooterSimulacao, CupomDiv, LabelDesconto,
  TextTaxas, SeparatorDiv
} from './styles';
import { formatPrice } from '../../helpers/format';

// imgs
import card from '../../assets/cardAsset_1.svg';
import melhorOpcao from '../../assets/melhor-opcao2.png';
import apiCoupon from '../../services/api-coupon';

function ParcelasComponent(props) {

  const [mobileState, setMobileState] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);

  // parcelas
  const [parcelChecked, setParcelChecked] = useState([false, false, false, false, false, false, false, false, false, false, false, false, true]);
  const { parcelas } = props;
  props.callbackParcelas(parcelChecked.findIndex(parcel => parcel === true));

  // #Cupom
  const [codigoCupom, setCodigoCupom] = useState('');
  const [coupon, setCoupon] = useState(Object);
  const [loadingCoupon, setLoadingCoupon] = useState(false);

  async function findCoupon(e) {
    e.preventDefault();
    setLoadingCoupon(1);
    const res = await apiCoupon.get(`coupon/${codigoCupom}`).catch((e) => {
      toast.error('Cupom não encontrado ou inválido');
      setCoupon({});
      props.callbackRecalculate();

      setLoadingCoupon(0);
    });
    if (res && res.data) {
      setCoupon(res.data);
      props.callbackRecalculate(res.data);
      setLoadingCoupon(0);
    }
  }

  return (<>
    <Simulacao>
      <SimulacaoHeader>
        <h4>Forma de Pagamento</h4>
        <div>
          <img src={card} alt="cartao" />
          <span>Cartão de Crédito</span>
        </div>
      </SimulacaoHeader >
      <SeparatorDiv />
      {
        !mobileState
          ? (
            <Parcelas>
              {
                parcelas.map((parcela, index) => {
                  const i = 12 - index;

                  return (
                    <ParcelaItem
                      alter={i % 2}
                      ative={parcelChecked[i]}
                      key={i}
                      onClick={() => {
                        const array = [];
                        array[i] = true;
                        setParcelChecked(array);
                      }}
                    >
                      {i === 12 ? (
                        <span>
                          {`${i}x de ${formatPrice(parcelas[i - 1] / i)}`}
                          <img alt="melhor opcao" src={melhorOpcao} />
                        </span>
                      ) : (
                          <span>{`${i}x de ${formatPrice(parcelas[i - 1] / i)}`}</span>
                        )}
                    </ParcelaItem>
                  );
                })
              }
            </Parcelas >
          )
          : (<ParcelasMobile>
            <ParcelaItem
              alter={0}
              ative={parcelChecked[12]}
              key={12}
              onClick={() => {
                const array = [];
                array[12] = true;
                setParcelChecked(array);
              }}
            >
              <span>{`${12}x de ${formatPrice(parcelas[11] / 12)}`}<img alt="melhor opcao" src={melhorOpcao} /></span>
            </ParcelaItem>

            <ParcelaItem
              alter={0}
              ative={parcelChecked[10]}
              key={10}
              onClick={() => {
                const array = [];
                array[10] = true;
                setParcelChecked(array);
              }}
            >
              <span>{`${10}x de ${formatPrice(parcelas[9] / 10)}`}</span>
            </ParcelaItem>

            <ParcelaItem
              alter={0}
              ative={parcelChecked[6]}
              key={6}
              onClick={() => {
                const array = [];
                array[6] = true;
                setParcelChecked(array);
              }}
            >
              <span>{`${6}x de ${formatPrice(parcelas[5] / 6)}`}</span>
            </ParcelaItem>
            <LinkMaisOpcoes onClick={() => setMobileState(false)}>Ver todas opções</LinkMaisOpcoes>
          </ParcelasMobile>)
      }
      <SeparatorDiv />
      <FooterSimulacao>
        <CupomDiv onSubmit={findCoupon}>
          <label>Cupom de desconto</label>
          <div>
            <input type="text" required value={codigoCupom} onChange={e => setCodigoCupom(e.target.value)} />

            <button type="submit">{loadingCoupon ?
              (<Spinner animation="border" variant="light" size="sm" />)
              :
              <>Aplicar</>
            }</button>
          </div>
        </CupomDiv>

        {
          loadingPayment ?
            <PagarButton disabled>
              <Spinner animation="border" variant="light" size="sm" />
            </PagarButton>
            :
            <PagarButton type="button"
              onClick={async () => {
                setLoadingPayment(1)
                await props.callbackHandleSubmit();
              }
              }
            >PROSSEGUIR PARA PAGAMENTO</PagarButton>

        }
      </FooterSimulacao>
      {
        !coupon.discount ?
          (<></>)
          :
          (<LabelDesconto>Cupom: {coupon.name} - Desconto: {coupon.service === 'fee' ? <>{coupon.discount}% sobre as taxas</> : <>{formatPrice(coupon.discount)}</>}</LabelDesconto>)
      }
      {props.taxesDescription.map(description => (
        <div key={description}>
          <TextTaxas>{description}</TextTaxas>
        </div>
      ))}


    </Simulacao>
  </>);
}

export default ParcelasComponent;

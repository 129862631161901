import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import Pagamento from '../pages/Pagamento';
import PagamentoProcessado from '../pages/PagamentoProcessado';
import Simulacoes from '../pages/Simulacoes/SimulacoesRenavam';
import SimulacoesBoleto from '../pages/Simulacoes/SimulacoesBoleto';
import SimulacoesValor from '../pages/Simulacoes/SimulacoesValor';
import Cadastro from '../pages/Cadastro';
import SolicitaRedefinirSenha from '../pages/SolicitaRedefinirSenha';
import PrimeiroAcesso from '../pages/PrimeiroAcesso';
import RedefinirSenha from '../pages/RedefinirSenha';
import LinkGerado from '../pages/LinkGerado';
import NotFound from '../pages/NotFound';
import Comprovante from '../pages/Comprovante';
import Dashboard from '../pages/Dashboard';
import DebitosVeiculares from '../pages/Categorias/DebitosVeiculares';
import Boletos from '../pages/Categorias/Boletos';
import LinkPorValor from '../pages/Categorias/LinkPorValor';
import Portal from '../pages/Categorias/Portal/Admin';
import LinksPagamento from '../pages/Categorias/Portal/LinksPagamento';
import Transacoes from '../pages/Categorias/Portal/Transacoes';
import Recorrencia from '../pages/Categorias/Portal/Recorrencia';
import DetalheTransacao from '../pages/Categorias/Portal/DetalheTransacao';
import Cidadetran from '../pages/Categorias/Portal/Cidadetran';
import Bloqueados from '../pages/Categorias/Portal/Bloqueados';
import DetalheCidadetran from '../pages/Categorias/Portal/DetalheCidadetran';
import Pos from '../pages/Categorias/Portal/POS';
import Perfil from '../pages/Perfil';

export default function routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Rotas abertas */}
        <Route path="/escoladeautomacao" exact component={Login} />
        <Route path="/paulacosta" exact component={Login} />
        <Route path="/ifly" exact component={Login} />
        <Route path="/meumentor" exact component={Login} />
        <Route path="/larissamoschetta" exact component={Login} />
        <Route path="/" exact component={Login} />
        <Route path="/cadastro" component={Cadastro} />
        <Route path="/redefinir-senha" exact component={SolicitaRedefinirSenha} />
        <Route path="/meu-primeiro-acesso/:id" component={PrimeiroAcesso} />
        <Route path="/redefinir-senha/:id" component={RedefinirSenha} />
        <Route path="/comprovante/:guid" component={Comprovante} />
        <Route path="/pagamento" exact component={Pagamento} />
        <Route path="/pagamento/status-obrigado" exact component={PagamentoProcessado} />

        {/* Simulações */}
        <Route path="/simulacoes/:userToken" component={Simulacoes} />
        <Route path="/simulacoes-por-valor/:userToken" component={SimulacoesValor} />
        <Route path="/boletos/:userToken" component={SimulacoesBoleto} />

        {/* Dashboard */}
        <Route path="/dashboard" component={Dashboard} isPrivate />

        {/* Categorias */}
        <Route path="/perfil" component={Perfil} isPrivate />
        <Route path="/debitos-veiculares" component={DebitosVeiculares} isPrivate />
        <Route path="/boletos" component={Boletos} isPrivate />

        {/* Admin */}
        <Route path="/portal" exact component={Portal} isPrivate />
        <Route path="/portal/links" component={LinksPagamento} isPrivate />
        <Route path="/portal/transacoes" exact component={Transacoes} isPrivate />
        <Route path="/portal/recorrencia" exact component={Recorrencia} isPrivate />
        <Route path="/portal/pos" exact component={Pos} isPrivate />
        <Route path="/portal/transacoes/:guid" component={DetalheTransacao} isPrivate />
        <Route path="/portal/cidadetran" exact component={Cidadetran} isPrivate />
        <Route path="/portal/cidadetran/bloqueados" exact component={Bloqueados} isPrivate />
        <Route path="/portal/cidadetran/:guid" exact component={DetalheCidadetran} isPrivate />
        <Route path="/simulacoes-por-valor" exact component={LinkPorValor} isPrivate />
        <Route path="/link/:category/:guid" component={LinkGerado} isPrivate />

        {/* Page 404 */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

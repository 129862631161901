import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { connect, useDispatch } from 'react-redux';
import LoginService from '../services/login';
import { validateRole } from '../helpers/roles';


function RouteWrapper({
  component: Component,
  isPrivate = false,
  path,
  ...rest
}) {
  const dispatch = useDispatch();
  let history = useHistory();


  const isAuth = () => {
    if (localStorage.getItem('token') == null) {
      localStorage.clear();
      return false;
    }
    if (localStorage.getItem('refresh_token') != null) {
      const refresh_token = localStorage.getItem('refresh_token');
      LoginService.post('/refresh-token', { refresh_token })
        .then((res) => {

          localStorage.setItem('token', res.data.data.access_token);
          localStorage.setItem('refresh_token', res.data.data.refresh_token);

        })
        .catch(async (err) => {
          if (err.response.data.message === 'token invalid') {
            await localStorage.clear();
            toast.error('Sua sessão expirou');
            history.push('/');
            return false;
          }

        });
      return true;
    }
  };


  const signed = isAuth();
  dispatch({
    type: '@user/USER_LOGGED',
    signed,
  });



  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }
  // if ((signed && path === '/') || (signed && path === '/cadastro') || (signed && path === '/redefinir')) {
  //   return <Redirect to="/dashboard" />;
  // }

  if(signed){
    const isAdmin = validateRole('usersTransactionsRead');
    if(path === '/' || path === '/cadastro' || path === '/redefinir'){
      return <Redirect to="/dashboard" />;
    }
    if(path && path.includes("portal") && !isAdmin){
      return <Redirect to="/dashboard" />;
    }

    if(path === "/simulacoes-por-valor" && !isAdmin){
      return <Redirect to="/dashboard" />;
    }
  }


  return <Route {...rest} component={Component} />;
}



export default connect()(RouteWrapper);

import React from 'react';
import { Label, LabelAproved, LabelDeclined, LabelNotAuthorized, LabelDefault, LabelPending } from './labelStyles';

export function renderLabelStatus(item) {

  if (!item) {
    return;
  }
  const status = item.status;
  const action = item.action;



  if (action === 'capture') {
    switch (status) {
      case 'approved': return (
        <>
          <LabelDefault>Captura</LabelDefault>
          <LabelAproved>Aprovado</LabelAproved>
        </>);
      default: return (
        <>
          <LabelDefault>Captura</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
    }
  }

  if (action === 'cancel') {
    switch (status) {
      case 'approved': return (
        <>
          <LabelDefault>Cancelamento</LabelDefault>
          <LabelDefault>Aprovado</LabelDefault>
        </>);
      default: return (
        <>
          <LabelDefault>Cancelamento</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
    }
  }

  if (action === 'reserve') {
    switch (status) {
      case 'approved': return (
        <>
          <LabelDefault>Reserva</LabelDefault>
          <LabelDefault>Aprovado</LabelDefault>
        </>);
      case 'declined': return (
        <>
          <LabelDefault>Reserva</LabelDefault>
          <LabelDeclined>Recusado</LabelDeclined>
        </>);
      default: return (
        <>
          <LabelDefault>Reserva</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);

    }
  }

  if (action === 'analyze' || action === 'webhook') {
    switch (status) {
      case 'approved': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelDefault>Aprovado</LabelDefault>
        </>);
      case 'pending': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelPending>Pendente</LabelPending>
        </>);
      case 'not_authorized': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelNotAuthorized>Não autorizado</LabelNotAuthorized>
        </>);
      case 'fraud': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelDeclined>Fraude</LabelDeclined>
        </>);
      case 'declined': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelDeclined>Recusado</LabelDeclined>
        </>);
      case 'canceled': return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelDeclined>Cancelado</LabelDeclined>
        </>);
      default: return (
        <>
          <LabelDefault>Análise</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);

    }
  }

  if (action === 'validate') {
    switch (status) {
      case 'error': return (
        <>
          <LabelDefault>Validação</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
      default: return (
        <>
          <LabelDefault>Validação</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
    }
  }

  if (action === 'subscription') {
    switch (status) {
      case 'approved': return (
        <>
          <LabelDefault>Assinatura</LabelDefault>
          <LabelAproved>Aprovado</LabelAproved>
        </>);
      case 'pending': return (
        <>
          <LabelDefault>Assinatura</LabelDefault>
          <LabelPending>Pendente</LabelPending>
        </>);
      case 'declined': return (
        <>
          <LabelDefault>Assinatura</LabelDefault>
          <LabelDeclined>Recusado</LabelDeclined>
        </>);
      case 'error': return (
        <>
          <LabelDefault>Assinatura</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
      default: return (
        <>
          <LabelDefault>Assinatura</LabelDefault>
          <LabelDeclined>Erro</LabelDeclined>
        </>);
    }
  }


}

export function renderLabelStatusV2(item) {
  switch (item) {
    case 'Reserved': return <Label>Reservado</Label>
    case 'Analysis Approved': return <Label>Análise Aprovada</Label>
    case 'Analysis Declined': return <Label>Análise Negada</Label>
    case 'Error Analyzing': return <Label>Erro ao Analisar</Label>
    case 'Canceled by Analysis': return <Label>Cancelado pela Análise</Label>
    case 'Fraud by Analysis': return <Label>Fraude pela Análise</Label>
    case 'Not Authorized by Analysis': return <Label>Não Autorizado pela Análise</Label>
    case 'Analysis Pending': return <Label>Analysis Pending</Label>

    case 'Bank Declined': return <Label color="#ff4d4d">BANCO</Label>
    case 'Error Reserving': return <Label color="#f78c00">ERRO RESERVA</Label>
    case 'Approved': return <Label color="#2eb82e">APROVADO</Label>
    case 'Approved Manually': return <Label color="#2eb82e">APROVADO MANUAL</Label>
    case 'Capture Declined': return <Label color="#bbb64e">NÃO CAPTURADO</Label>
    case 'Error Capturing': return <Label color="#bbb64e">ERRO CAPTURA</Label>
    case 'Canceled': return <Label color="#ff4d4d">ANTIFRAUDE</Label>
    case 'Canceled Manually': return <Label color="#ff4d4d">MANUAL</Label>
    case 'Cancel Declined': return <Label color="#bbb64e">NÃO CANCELADO</Label>
    case 'Analyzing': return <Label color="#1d55db">ANÁLISE</Label>
    case 'Validate Declined': return <Label color="#f78c00">ERRO VALIDAÇÃO</Label>
    case 'Error Validating Data': return <Label color="#f78c00">ERRO</Label>
    default: return ''
  }
}

export function renderStatusSum(item){
  switch (item) {
    case 'Bank Declined': return <Label size={10} color="#ff4d4d">BANCO</Label>
    case 'Error Reserving': return <Label size={10} color="#f78c00">ERRO RESERVA</Label>
    case 'Approved': return <Label size={10} color="#2eb82e">APROVADO</Label>
    case 'Approved Manually': return <Label size={10} color="#2eb82e">APROVADO MANUAL</Label>
    case 'Capture Declined': return <Label size={10} color="#bbb64e">NÃO CAPTURADO</Label>
    case 'Error Capturing': return <Label size={10} color="#bbb64e">ERRO CAPTURA</Label>
    case 'Canceled': return <Label size={10} color="#ff4d4d">ANTIFRAUDE</Label>
    case 'Canceled Manually': return <Label size={10} color="#ff4d4d">MANUAL</Label>
    case 'Cancel Declined': return <Label size={10} color="#bbb64e">NÃO CANCELADO</Label>
    case 'Analyzing': return <Label size={10} color="#1d55db">ANÁLISE</Label>
    case 'Validate Declined': return <Label size={10} color="#f78c00">ERRO VALIDAÇÃO</Label>
    case 'Error Validating Data': return <Label size={10} color="#f78c00">ERRO</Label>
    case 'total': return <Label size={10}>TOTAL</Label>
    default: return ''
  }
}

import React, { useState, useEffect } from 'react';
import { Spinner, Breadcrumb, Table, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaInfoCircle, FaUser, FaAddressCard, FaCreditCard, FaShoppingCart, FaClipboardList, FaFileAlt, FaEnvelope } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import {
    Container,
    Content,
    Card,
    CardItem,
    ItemCategory,
    Button,
    ButtonsDiv,
    TooltipLabel
} from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiTransacao from '../../../../services/transacao';
import { formatDateBarWithHours, formatPrice } from '../../../../helpers/format';
import { cpfMask, phoneMask, cepMask } from '../../../../helpers/masks';
import { renderCategory, renderGateway, renderActions, renderStatus, renderIdentificationDebits } from '../../../../helpers/labels/renders';
import { renderLabelStatusV2 } from '../../../../helpers/labels/statusTransaction';

function DetalheTransacao({ match }) {
    const token = localStorage.getItem('token');
    const [transaction, setTransaction] = useState(Object);
    const [loading, setLoading] = useState(0);


    useEffect(() => {
        async function loadData() {
            setLoading(1);
            const res = await apiTransacao.get(`/ecommerce/transaction?limit=1&page=1&guid=${match.params.guid}`, { headers: { Authorization: `Bearer ${token}` } })
                .catch(e => Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro ao buscar dados!',
                }));

            if (res && res.data && res.data.records) {
                setTransaction(res.data.records[0]);
            }
            setLoading(0);
        }
        loadData();
        //eslint-disable-next-line
    }, [])

    async function loadCRLV() {
        const renavam = transaction.items[0].data.renavam
        const year = transaction.items.find(e => e.data.type === 'crlv').identification.split("#").pop()

        Swal.fire({
            title: "Documento CRLV",
            html:
                'Confirma que o licencimento liberado no Detran é para o exercícicio abaixo?' +
                `</br></br><b><h3>${year}</h3></b>`,
            showCancelButton: true,
            confirmButtonText: `Gerar`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiTransacao.post(`/ecommerce/crlve`, {
                    renavam,
                    guid: match.params.guid
                }, { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                    Swal.showValidationMessage(
                        `Erro ao gerar o documento`
                    )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                const pdf = result.value.data.pdf
                let hiddenElement = document.createElement('a');
                hiddenElement.href = 'data:application/pdf;base64,' + encodeURI(pdf);
                hiddenElement.target = '_blank';
                hiddenElement.download = `crlv-${renavam}-.pdf`;
                hiddenElement.click();
            }
        })
    }

    async function updateStatus(action) {

        Swal.fire({
            title: "Atualização de status",
            html:
                `<label>Digite ${action.toUpperCase()} para confirmar</label>` +
                '<input id="swal-input1" class="swal2-input">' +
                '<label>Digite o motivo</label>' +
                '<textarea id="swal-input2"class="swal2-input">',
            showCancelButton: true,
            confirmButtonText: `Continuar`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {

                const reason = document.getElementById('swal-input2').value
                const status = action === 'capturar' ? 'capture' : 'cancel'

                const confirm = document.getElementById('swal-input1').value
                if (confirm.toUpperCase() !== action.toUpperCase()) {
                    return Swal.showValidationMessage(
                        `Confirmação incorreta`
                    )
                }
                if (!reason) {
                    return Swal.showValidationMessage(
                        `O motivo não pode estar vazio`
                    )
                }

                return await apiTransacao.post(`/ecommerce/transaction/action`, {
                    reason,
                    guid: transaction.guid,
                    status,
                }, { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                    Swal.showValidationMessage(
                        `Erro ao atualizar status`
                    )
                })

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Status Atualizado!',
                    'Recarregue a página',
                    'success'
                )
            }
        })
    }

    async function sendEmail() {
        Swal.fire({
            title: "E-mail",
            html: 'Deseja reenviar o e-mail com status da transação?',
            showCancelButton: true,
            confirmButtonText: `Enviar`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiTransacao.get(`/ecommerce/transaction/send-email/${match.params.guid}`,
                    { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                        Swal.showValidationMessage(
                            `Erro ao enviar e-mail`
                        )
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Email enviado com sucesso!',
                    '',
                    'success'
                )
            }
        })
    }

    return <Container>
        <HeaderLogged />
        <Content>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal/transacoes" exact>
                    <Breadcrumb.Item>Transações</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Detalhe</Breadcrumb.Item>
            </Breadcrumb>
            {
                !loading ?
                    (<>
                        {
                            transaction && transaction.guid ?
                                (
                                    <>
                                        <Card>
                                            <h3>Descrição <FaInfoCircle color="#666" /></h3>
                                            <CardItem>
                                                <div>
                                                    <h4><strong>Data: </strong>{formatDateBarWithHours((transaction.date))}</h4>
                                                    <h4><strong>Descrição: </strong>{transaction.description}</h4>
                                                    <h4><strong>Protocolo: </strong>{transaction.guid}</h4>

                                                </div>
                                                <div>
                                                    <h4><strong>Status</strong>
                                                        {
                                                            transaction && transaction.prominentStatus && renderLabelStatusV2(transaction.prominentStatus.description)
                                                        }
                                                    </h4>
                                                </div>
                                            </CardItem>
                                        </Card>
                                        <Card>
                                            <h3>Dados do usuário <FaUser color="#666" /></h3>
                                            <CardItem>
                                                <div>
                                                    <h4><strong>Nome: </strong>{transaction.user.name}</h4>
                                                    <h4><strong>Email: </strong>{transaction.user.email}</h4>
                                                    <h4><strong>CPF: </strong>{transaction.user.identification ? cpfMask(transaction.user.identification.number) : cpfMask(transaction.user.documents[0].identification)}</h4>
                                                    <h4><strong>Parceiro: </strong>{transaction.userToken && transaction.userToken.from ? transaction.userToken.from : "-"}</h4>
                                                </div>
                                                <div>
                                                    <h4><strong>Telefone: </strong>{transaction.user.phone ? phoneMask(transaction.user.phone.number) : phoneMask(transaction.user.phones[0].number)}</h4>
                                                    <h4><strong>IP: </strong>{transaction.user.ip}</h4>
                                                    <h4><strong>Criado em: </strong>{formatDateBarWithHours(transaction.user.createdAt)}</h4>
                                                </div>
                                            </CardItem>
                                        </Card>
                                        <Card>
                                            <h3>Dados do pagador <FaAddressCard color="#666" /></h3>
                                            <CardItem>
                                                <div>
                                                    <h4><strong>Nome: </strong>{transaction.payer.name}</h4>
                                                    <h4><strong>Email: </strong>{transaction.payer.email}</h4>
                                                    <h4><strong>Nascimento: </strong>{transaction.payer.birthdate}</h4>
                                                </div>
                                                <div>
                                                    <h4><strong>CEP: </strong>{cepMask(transaction.payer.address.zipCode)}</h4>
                                                    <h4><strong>Rua: </strong>{`${transaction.payer.address.street}, ${transaction.payer.address.number} - ${transaction.payer.address.neighborhood}`}</h4>
                                                    <h4><strong>Cidade: </strong>{`${transaction.payer.address.city}, ${transaction.payer.address.state}`}</h4>
                                                </div>
                                            </CardItem>
                                        </Card>
                                        <Card>
                                            <h3>Pagamento <FaCreditCard color="#666" /></h3>
                                            <CardItem>
                                                <div>

                                                    <h4><strong>Parcelas: </strong>{transaction.payment.installments}</h4>
                                                    <h4><strong>Débitos: </strong>{formatPrice(transaction.payment.originalAmount)}</h4>
                                                    <h4><strong>Total: </strong>{formatPrice(transaction.payment.grossAmount)}</h4>
                                                    <h4><strong>Total Pago: </strong>{formatPrice(transaction.payment.amount)}</h4>
                                                    <h4><strong>Desconto: </strong>
                                                        {
                                                            transaction.payment.discount ?
                                                                (<>
                                                                    {`${formatPrice(transaction.payment.discount)} - ${transaction.payment.coupon.name}`}
                                                                </>)
                                                                :
                                                                formatPrice(0)}</h4>
                                                    <h4><strong>Código de Autorização: </strong>
                                                        {
                                                            Array.isArray(transaction.status) &&
                                                                transaction.status.find(item => item.authorizationCode) ?
                                                                transaction.status.find(item => item.authorizationCode).authorizationCode
                                                                :
                                                                (<> - </>)
                                                        }
                                                    </h4>
                                                    <h4><strong>Comprovante: </strong>
                                                        {
                                                            Array.isArray(transaction.status) &&
                                                                transaction.status.filter(item => item.action === 'capture' && item.status === 'approved')[0] ?
                                                                <Link target="_blank" to={`/comprovante/${transaction.guid}`}><FaFileAlt size={16} color="#ee0066" /></Link>
                                                                :
                                                                (<>Não capturado</>)
                                                        }
                                                    </h4>
                                                </div>
                                                <div>
                                                    <h4><strong>Gateway: </strong>{renderGateway(transaction.gateway)}</h4>
                                                    <h4><strong>Antifraude: </strong>{typeof transaction.antifraud === 'string' ? transaction.antifraud : 'konduto'}</h4>
                                                    <h4><strong>Descrição: </strong>{transaction.payment.description}</h4>
                                                    <h4><strong>Tipo: </strong>{transaction.payment.type}</h4>
                                                    <h4><strong>Cartão: </strong>{transaction.card.number}</h4>
                                                    <h4><strong>Bandeira: </strong>{transaction.card.brand}</h4>
                                                    <h4><strong>Data exp: </strong>{transaction.card.expirationDate}</h4>
                                                </div>
                                            </CardItem>
                                        </Card>
                                        <Card>
                                            <h3>Itens <FaShoppingCart color="#666" /></h3>
                                            <ItemCategory>
                                                {transaction.items && transaction.items.map(item => (
                                                    <div key={item.identification}>
                                                        <h4><strong>Categoria: </strong>{renderCategory(item.category)}</h4>
                                                        <h4><strong>Descrição: </strong>{item.identification ? renderIdentificationDebits(item.identification) : item.description}</h4>
                                                        <h4><strong>Valor Original: </strong>{formatPrice(item.originalAmount)}</h4>
                                                        <h4><strong>Valor pago: </strong>{formatPrice(item.amount)}</h4>

                                                        {
                                                            item.data && item.data.vehicle ?
                                                                (<>
                                                                    <h4><strong>Proprietário: </strong>{item.data.vehicle.owner}</h4>
                                                                    <h4><strong>Placa: </strong>{item.data.vehicle.plate}</h4>
                                                                    <h4><strong>CPF: </strong>{item.data.vehicle.cpfcnpj}</h4>
                                                                </>)
                                                                :
                                                                (<></>)
                                                        }
                                                    </div>
                                                ))}
                                            </ItemCategory>
                                        </Card>
                                        <Card>
                                            <h3>Histórico de status<FaClipboardList color="#666" /></h3>

                                            <Table responsive="xl" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Status</th>
                                                        <th>Parceiro</th>
                                                        <th>Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Array.isArray(transaction.status) &&
                                                        transaction.status.map(item => (
                                                            <tr key={item.date}>
                                                                <td>{renderActions(item.action)}</td>
                                                                <td><TooltipLabel>
                                                                    {renderStatus(item.status)}
                                                                    {item.reason ?
                                                                        (<OverlayTrigger placement="top" overlay={(
                                                                            <Tooltip id="tooltip-cvv">
                                                                                Motivo: {item.reason} <br />
                                                                                    Por: {item.creator && item.creator.name}
                                                                            </Tooltip>)}><FaInfoCircle color="#666" /></OverlayTrigger>)
                                                                        :
                                                                        (<></>)
                                                                    }
                                                                </TooltipLabel>
                                                                </td>
                                                                <td>{renderGateway(item.partnerName)}</td>
                                                                <td>{formatDateBarWithHours(item.date)}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>

                                        </Card>


                                        <ButtonsDiv>
                                            <Button type="button" onClick={sendEmail}><FaEnvelope /></Button>
                                            {
                                                transaction.prominentStatus &&
                                                    (transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'approved') &&
                                                    transaction.items.find(item => item.data && item.data.type === 'crlv') ?
                                                    <Button type="button" onClick={loadCRLV}>CRLV</Button>
                                                    :
                                                    (<></>)
                                            }
                                            {
                                                transaction.prominentStatus &&
                                                    ((transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'error') ||
                                                        (transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'declined')) ?
                                                    <Button type="button" color="#2eb82e" onClick={() => updateStatus('capturar')}>APROVAR CAPTURA</Button>
                                                    :
                                                    (<Button color="#666" disabled>APROVAR</Button>)
                                            }
                                            {
                                                transaction.prominentStatus &&
                                                    ((transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'error') ||
                                                        (transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'declined') ||
                                                        (transaction.prominentStatus.action === 'capture' && transaction.prominentStatus.status === 'approved') ||
                                                        (transaction.prominentStatus.action === 'cancel' && transaction.prominentStatus.status === 'error') ||
                                                        (transaction.prominentStatus.action === 'cancel' && transaction.prominentStatus.status === 'declined') ||
                                                        (transaction.prominentStatus.action === 'analyze' && transaction.prominentStatus.status === 'pending')) ?
                                                    <Button type="button" color="#ff4d4d" onClick={() => updateStatus('cancelar')}>CANCELAR</Button>
                                                    :
                                                    (<Button color="#666" disabled>CANCELAR</Button>)
                                            }
                                        </ButtonsDiv>



                                    </>
                                )
                                :
                                (<h2>Transação não encontrada</h2>)
                        }
                    </>)
                    :
                    (<Spinner animation="border" variant="secondary" />)


            }
        </Content>
    </Container>;
}

export default DetalheTransacao;
/* eslint-disable no-sparse-arrays */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import HeaderLogged from '../../../components/HeaderLogged';
import apiUserToken from '../../../services/api-userToken';

import {
    Container,
    Content,
} from './styles';
import whitelabel from '../../../helpers/whitelabel';
const company = localStorage.getItem('company')

function Dashboard({ history, user }) {
    const [renavam, setRenavam] = useState('');
    const [plate, setPlate] = useState('');
    const [state, setState] = useState('sp');
    const [loading, setLoading] = useState(0);

    const seller = {
        companyId: whitelabel[company].companyId,
        merchantId: whitelabel[company].merchantId
    }

    async function submit(e) {
        e.preventDefault();
        setLoading(1);

        if (renavam.length < 9) {
            toast.error('Renavam inválido');
            setLoading(0);
            return;
        }

        const res = await apiUserToken.post('/user/token', {
            seller,
            user: {
                name: user.dados.fullName,
                email: user.dados.email,
                phones: [
                    {
                        type: user.dados.phones[0] ? user.dados.phones[0].type : '',
                        number: user.dados.phones[0] ? user.dados.phones[0].number : '',
                    },
                ],
                documents: [
                    {
                        type: 'CPF',
                        identification: user.dados.cpf,
                    },
                ],
            },
            transactionLink: false,
            from: 'TAKIPAY_MINHACONTA_DV',
            items: [
                {
                    category: `vehicle-debits-${state}`,
                    identification: renavam,
                    plate
                }
            ],
        }).catch((e) => {
            toast.error('Erro ao simular');
            setLoading(0);
        });

        if (res && res.data) {
            setLoading(0);
            history.push(`/simulacoes/${res.data.id}`);
        }

    }


    return (
        <Container>
            <HeaderLogged />
            <Content>
                <h1>Consulte seus débitos</h1>
                <form onSubmit={submit}>
                    <select required value={state} onChange={e => setState(e.target.value)}>

                        {/* <option value="ms">MS</option> */}
                        <option value="mg">MG</option>
                        <option value="sp">SP</option>
                        {/* 
                        <option value="">AC</option>
                        <option value="">AL</option>
                        <option value="">AP</option>
                        <option value="">AM</option>
                        <option value="">BA</option>
                        <option value="">CE</option>
                        <option value="">DF</option>
                        <option value="">ES</option>
                        <option value="">GO</option>
                        <option value="">MA</option>
                        <option value="mt">MT</option>
                        <option value="">MS</option>
                        <option value="mg">MG</option>
                        <option value="">PA</option>
                        <option value="">PB</option>
                        <option value="">PI</option>
                        <option value="">PR</option>
                        <option value="">PE</option>
                        <option value="">RJ</option>
                        <option value="">RN</option>
                        <option value="">RS</option>
                        <option value="">RO</option>
                        <option value="">RR</option>
                        <option value="">SC</option>
                        <option value="sp">SP</option>
                        <option value="">SE</option>
                        <option value="">TO</option> */}
                    </select>
                    <input required maxLength={11} type="tel" value={renavam} onChange={(e) => setRenavam(e.target.value)} placeholder="Digite seu renavam" />
                    <input required maxLength={7} value={plate} onChange={(e) => setPlate(e.target.value)} placeholder="Digite a placa" />
                    {
                        loading ?
                            <button disabled><Spinner animation="border" variant="light" size="sm" /></button>
                            :
                            <button type="submit">CONSULTAR</button>
                    }


                </form>

            </Content>
        </Container>
    );
}

export default connect(state => ({
    user: state.user,

}))(Dashboard);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 414px) {
    margin-bottom: 100px;
    form{
    width: 280px !important;
   }
  }


  form{
    display: flex;
    flex-direction: column;
    width: 350px;

    span{
      margin-top: 10px;
      cursor: pointer;
      align-self: center;
      text-decoration: underline;
    }

    div{
      display: flex;
      justify-content: space-between;
      input{
        width: 170px;
        @media (max-width: 414px) {
          width: 135px !important;
        }
      }
    }

    input, button{
      margin: 5px 0;
    }
  }

`;

export const EnderecoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  height: 80px;
  margin: 10px 0;
  cursor: pointer;
  text-align: center;

  &:hover{
    border: 2px solid #441188;
  }

  @media (max-width: 414px) {
     width: 280px !important;
  }
  `;

export const NovoEnderecoButton = styled.button`
  margin-top: 10px;

`;



export const RemoveEnderecoButton = styled.button`
      margin: 5px 0;
      border-radius: 4px;
      background-color: #FF4D4D !important;
      font-weight: bold;
      color: #fff;
`;

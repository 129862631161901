import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: solid 1px #c3c3c3;
  text-align: center;
  margin-top: 60px;
  padding: 20px 0px;
  /* height: 100px; */
  width: 90%;


  span{
    margin: 10px 0;
  }

  @media (max-width: 414px) {
    display: none;
  }
`;

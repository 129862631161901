import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  FaUser,
} from 'react-icons/fa';
import { isValid } from 'cpf';
import * as EmailValidator from 'email-validator';
import Swal from 'sweetalert2'


import { DataForm, Title, TwoInputs, InputLabel, TwoButtons, Button, PreviousButton } from './styles';


import { birthMask, cpfMask, cnpjMask, phoneMask } from '../../helpers/masks';

function FormUser(props) {

  // dados usuário
  const [cpfOrCpnj, setCpfOrCpnj] = useState(props.userData.cpfOrCpnj ? props.userData.cpfOrCpnj : 'CPF');
  const [cpf, setCpf] = useState(props.userData.cpf ? props.userData.cpf : '');
  const [cnpj, setCnpj] = useState(props.userData.cnpj ? props.userData.cnpj : '');
  const [nome, setNome] = useState(props.userData.nome ? props.userData.nome : '');
  const [sobrenome, setSobrenome] = useState(props.userData.sobrenome ? props.userData.sobrenome : '');
  const [dataNascimento, setDataNascimento] = useState(props.userData.dataNascimento ? props.userData.dataNascimento : '');
  const [telefone, setTelefone] = useState(props.userData.telefone ? phoneMask(props.userData.telefone) : '');
  const [email, setEmail] = useState(props.userData.email ? props.userData.email : '');
  const [confirmEmail, setConfirmEmail] = useState(props.userData.confirmEmail ? props.userData.confirmEmail : '');

  useEffect(() => {
    const { userSigned } = props;
    if (!props.userData.nome && props.userSigned.cpf) {
      setCpf(userSigned.cpf)
      setNome(userSigned.fullName.split(' ')[0])
      setSobrenome(userSigned.fullName.split(' ')[1])
      setTelefone(phoneMask(userSigned.phones[0].number))
      setEmail(userSigned.email)
      setConfirmEmail(userSigned.email)
    }
    else if(!props.userData.nome && !props.userSigned.cpf){
      setEmail(props.cart.simulacao.userTokenData.email ? props.cart.simulacao.userTokenData.email : '')
      setCpf(props.cart.simulacao.userTokenData.documents  ? props.cart.simulacao.userTokenData.documents[0].identification : '')
      setTelefone(props.cart.simulacao.userTokenData.phones  ? phoneMask(props.cart.simulacao.userTokenData.phones[0].number) : '')
    }

    //eslint-disable-next-line
  }, [])

  function submit(e) {
    e.preventDefault();

    if (!isValid(cpf)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'CPF inválido!',
      })
      // alert('CPF inválido')
      return
    }

    if (telefone && telefone.length < 14) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Telefone incompleto!',
      })
      // alert('Telefone incompleto')
      return;
    }

    if (!EmailValidator.validate(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'E-mail inválido!',
      })
      // alert('E-mail inválido')
      return;
    }

    if (email !== confirmEmail) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Os e-mails não coincidem!',
      })
      // alert('Os e-mails não coincidem')
      return;
    }

    props.callbackUser({
      cpfOrCpnj,
      cpf: cpf.replace('.', '').replace('.', '').replace('-', ''),
      cnpj: cnpj
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', ''),
      nome,
      sobrenome,
      dataNascimento: dataNascimento.replace('/', '-').replace('/', '-'),
      telefone: telefone.replace('(', '')
        .replace(')', '')
        .replace('-', '')
        .replace(' ', ''),
      email,
      confirmEmail
    })
    props.callbackNextStep();
  }

  return <DataForm onSubmit={submit}>
    <Title>
      <h4><FaUser /> Dados pessoais</h4>
    </Title>
    <TwoInputs>
      <InputLabel>
        <label>Nome*</label>
        <input
          required
          maxLength="30"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        ></input>
      </InputLabel>
      <InputLabel>
        <label>Sobrenome*</label>
        <input
          required
          maxLength="30"
          value={sobrenome}
          onChange={(e) => setSobrenome(e.target.value)}
        ></input>
      </InputLabel>
    </TwoInputs>
    <InputLabel>
      <label>Data de Nascimento*</label>
      <input
        type="tel"
        required
        value={birthMask(dataNascimento)}
        onChange={(e) => setDataNascimento(birthMask(e.target.value))}
      />
    </InputLabel>
    <InputLabel>
      <label>E-mail*</label>
      <input
        required
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      ></input>
    </InputLabel>
    <InputLabel>
      <label>Confirme o E-mail*</label>
      <input
        required
        type="email"
        value={confirmEmail}
        onChange={(e) => setConfirmEmail(e.target.value)}
      ></input>
    </InputLabel>
    <InputLabel>
      <label>Seu melhor Telefone*</label>
      <input
        type="tel"
        required
        minLength={15}
        maxLength={15}
        value={phoneMask(telefone)}
        onChange={(e) => setTelefone(phoneMask(e.target.value))}
        placeholder="Ex: (11) 0000-0000"
      ></input>
    </InputLabel>
    <TwoInputs>
      <InputLabel>
        <label>Documento</label>
        <select
          value={cpfOrCpnj}
          onChange={(e) => setCpfOrCpnj(e.target.value)}
        >
          <option defaultValue="CPF">CPF</option>
          <option defaultValue="CNPJ">CNPJ</option>
        </select>
      </InputLabel>
      {cpfOrCpnj === 'CPF' ? (
        <InputLabel>
          <label>CPF*</label>
          <input
            type="tel"
            required
            value={cpfMask(cpf)}
            onChange={(e) => setCpf(e.target.value)}
          ></input>
        </InputLabel>
      ) : (
          <InputLabel>
            <label>CNPJ*</label>
            <input
              type="tel"
              required
              value={cnpjMask(cnpj)}
              onChange={(e) => setCnpj(e.target.value)}
            ></input>
          </InputLabel>
        )}
    </TwoInputs>
    <TwoButtons>
      <Button type="submit">Próximo</Button>
      <PreviousButton type="button" onClick={props.callbackPreviousStep}>Voltar</PreviousButton>
    </TwoButtons>
  </DataForm>;
}

// export default FormUser;

export default connect((state) => ({
  cart: state.cart,
}))(FormUser);

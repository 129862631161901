import React, { useState, useEffect } from 'react';
import {
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { Spinner } from 'react-bootstrap'
import api from 'axios';
import {
  DataForm, Title, TwoInputs, InputLabel, TwoButtons, PreviousButton,
  SelectButton, PagarButton
} from './styles';


import { cepMask } from '../../helpers/masks';

function FormPayer(props) {
  const user = props.user;
  const [loadingPayment, setLoadingPayment] = useState(props.loadingPayment);
  const [newAddress, setNewAddress] = useState(user && user.signed ? false : true);


  //endereço
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');

  //busca cep
  useEffect(() => {
    async function loadCEP() {
      const resp = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (resp && resp.data && resp.data.uf) {
        const { data } = resp;
        setCidade(data.localidade);
        setUf(data.uf);
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
      }
    }
    if (cep.length >= 9) {
      loadCEP();
    }
  }, [cep]);

  async function submit(e) {
    e.preventDefault();
    setLoadingPayment(1)


    await props.callbackPayment(newAddress ? {
      cep: cep.replace('-', ''),
      logradouro,
      numero,
      complemento,
      bairro,
      cidade,
      uf
    } :
      {
        cep: user.dados.addresses[0].zipCode,
        logradouro: user.dados.addresses[0].street,
        numero: user.dados.addresses[0].number,
        complemento: user.dados.addresses[0].complement,
        bairro: user.dados.addresses[0].neighborhood,
        cidade: user.dados.addresses[0].city,
        uf: user.dados.addresses[0].state
      })



    setLoadingPayment(0)
  }

  function renderAddress() {
    return (
      <>
        <InputLabel>
          <label>CEP*</label>
          <input
            type="tel"
            required
            value={cepMask(cep)}
            onChange={(e) => {
              setCep(e.target.value);
            }}
          ></input>
        </InputLabel>

        <TwoInputs>
          <InputLabel>
            <label>Logradouro*</label>
            <input
              required
              value={logradouro}
              onChange={(e) => setLogradouro(e.target.value)}
            ></input>
          </InputLabel>

          <InputLabel>
            <label>Número*</label>
            <input
              required
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            ></input>
          </InputLabel>
        </TwoInputs>
        <TwoInputs>
          <InputLabel>
            <label>Complemento</label>
            <input
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
            ></input>
          </InputLabel>

          <InputLabel>
            <label>Bairro*</label>
            <input
              required
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
            ></input>
          </InputLabel>
        </TwoInputs>

        <TwoInputs>
          <InputLabel>
            <label>Cidade*</label>
            <input
              required
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
            ></input>
          </InputLabel>
          <InputLabel>
            <label>Estado*</label>
            <select required value={uf} onChange={(e) => setUf(e.target.value)}>
              <option></option>
              <option>AC</option>
              <option>AL</option>
              <option>AP</option>
              <option>AM</option>
              <option>BA</option>
              <option>CE</option>
              <option>DF</option>
              <option>ES</option>
              <option>GO</option>
              <option>MA</option>
              <option>MT</option>
              <option>MS</option>
              <option>MG</option>
              <option>PA</option>
              <option>PB</option>
              <option>PI</option>
              <option>PR</option>
              <option>PE</option>
              <option>RJ</option>
              <option>RN</option>
              <option>RS</option>
              <option>RO</option>
              <option>RR</option>
              <option>SC</option>
              <option>SP</option>
              <option>SE</option>
              <option>TO</option>
            </select>
          </InputLabel>
        </TwoInputs>
      </>
    );
  }

  return <DataForm onSubmit={submit}>
    <Title>
      <h4><FaMapMarkerAlt /> Endereço da fatura do cartão</h4>
    </Title>

    {user && user.signed ? (
      <>
        <span>Selecione uma opção</span>
        {user.dados.addresses[0] ? (
          <SelectButton
            type="button"
            ative={newAddress ? false : true}
            onClick={() => {
              setNewAddress(false);
            }}
          >
            <FaMapMarkerAlt size={30} color="#441188" />
            <span>
              {`${user.dados.addresses[0].street}, ${user.dados.addresses[0].number} - ${user.dados.addresses[0].neighborhood}, ${user.dados.addresses[0].state}`}
            </span>
          </SelectButton>
        ) : (
            <></>
          )}

        <SelectButton
          type="button"
          ative={newAddress ? true : false}
          onClick={() => {
            setNewAddress(true);
          }}
        >
          <span>Cadastrar novo</span>
        </SelectButton>
        {newAddress ? <>
          {renderAddress()}
        </> : <></>}
      </>
    ) : (
        <>
          {renderAddress()}
        </>
      )}
    <TwoButtons>
      {
        loadingPayment ?
          <PagarButton disabled>
            <Spinner animation="border" variant="light" size="sm" />
          </PagarButton>
          :
          <PagarButton type="submit">PAGAR</PagarButton>
      }
      <PreviousButton type="button" onClick={props.callbackPreviousStep}>Voltar</PreviousButton>
    </TwoButtons>
  </DataForm>;
}

export default FormPayer;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1{
    margin-top: 50px;

  }

  @media (max-width: 414px) {

      h1{
        text-align: center !important;
        margin-top: 20px;
        font-size: 30px;
      }
    }

`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 500px;
  margin-top: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  @media (max-width: 414px) {
    width: 300px;
    }
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

`;

export const BarItem = styled.button.attrs((props) => ({
  checked: props.done,
}))`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #EEE;

    &:hover{
      background-color: #ee0066!important;
      svg{
        color: #FFF !important;
      }
    }


    ${(props) => props.done
    && css`
      background-color: #ee0066!important;
      svg{
        color: #FFF !important;
      }
    `}
`;


export const Input = styled.input`
  margin: 10px 0;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  width: 100%;
  padding: 10px 0px;
  border-radius: 4px;
  text-align: center;

`;

export const AreaCep = styled.div`
  display: flex;
  width: 100%;

  button{
    width: 35%;
    border-radius: 0px;
  }
  input{

  }
`;

export const NextButton = styled.button`
  margin: 10px 0;
`;

export const Etapa1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height: 200px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 100%;
`;


export const PersonType = styled.div`
  display: flex;
  margin: 10px 0px;

  svg {
    margin: 20px 30px;
  }
`;

export const PessoaFisica = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  span{
    margin-bottom: 10px;
  }
`;
export const PessoaJuridica = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  span{
    margin-bottom: 10px;
  }
`;

export const Finish = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0px;

  @media (max-width: 414px){
    width: 300px;
  }

  h1{
    margin-bottom: 30px;
    text-align: center;
  }

  span{
    color: #ee0066;
    font-weight: bold;

  }

  a{

    margin-top: 50px;
    font-size: 16px;
    text-decoration: underline;
  }
`;

import { decode } from 'jsonwebtoken';

export function validateRole(role) {
  // token
  const refresh = localStorage.getItem('refresh_token');
  if (!refresh) {
    return false;
  }
  const tokenDecoded = decode(refresh);
  if (tokenDecoded && tokenDecoded.realm_access) {
    const { realm_access } = tokenDecoded;
    return realm_access.roles.includes(role);
  }
  return false;
}

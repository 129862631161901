function cpfMask(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

function cnpjMask(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

function phoneMask(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+$/, '$1');
}

function cardMask(value) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 números e adiciona um espaço
    .replace(/(\d{4})(\d)/, '$1 $2') // captura 4 números e adiciona um espaço
    .replace(/(\d{4})(\d{1,2})/, '$1 $2') // captura 4 números e adiciona um espaço
    .replace(/(\d{4})\d+?$/, '$1'); // captura ultimos 4 numeros e não deixa ser digitado mais nada
}

function priceMask(numero) {
  numero = numero.replace('.', '').replace(',', '').replace(/\D/g, '');
  const texto = (Math.round(Math.round(numero)) / 100).toFixed(2).replace('.', ',');
  const stringComPontos = texto.replace(/(\d)(?=(?:[0-9]{3})+\b)/g, '$1.');
  return stringComPontos;
}

function numberMask(value) {
  return value
    .replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada
}

function birthMask(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 números e adiciona uma barra
    .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 números e adiciona uma barra
    .replace(/(\d{4})\d+?$/, '$1'); // captura ultimos 4 numeros e não deixa ser digitado mais nada
}

function cepMask(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2') // captura 5 números e adiciona um traço
    .replace(/(-\d{3})\d+?$/, '$1'); // captura ultimos 3 numeros e não deixa ser digitado mais nada
}


export {
  phoneMask, cpfMask, cnpjMask, numberMask, cardMask, birthMask, cepMask, priceMask,
};

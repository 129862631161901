import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #441188;
  width: 100%;
  padding: 10px 50px;

  a{
    text-decoration: none;
  }

  @media (max-width: 414px) {
    justify-content: center;
  }

`;

export const Logo = styled.img`
  width: 80px;
 `;

export const Cart = styled.div`
  &:hover{
        svg{
          color: #3EA1B5!important;
        }
      }
`;

export const PerfilArea = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px;
  svg{
    margin: 0 5px;
  }
  span{
    text-align: left;
    color: #E5E5E5;
    text-decoration: none;
    font-size: 12px;
  }
  div{
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 414px) {
    div {
      display: none;
    }
  }

    &:hover{
      cursor: pointer;
      svg{
        color: #3EA1B5!important;
      }
      span{
        color: #3EA1B5!important;
      }
    }
 `;

export const ProfileName = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 14px !important;
 `;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

 `;



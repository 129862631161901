import { combineReducers } from 'redux';

import cart from './Cart/reducer';
import user from './User/reducer';
import pagamento from './StatusPagamento/reducer';

export default combineReducers({
  cart,
  user,
  pagamento,
});

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { FaCar } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2'

import HeaderDate from '../../../components/HeaderDate';
import Parcelas from '../../../components/Parcelas';
import { formatPrice, formatDateBar } from '../../../helpers/format';

import {
    Container,
    Content,
    DataInfo,
    Debitos,
    DadosProprietario,
    DebitoItem,
    Fee,
    FeeCheckBox,
    Total,
    LoadingSimulacao,
    FeeRow,
    ErrorSimulacao,
    MensagemMultas,
    H1Falha,
    LinkTryAgain
} from './styles';

import Footer from '../../../components/Footer';
import api from '../../../services/simulacoes';

function Simulacoes(props) {

    const dispatch = useDispatch();
    const token = localStorage.getItem('token');

    const [guid, setGuid] = useState('');

    // utils state
    const [parcelas, setParcelas] = useState([]);
    const [parcelasIniciais, setParcelasIniciais] = useState([]);
    const [totalDebitos, setTotalDebitos] = useState(0);

    const [loading, setLoading] = useState(true);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [parcelaSelecionada, setParcelaSelecionada] = useState(12);
    const [valorParcelaSelecionada, setValorParcelaSelecionada] = useState(0);

    // error
    const [erroConsulta, setErroConsulta] = useState(false);
    const [messageError, setMessageError] = useState(false);

    // #Data hubspot
    const [userTokenData, setUserTokenData] = useState(Object);
    const [seller, setSeller] = useState(Object);

    // #Data Vehicle
    const [owner, setOwner] = useState('');
    const [vehicleRenavam, setvehicleRenavam] = useState('');
    const [plate, setPlate] = useState('');

    // #Data Licensing
    const [hasLicensing, setHasLicensing] = useState(false);
    const [checkedAllLicensing, setCheckedAllLicensing] = useState(true);
    const [licensing, setLicensing] = useState([]);

    // #Data IPVA
    const [hasIpva, setHasIpva] = useState(false);
    const [checkedAllIpva, setCheckedAllIpva] = useState(true);
    const [ipva, setIpva] = useState([]);
    // #Data DPVAT
    const [hasDpvat, setHasDpvat] = useState(false);
    const [checkedAllDpvat, setCheckedAllDpvat] = useState(true);
    const [dpvat, setDpvat] = useState([]);

    // #Data CRLV
    const [hasCRLV, setHasCRLV] = useState(false);
    const [checkedAllCRLV, setCheckedAllCRLV] = useState(true);
    const [crlv, setCrlv] = useState([]);

    // #Data Tickets
    const [hasTickets, setHasTickets] = useState(false);
    const [checkedAllTickets, setCheckedAllTickets] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [ticketsLimit, setTicketsLimit] = useState(0);

    // #Cupom
    const [coupon, setCoupon] = useState(Object);

    // Data da simulação
    const [simulationDate, setSimulationDate] = useState(new Date())

    // load infos
    useEffect(() => {
        setLoading(1);

        async function loadSimulacao() {
            const { userToken } = props.match.params;
            let resp = null;

            resp = await api.post('/simulation/items',
                {
                    userToken
                })
                .catch((e) => {
                    setErroConsulta(true);
                    setMessageError('Erro ao simular, tente novamente mais tarde');
                    setLoading(0);
                });


            if (resp) {
                const { data } = resp;

                setSeller(data.seller);
                if (data.user) {
                    setUserTokenData(data.user);
                }



                if (data.items.length === 0 || (data.items.length === 1 && data.items[0].originalAmount === 0)) {
                    setErroConsulta(true);
                    setMessageError(data.items[0] && data.items[0].data.message ? data.items[0].data.message : "Nenhum dado encontrado para esta simulação");
                    setLoading(0);
                    return;
                }

                setOwner(data.items[0].data.vehicle.owner);
                setPlate(data.items[0].data.vehicle.plate);
                setSimulationDate(data.date);
                setvehicleRenavam(data.items[0].data.renavam);
                setGuid(data.guid);
                const dpvatArray = [];
                const ipvaArray = [];
                const ticketArray = [];
                const licensingArray = [];
                // const clrvArray = [];
                const crlvArray = [];

                let total = 0;
                let totalTaxas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                data.items.map((item) => {
                    if (item.data && item.category.includes('vehicle-debits')) {
                        switch (item.data.type) {
                            case 'licensing':
                                if (item.data.licensingFee > 0) {
                                    setHasLicensing(true);
                                    licensingArray.push({
                                        value: item.data.licensingFee,
                                        exercise: item.data.exercise,
                                        taxes: item.taxes,
                                        identification: item.identification,
                                        plate: item.plate,
                                        category: item.category,
                                        checked: true,
                                    });
                                    setLicensing(licensingArray);
                                    total += item.originalAmount;
                                    item.taxes.map((tax, index) =>
                                        totalTaxas[index] += tax)
                                }
                                break;
                            case 'crlv':
                                setHasCRLV(true);
                                crlvArray.push({
                                    value: item.originalAmount,
                                    taxes: item.taxes,
                                    identification: item.identification,
                                    plate: item.plate,
                                    category: item.category,
                                    checked: true
                                })
                                setCrlv(crlvArray);
                                total += item.originalAmount;
                                item.taxes.map((tax, index) =>
                                    totalTaxas[index] += tax)
                                break;

                            case 'ipva':
                                setHasIpva(true);
                                ipvaArray.push({
                                    value: item.data.value,
                                    exercise: item.data.exercise,
                                    dueDate: item.data.dueDate,
                                    taxes: item.taxes,
                                    identification: item.identification,
                                    plate: item.plate,
                                    category: item.category,
                                    checked: true,
                                })
                                setIpva(ipvaArray);
                                total += item.originalAmount;
                                item.taxes.map((tax, index) =>
                                    totalTaxas[index] += tax)
                                break;

                            case 'dpvat':
                                setHasDpvat(true);
                                dpvatArray.push({
                                    value: item.data.value,
                                    exercise: item.data.exercise,
                                    dueDate: item.data.dueDate,
                                    taxes: item.taxes,
                                    identification: item.identification,
                                    plate: item.plate,
                                    category: item.category,
                                    checked: true,
                                });
                                setDpvat(dpvatArray);
                                total += item.originalAmount;
                                item.taxes.map((tax, index) =>
                                    totalTaxas[index] += tax)
                                break;

                            case 'ticket':
                                setHasTickets(true);
                                ticketArray.push({
                                    value: item.data.value,
                                    taxes: item.taxes,
                                    checked: true,
                                    dueDate: item.data.dueDate,
                                    guide: item.data.guide,
                                    identification: item.identification,
                                    plate: item.plate,
                                    category: item.category,
                                    aiip: item.data.aiip
                                })
                                setTickets(ticketArray);
                                total += item.originalAmount;
                                item.taxes.map((tax, index) =>
                                    totalTaxas[index] += tax)
                                break;
                        }
                    }
                });

                if (ticketArray.length >= 15 && licensingArray.length === 0) {
                    setTicketsLimit(true)
                }

                setTotalDebitos(total);
                setParcelas(totalTaxas);
                setValorParcelaSelecionada(totalTaxas[11])
                setParcelasIniciais(totalTaxas)
            }

            setLoading(0);
        }

        loadSimulacao();
    }, []);


    function recalculateTotal(coupon) {

        let totalDebitosCalculado = 0;

        const items = dpvat.concat(licensing, ipva, tickets, crlv);
        items.map(item => item.checked ? (totalDebitosCalculado += item.value) : 0);

        const parcelasRecalculadasSemDesconto = items.filter(each => each.checked).map(each => each.taxes)
            .reduce((total, next) => { return total.map((each, i) => { return each + next[i] }) }, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]).map(item => Math.round(item))

        let parcelasRecalculadas = parcelasRecalculadasSemDesconto.map(each => {
            if (coupon && coupon.service === 'fee') {
                return each - Math.round(((coupon.discount) / 100) * (each - totalDebitosCalculado))
            }
            return each

        })
        setParcelasIniciais(parcelasRecalculadasSemDesconto);
        setParcelas(parcelasRecalculadas);
        setTotalDebitos(totalDebitosCalculado);
    }

    // marca/desmarca todos subitens de um debito
    function checkedAll(type, status) {
        switch (type) {
            case 'licensing':
                const licensingArray = licensing;
                licensing.map((item, i) => {
                    licensingArray[i].checked = status;
                });
                setLicensing(licensingArray);
                setCheckedAllLicensing(status);
                break;

            case 'ipva':
                const ipvaArray = ipva;
                ipva.map((item, i) => {
                    ipvaArray[i].checked = status;
                });
                setIpva(ipvaArray);
                setCheckedAllIpva(status);
                break;

            case 'dpvat':
                const dpvatArray = dpvat;
                dpvat.map((item, i) => {
                    dpvatArray[i].checked = status;
                });
                setDpvat(dpvatArray);
                setCheckedAllDpvat(status);
                break;

            case 'ticket':
                const ticketArray = tickets;
                tickets.map((item, i) => {
                    ticketArray[i].checked = status;
                });
                setTickets(ticketArray);
                setCheckedAllTickets(status);
                break;

            case 'crlv':
                const crlvArray = crlv;
                crlv.map((item, i) => {
                    crlvArray[i].checked = status;
                });
                setCrlv(crlvArray);
                setCheckedAllCRLV(status);
                break;
            default:
                break;
        }
    }

    // valida se todos subitens do checkbox estão selecionados
    // se todos subitens selecionados -> seleciona checkbox pai
    // se pelo menos um subitem não selecionado -> desativa checkbox pai
    function validateCheckBox(type) {
        let qtdeSelecionada = 0;
        switch (type) {
            case 'licensing':
                licensing.map(item => item.checked ? qtdeSelecionada++ : qtdeSelecionada)
                if (qtdeSelecionada === licensing.length) {
                    checkedAll('licensing', true);
                }
                else {
                    setCheckedAllLicensing(false);
                }
                break;
            case 'dpvat':
                dpvat.map(item => item.checked ? qtdeSelecionada++ : qtdeSelecionada)
                if (qtdeSelecionada === dpvat.length) {
                    checkedAll('dpvat', true);
                }
                else {
                    setCheckedAllDpvat(false);
                }
                break;
            case 'ipva':
                ipva.map(item => item.checked ? qtdeSelecionada++ : qtdeSelecionada)
                if (qtdeSelecionada === ipva.length) {
                    checkedAll('ipva', true);
                }
                else {
                    setCheckedAllIpva(false);
                }
                break;
            case 'ticket':
                tickets.map(item => item.checked ? qtdeSelecionada++ : qtdeSelecionada)
                if (qtdeSelecionada === tickets.length) {
                    checkedAll('ticket', true);
                }
                else {
                    setCheckedAllTickets(false);
                }
                break;
            case 'crlv':
                crlv.map(item => item.checked ? qtdeSelecionada++ : qtdeSelecionada)
                if (qtdeSelecionada === crlv.length) {
                    checkedAll('crlv', true);
                }
                else {
                    setCheckedAllCRLV(false)
                }
        }
    }

    function validateItemIsOverdue (due, now) {
        now = new Date(now)
        now.setHours(now.getHours() - 3)
        now.setHours(0, 0, 0, 0)
        do {
            now.setDate(now.getDate() + 1)
        } while (!(now.getDay() % 6)) // pula finais de semana
        return due && due.split('T')[0] != '0001-01-01' && new Date(due) < now ? true : false
    }

    // valida regra de negocio licenciamento
    // desabilita licenciamento do mesmo ano quando debito selecionado
    // habilita debitos do mesmo ano quando licenciamento selecionado
    function validateLicensingRules(debit) {
        if (hasIpva && ipva.find(item => !item.checked) && debit === 'ipva') {

            const ipvaUnchecked = []
            const licensingUnchecked = licensing
            ipva.map(item => !item.checked ? ipvaUnchecked.push(item) : {})

            if (hasLicensing && ipvaUnchecked.length > 0) {
                licensing.map((lic, i) => {
                    if (ipvaUnchecked.find(item => item.exercise <= lic.exercise || validateItemIsOverdue(item.dueDate, simulationDate))) {
                        licensingUnchecked[i].checked = false;
                        setLicensing(licensingUnchecked);
                        validateCheckBox('licensing')
                    }
                })
            }
        }

        if (hasDpvat && dpvat.find(item => !item.checked) && debit === 'dpvat') {
            const dpvatUnchecked = []
            const licensingUnchecked = licensing
            dpvat.map(item => !item.checked ? dpvatUnchecked.push(item) : {})

            if (hasLicensing && dpvatUnchecked.length > 0) {
                licensing.map((lic, i) => {
                    if (dpvatUnchecked.find(item => item.exercise <= lic.exercise || validateItemIsOverdue(item.dueDate, simulationDate))) {
                        licensingUnchecked[i].checked = false;
                        setLicensing(licensingUnchecked);
                        validateCheckBox('licensing')
                    }
                })
            }
        }

        if (hasTickets && tickets.find(item => !item.checked) && debit === 'ticket') {
            checkedAll('licensing', false);
        }

        // caso licenciamento marcado, marca todos debitos
        if (debit === 'licensing' && licensing.find(item => item.checked) && dpvat.find(item => !item.checked)) {
            const dpvatUnchecked = dpvat
            const licensingUnchecked = []
            licensing.map(item => item.checked ? licensingUnchecked.push(item) : {})

            if (hasDpvat && licensingUnchecked.length > 0) {
                dpvat.map((dpvatItem, i) => {

                    if (licensingUnchecked.find(item => dpvatItem.exercise <= item.exercise || validateItemIsOverdue(dpvatItem.dueDate, simulationDate))) {
                        dpvatUnchecked[i].checked = true;
                        setDpvat(dpvatUnchecked);
                        validateCheckBox('dpvat')
                    }
                })
            }
        }
        if (debit === 'licensing' && licensing.find(item => item.checked) && ipva.find(item => !item.checked)) {
            const ipvaUnchecked = ipva
            const licensingUnchecked = []
            licensing.map(item => item.checked ? licensingUnchecked.push(item) : {})


            if (hasIpva && licensingUnchecked.length > 0) {
                ipva.map((ipvaItem, i) => {
                    if (licensingUnchecked.find(item => ipvaItem.exercise <= item.exercise || validateItemIsOverdue(ipvaItem.dueDate, simulationDate))) {
                        ipvaUnchecked[i].checked = true;
                        setIpva(ipvaUnchecked);
                        validateCheckBox('ipva')
                    }
                })
            }
        }
        if (debit === 'licensing' && licensing.find(item => item.checked) && tickets.find(item => !item.checked)) {
            checkedAll('ticket', true);
        }

        // crlv
        if (debit !== 'crlv' && crlv.find(item => item.checked) && licensing.find(item => !item.checked)) {
            checkedAll('crlv', false);
        }
        if (debit === 'crlv' && crlv.find(item => item.checked)) {
            checkedAll('licensing', true);
        }


    }


    function loadLicensing() {
        if (hasLicensing) {

            return (
                <DebitoItem>
                    <FeeCheckBox>
                        <input type="checkbox" checked={checkedAllLicensing} onChange={() => {
                            checkedAll('licensing', !checkedAllLicensing);
                            validateLicensingRules('licensing')
                            recalculateTotal(coupon)

                        }} />
                        <span>Licenciamento</span>
                    </FeeCheckBox>
                    {
                        licensing.map((item, i) => (
                            <Fee key={i}>
                                <FeeRow>
                                    <div>
                                        <input type="checkbox" checked={item.checked} onChange={() => {
                                            const array = licensing;
                                            array[i].checked = !array[i].checked;
                                            if (array[i].checked === false) {
                                                setCheckedAllLicensing(false);

                                            }
                                            validateCheckBox('licensing');
                                            validateLicensingRules('licensing')
                                            recalculateTotal(coupon);

                                        }
                                        } />
                                        <span>{`Licenciamento ${item.exercise}`}</span>
                                    </div>
                                    <span>{formatPrice(item.value)} </span>
                                </FeeRow>
                            </Fee>
                        ))
                    }
                </DebitoItem>);
        }
        return (<></>);
    }

    function loadIpva() {
        if (hasIpva) {
            return (<DebitoItem>
                <FeeCheckBox>
                    <input type="checkbox" checked={checkedAllIpva} onChange={() => {
                        checkedAll('ipva', !checkedAllIpva);
                        validateLicensingRules('ipva');
                        recalculateTotal(coupon);
                    }} />
                    <span>IPVA</span>
                </FeeCheckBox>
                <Fee>
                    {ipva.map((item, i) => (
                        <FeeRow key={i}>
                            <div>
                                <input type="checkbox" checked={item.checked} onChange={() => {
                                    const array = ipva;
                                    array[i].checked = !array[i].checked;
                                    if (array[i].checked === false) {
                                        setCheckedAllIpva(false);
                                    }
                                    validateCheckBox('ipva');
                                    validateLicensingRules('ipva');
                                    recalculateTotal(coupon);
                                }} />
                                <span>{`Exercício ${item.exercise}`}</span>
                            </div>
                            <span>{formatPrice(item.value)}</span>
                        </FeeRow>
                    ))}
                </Fee>
            </DebitoItem >);
        }

        return (<></>);
    }

    function loadDpvat() {
        if (hasDpvat) {
            return (
                <DebitoItem>
                    <FeeCheckBox>
                        <input type="checkbox" checked={checkedAllDpvat} onChange={() => {
                            checkedAll('dpvat', !checkedAllDpvat);
                            validateLicensingRules('dpvat');
                            recalculateTotal(coupon);
                        }} />
                        <span>DPVAT</span>
                    </FeeCheckBox>
                    <Fee>
                        {dpvat.map((item, i) => (
                            <FeeRow key={i}>
                                <div>
                                    <input type="checkbox" checked={item.checked} onChange={() => {
                                        const array = dpvat;
                                        array[i].checked = !array[i].checked;
                                        validateCheckBox('dpvat');
                                        validateLicensingRules('dpvat');
                                        recalculateTotal(coupon);
                                    }} />
                                    <span>{`Exercício ${item.exercise}`}</span>
                                </div>
                                <span>{formatPrice(item.value)}</span>
                            </FeeRow>
                        ))}
                    </Fee>
                </DebitoItem >
            );
        }
        return <></>;
    }

    function loadCRLV() {
        if (hasCRLV) {
            return (
                <DebitoItem>
                    <FeeCheckBox>
                        <input type="checkbox" checked={checkedAllCRLV} onChange={() => {
                            checkedAll('crlv', !checkedAllCRLV);
                            validateLicensingRules('crlv');
                            validateLicensingRules('licensing');
                            recalculateTotal(coupon);
                        }} />
                        <span>CRLV</span>
                    </FeeCheckBox>
                    {
                        crlv.map((item, i) => (
                            <Fee key={i}>
                                <FeeRow>
                                    <div>
                                        <input type="checkbox" checked={item.checked} onChange={() => {
                                            checkedAll('crlv', !checkedAllCRLV);
                                            validateLicensingRules('crlv');
                                            validateLicensingRules('licensing');
                                            recalculateTotal(coupon);
                                        }
                                        } />
                                        <span>Receber CRLVe por e-mail</span>
                                    </div>
                                    <span>{formatPrice(item.value)} </span>
                                </FeeRow>
                            </Fee>
                        ))
                    }
                </DebitoItem>);
        }
        return (<></>);
    }

    function loadTickets() {
        if (hasTickets) {
            return (
                <DebitoItem>
                    <FeeCheckBox>
                        <input type="checkbox"
                            checked={checkedAllTickets}
                            onChange={() => {
                                checkedAll('ticket', !checkedAllTickets);
                                validateLicensingRules('ticket');
                                recalculateTotal(coupon);
                            }}
                        />
                        <span>Multas</span>
                    </FeeCheckBox>
                    <Fee>
                        {tickets.map((item, i) => (
                            <FeeRow key={i}>
                                <div>
                                    <input type="checkbox" checked={item.checked} onChange={() => {
                                        const array = tickets;
                                        array[i].checked = !array[i].checked;
                                        if (array[i].checked === false) {
                                            setCheckedAllTickets(false);
                                        }
                                        validateCheckBox('ticket');
                                        validateLicensingRules('ticket');
                                        recalculateTotal(coupon);

                                    }} />
                                    <span >{`Venc.: ${formatDateBar(item.dueDate)} - ${item.guide}`}</span>
                                </div>
                                <span >{formatPrice(item.value)}</span>
                            </FeeRow>
                        ))}
                    </Fee>
                </DebitoItem>
            );
        }
        return <></>;
    }

    async function handleSubmit() {

        if (totalDebitos <= 0 || valorParcelaSelecionada <= 0) {
            toast.error('Valor inválido');
            return;
        }

        setLoadingPayment(1);

        // valida quais debitos foram selecionados
        let items = [];

        ipva.filter(item => item.checked).map(item => {
            items.push({
                category: item.category,
                identification: item.identification,
                plate: item.plate
            });
        })

        dpvat.filter(item => item.checked).map(item => {
            items.push({
                category: item.category,
                identification: item.identification,
                plate: item.plate
            });
        })

        licensing.filter(item => item.checked).map(item => {
            items.push({
                category: item.category,
                identification: item.identification,
                plate: item.plate
            });
        })

        tickets.filter(item => item.checked).map(item => {
            items.push({
                category: item.category,
                identification: item.identification,
                plate: item.plate
            });
        })

        crlv.filter(item => item.checked).map(item => {
            items.push({
                category: item.category,
                identification: item.identification,
                plate: item.plate
            });
        })

        const { userToken } = props.match.params;

        let payload = {}
        let dataDefault = {
            items
        };

        if (props.user.signed) {
            const { userId, cpf, email, fullName, phones } = props.user.dados;

            payload = {
                ...dataDefault,
                oldGuid: guid,
                seller,
                user: {
                    id: userId,
                    name: fullName,
                    email: email,
                    phones: [{
                        type: phones[0].type,
                        number: phones[0].number
                    }],
                    documents: [{
                        type: "CPF",
                        identification: cpf
                    }]
                }
            }
        }

        else {
            payload = {
                ...dataDefault,
                userToken
            };
        }

        const res = await api.post('/simulation/items', payload, { headers: { Authorization: `Bearer ${token}` } }).catch((e) => {
            setErroConsulta(true);
            setMessageError('Ops, houve um erro ao processar sua simulação, tente novamente mais tarde..');
            setLoading(0);
        });

        if (res && res.data.changed) {
            setLoading(0);
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Parece que seus débitos foram atualizados, a página será recarregada!',
                didClose: () => { document.location.reload(true); }
            })

            return;
        }

        if (res && res.data) {

            const totalSelecionadoComDesconto = parcelas[parcelaSelecionada - 1];
            const valorParcelaSelecionadaComDesconto = Math.round(parcelas[parcelaSelecionada - 1] / parcelaSelecionada);

            const totalSelecionado = parcelasIniciais[parcelaSelecionada - 1];
            const valorParcelaSelecionada = Math.round(parcelasIniciais[parcelaSelecionada - 1] / parcelaSelecionada);

            //aciona redux
            dispatch({
                type: '@cart/UPDATE_VALUES',
                simulacao: {
                    product: 'vehicle-debits',
                    description: `Pagamento renavam ${vehicleRenavam}`,
                    guid: res.data.guid,
                    parcelaSelecionada,
                    valorParcelaSelecionada,
                    valorParcelaSelecionadaComDesconto,
                    totalSelecionado,
                    totalSelecionadoComDesconto,
                    originalAmount: totalDebitos,
                    parcelas,
                    parcelasIniciais,
                    coupon,
                    userToken,
                    userTokenData,
                    seller,
                    items: res.data.items,
                }
            });

            props.history.push(`/pagamento/`)
        }

        // setRealizarPagamento(1);

    }


    function renderSimulacao() {

        if (erroConsulta === false) {

            return (
                <Content>
                    <Helmet>
                        <meta name="kdt:page" content="product" />
                        <meta name="kdt:product" content={`sku=${vehicleRenavam}, name=Pagamento de Tributos`} />
                        <script src="https://www.mercadopago.com/v2/security.js" view="item"></script>
                    </Helmet>
                    <DataInfo>
                        <Debitos>
                            {/* <img src={car} alt="car" /> */}
                            <FaCar size={38} color="#441188" />
                            <DadosProprietario>
                                <thead>
                                    <tr>
                                        <th>Renavam</th>
                                        <th>Placa</th>
                                        <th>Proprietário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{vehicleRenavam}</td>
                                        <td>{plate}</td>
                                        <td>{owner}</td>
                                    </tr>
                                </tbody>
                            </DadosProprietario>
                            {
                                loadCRLV()
                            }
                            {
                                loadLicensing()
                            }
                            {
                                loadIpva()
                            }
                            {
                                loadDpvat()
                            }
                            {
                                loadTickets()
                            }
                        </Debitos>
                        <Total>
                            <span>Total de Débitos:</span>
                            <span>{formatPrice(totalDebitos)}</span>
                        </Total>
                        {
                            ticketsLimit ?
                                (<MensagemMultas>*A pesquisa dos seus débitos veiculares apresentada pela Prodesp traz no máximo 15 multas. Caso tenha mais de 15 multas é necessário efetuar o pagamento e após baixa na Sefaz-SP, as demais multas serão disponibilizadas para nova consulta e pagamento em nossa plataforma.</MensagemMultas>)
                                :
                                (<></>)
                        }
                    </DataInfo>
                    <Parcelas parcelas={parcelas}
                        callbackParcelas={(parcel) => setParcelaSelecionada(parcel)}
                        callbackRecalculate={(coupon) => {
                            recalculateTotal(coupon);
                            setCoupon(coupon)
                        }}
                        callbackHandleSubmit={() => handleSubmit()}
                        taxesDescription={
                            ["* Custo da operação de 2,95% a.m. aplicável para parcelamento em 12x. Para demais parcelas, consulte.",
                                "** Não Cobramos Taxa de Despachante"]
                        }
                    />

                </Content >
            );


        }

        return (

            <ErrorSimulacao>
                {
                    messageError === 'Veículo já licenciado' ?
                        (<>
                            <h1>Seu veículo não possui débitos</h1>
                            <span><strong>Mensagem:</strong> Veículo já licenciado </span>
                        </>)
                        :
                        (<>
                            <H1Falha>Falha ao realizar simulação</H1Falha>
                            <span><strong>Erro:</strong> {messageError}</span>
                            <LinkTryAgain href={`/simulacoes/${props.match.params.userToken}`}>Tentar novamente</LinkTryAgain>
                        </>)
                }

            </ErrorSimulacao>

        );
    }

    return (
        <Container>
            <HeaderDate />
            {
                loading
                    ? (<LoadingSimulacao>
                        <Spinner animation="border" variant="primary" />
                        <span>Consultando dados, aguarde...</span>
                    </LoadingSimulacao>)
                    : renderSimulacao()
            }
            <Footer />
        </Container >
    );
}


export default connect(state => ({
    user: state.user,
    pagamento: state.pagamento
}))(Simulacoes);

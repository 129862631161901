const initial_state = {
  status: false,
}

export default function StatusPagamento(state = initial_state, action) {
  switch (action.type) {
    case '@pagamento/STATUS':
      return {
        ...state,
        status: action.status
      };
    default:
      return state;
  }

}

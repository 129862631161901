import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import apiUser from '../../services/api-user';
import { Container, Content } from './styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';


export default function RedefinirSenha(props) {

  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [enviado, setEnviado] = useState(false);
  const [loading, setLoading] = useState(0);

  async function redefinir(e) {
    e.preventDefault();
    setLoading(1);
    const { id } = props.match.params;

    if(senha !== confirmarSenha){
      toast.error('Senhas não conferem');
      setLoading(0);
      return;
    }

    const resp = await apiUser.patch(`/v1/user/resetpassword/${id}`, {
      password: senha
    }).catch(e => {
      if(e.response.data.statusCode === '404'){
        toast.error('Usuário não encontrado');
      }
      else{
        toast.error('Erro ao criar senha');
      }

      return;
    });

    if (resp) {
      setEnviado(true);
    }
    setLoading(0);
  }

  return (
    <Container>
      <Header />
      <Content>
        <h1>Realizar meu primeiro <strong>acesso</strong></h1>
        {
          enviado ?
            (<>
              <span>Sua <strong>senha</strong> foi cadastrada com <strong>sucesso</strong>!</span>
              <Link to='/'>Realizar login</Link>
            </>)
            :
            (
              <>
                <span>Escolha uma <strong>senha</strong></span>
                <form onSubmit={redefinir}>
                  <input minLength={6} required value={senha} onChange={e => setSenha(e.target.value)} type="password" placeholder="Digite sua senha" />
                  <input minLength={6} required value={confirmarSenha} onChange={e => setConfirmarSenha(e.target.value)} type="password" placeholder="Confirme sua senha" />
                  <button type="submit">
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                        <>Salvar</>
                      )}
                  </button>
                </form>
              </>
            )
        }
      </Content >
      <Footer />
    </Container >
  );
}

import React from 'react';
import { Helmet } from 'react-helmet';

import { Container } from './styles';

export default function Footer() {
  return (
    <Container>
      <Helmet>
        <noscript>{`<iframe title="google-tag" src="https://www.googletagmanager.com/ns.html?id=GTM-KMZPSV9"
        height="0" width="0" style={"display:none;visibility:hidden"}></iframe>`}</noscript>
      </Helmet>
      <p>TAKI PAGAMENTOS SA - CNPJ: 14.476.001/0001-11</p>
      <p>Copyright © 2020 - Takí Pay</p>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import { Spinner, Breadcrumb, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';

import { Container, Content, TableDiv, FiltrosDiv } from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';

import { formatPriceOld } from '../../../../helpers/format';
import apiPortal from '../../../../services/api-portal';


function POS() {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(0);
    const [loadingByNsu, setLoadingByNsu] = useState(0);
    const [data, setData] = useState([]);
    const [category, setCategory] = useState('debitos-veiculares')
    const [nsu, setNsu] = useState('')

    useEffect(() => {
        loadData()

        //eslint-disable-next-line
    }, [category])

    async function loadData() {
        setLoading(1);
        const res = await apiPortal.get(`/pos/${category}`, { headers: { Authorization: `Bearer ${token}` } }).catch(e => Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erro ao buscar dados!',
          }))

        if (res && res.data) {
            setData(res.data)
        }
        setLoading(0);
    }

    async function loadByNsu(){
        setLoadingByNsu(1);
        const res = await apiPortal.get(`/pos/${category}/${nsu}`, { headers: { Authorization: `Bearer ${token}` } }).catch(e => Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Erro ao buscar dados!',
          }))

        if (res && res.data) {
            setData(res.data)
        }
        setLoadingByNsu(0);
    }

    return <Container>
        <HeaderLogged />
        <Content>
            <h1>POS</h1>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>POS</Breadcrumb.Item>
            </Breadcrumb>
            <FiltrosDiv>
                <select value={category} onChange={e => setCategory(e.target.value)}>
                    <option value="debitos-veiculares">Débitos Veiculares</option>
                    <option value="boleto">Boletos</option>
                </select>
                <input placeholder="Buscar por NSU" value={nsu} onChange={e => setNsu(e.target.value)} />
                {
                    loadingByNsu ?
                    (<button disabled><Spinner animation="border" variant="light" size="sm" /></button>)
                    :
                    (<button type="button" onClick={loadByNsu}><FaSearch color="#FFF"/></button>)
                }
                
            </FiltrosDiv>

            {
                loading ?
                    (<Spinner animation="border" />)
                    :
                    <TableDiv>
                        <Table responsive="xl" striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Identificador</th>
                                    <th>Nsu</th>
                                    <th>Valor</th>
                                    <th>Valor original</th>
                                    <th>Parcelas</th>
                                    <th>Cartão</th>
                                    <th>Bandeira</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>{
                                data.map(item => (
                                    <tr key={item._id}>
                                        <td>{item.payment_datetime ? item.payment_datetime : item.data.payment_datetime}</td>
                                        <td>{item.text_identificator ? item.text_identificator : item.data.text_identificator}</td>
                                        <td>{item.payment_nsu_acquire ? item.payment_nsu_acquire : item.data.payment_nsu_acquire}</td>
                                        <td>{item.payment_value ? formatPriceOld(item.payment_value) : formatPriceOld(item.data.payment_value)}</td>
                                        <td>{item.valor_original ? formatPriceOld(item.valor_original) : formatPriceOld(item.data.valor_original) }</td>
                                        <td>{item.payment_parcells ? item.payment_parcells : item.data.payment_parcells}</td>
                                        <td>{item.card_pan ? item.card_pan : item.data.card_pan}</td>
                                        <td>{item.card_brand ? item.card_brand : item.data.card_brand}</td>
                                        <td>{item.status ? item.status : item.data.status}</td>
                                    </tr>
                                ))
                            }</tbody>
                        </Table>
                    </TableDiv>

            }

        </Content>

    </Container>;
}

export default POS;
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { FaRegCopy } from 'react-icons/fa';

import { Container, Content, CopyDiv } from './styles';
import HeaderLogged from '../../components/HeaderLogged';

function LinkGerado({ match }) {
    const value = `${document.location.origin}/${match.params.category}/${match.params.guid}`;

    return <Container>
        <HeaderLogged />

        <Content>
            <h1>Link de pagamento</h1>
            <CopyDiv>
                <input defaultValue={value} />
                <CopyToClipboard text={value}
                >
                    <button onClick={() => toast.success('Link copiado')}>Copiar <FaRegCopy size={14} color="#fff" /></button>
                </CopyToClipboard>
            </CopyDiv>
            <p>O seu link foi gerado com <strong>sucesso!</strong> Clique no botão para copiar para sua área de transferência.</p>
        </Content>


    </Container>;
}

export default LinkGerado;

import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Content = styled.div`
    margin: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 48%;

  @media(max-width: 414px){
    width: 70%;
  }
`;

export const FormValor = styled.form`
    width: 100%;
`;

export const LinkPorValorDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    div{
        width: 48%;
    }
    input, select{
        height: 41px;
    }
    select{
        text-align: center; /* para firefox */
        text-align-last: center; /* para chrome */
        width: 100%;
        padding: 10px;
        border-radius: 4px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
     button{
        width: 48%;
    }
`;

export const PreviewButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ee0066;
    span{
        color: #fff;
    }
    svg{
        margin-left: 5px;
    }
    &:hover{
            background-color: ${lighten(0.08, '#ee0066')};
          }

    @media (max-width: 750px) {
    span{
        display: none;
      }
    }
`;

export const DescricaoInput = styled.input`
    margin-top: 15px;
    margin-bottom: 5px;
`;

export const ParcelasCard = styled.div`
    width: 100%;
    margin: 20px;
    background-color: #EEE;
    border-radius: 4px;
    padding: 20px 40px;

    ul{
        margin: 0;
    }

    ul li{
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px #c3c3c3 solid;
        strong{
            font-size: 16px;
        }
    }
`;

export const FormData = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;

`;

export const LabelDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    label{
      width: 100px;
    }
    @media (max-width: 414px) {
      label, input{
        width: 100% !important;
      }

      flex-direction: column;
    }
    input{
      width: 450px;
      margin: 10px 0;
    }

`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
`;

export const TwoButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
    label{
      width: 100px;
    }

  input{
      width: 450px;
      margin: 10px 0;
    }

  @media (max-width: 414px) {
      label, input{
        width: 100% !important;
      }

      flex-direction: column;
    }
`;

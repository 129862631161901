import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

`;

export const Content = styled.div`
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1, h2, h3{
      /* color: #fff; */
    }
    h1{
      font-size: 80px;
    }
    h2{
      font-size: 50px;
    }
    h3{
      font-size: 20px;
    }

    a{
      /* color: #fff; */
      text-decoration: underline;
      cursor: pointer;
    }
`;


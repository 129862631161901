import React, { useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { isValid } from 'cpf';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { endOfToday } from 'date-fns';

// import apiGateway from '../../services/api-gateway';
import apiUserToken from '../../../services/api-userToken';
import apiBoletos from '../../../services/boletos';
import { phoneMask, cpfMask } from '../../../helpers/masks';
import { validateRole } from '../../../helpers/roles';
import { formatDateBar, formatPrice } from '../../../helpers/format';
import HeaderLogged from '../../../components/HeaderLogged';
import {
    Container,
    Content,
    FormBarCode,
    FormData,
    RemoveButton,
    Button,
    TwoButtons,
    TableOverFlow,
    BoletoDiv,
    DatePickerDiv,
    LabelDiv,
} from './styles';
import whitelabel from '../../../helpers/whitelabel';
const company = localStorage.getItem('company')

function Boletos({ user, history }) {
    // boleto
    const [boletos, setBoletos] = useState([]);
    const [barCode, setBarCode] = useState('');

    // auxiliar states
    // const token = localStorage.getItem('token');
    const [formUserActive, setFormUserActive] = useState(false);
    const [render, setRender] = useState(false);
    const [loading, setLoading] = useState(0);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const [loadingSimulation, setLoadingSimulation] = useState(0);

    // form cliente
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');

    // Datepicker
    const [endDatePicker, setEndDatePicker] = useState(endOfToday());
    const minDatePicker = endOfToday();
    const seller = {
        companyId: whitelabel[company].companyId,
        merchantId: whitelabel[company].merchantId
    }


    async function addBoleto(e) {
        e.preventDefault();

        if (boletos.find((element) => element.barCode === barCode)) {
            toast.error('Esse boleto já foi adicionado');
            return;
        }

        setLoading(1);
        const res = await apiBoletos.post('simulation/boletos', {
            seller,
            items: [
                {
                    identification: barCode,
                }],
        })
            .catch((e) => {
                toast.error('Erro ao buscar boleto');
            });

        if (res && res.data) {
            const array = boletos;
            if (res.data[0].data.totalValue > 0) {
                const boleto = {
                    barCode,
                    dueDate: res.data[0].data.dueDate,
                    totalValue: res.data[0].data.totalValue,
                };
                array.push(boleto);
                setBoletos(array);
                setRender(!render);
            } else {
                toast.error('Boleto inválido ou vencido');
            }
        }
        setBarCode('');
        setLoading(0);
    }

    function removeBoleto(index) {
        const array = boletos;
        array.splice(index, 1);
        setBoletos(array);
        setRender(!render);
    }

    async function sendRequestSimulation() {
        if (boletos.length <= 0) {
            toast.error('Insira pelo menos um boleto');
            return;
        }

        setLoadingSimulation(1);
        const boletosToPaylod = [];
        boletos.map((item) => boletosToPaylod.push({ identification: item.barCode, category: 'boletos' }));


        const res = await apiUserToken.post('/user/token', {
            seller,
            user: {
                name: user.dados.fullName,
                email: user.dados.email,
                phones: [
                    {
                        type: user.dados.phones[0] ? user.dados.phones[0].type : '',
                        number: user.dados.phones[0] ? user.dados.phones[0].number : '',
                    },
                ],
                documents: [
                    {
                        type: 'CPF',
                        identification: user.dados.cpf,
                    },
                ],
            },
            transactionLink: false,
            items: boletosToPaylod,
        }).catch((e) => {
            toast.error('Erro ao simular');
            setLoadingSimulation(0);
        });

        if (res && res.data) {
            setLoadingSimulation(0);
            history.push(`/boletos/${res.data.id}`);
        }
    }

    async function sendRequestGenerateLink(e) {
        e.preventDefault();
        setLoadingRequest(1);

        if (!isValid(cpf)) {
            toast.error('CPF inválido');
            setLoadingRequest(0);
            return;
        }

        const newPhone = phone
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '');

        const newCpf = cpf.replace('.', '').replace('.', '').replace('-', '');

        const boletosToPaylod = [];
        boletos.map((item) => boletosToPaylod.push({ identification: item.barCode, category: 'boletos' }));

        const res = await apiUserToken.post('/user/token', {
            seller,
            user: {
                name,
                email,
                phones: [
                    {
                        type: newPhone.length === 10 ? 'phone' : 'cellphone',
                        number: newPhone,
                    },
                ],
                documents: [
                    {
                        type: 'CPF',
                        identification: newCpf,
                    },
                ],
            },
            transactionLink: true,
            from : 'TAKIPAY_MINHACONTA_BOLETO',
            creator: {
                id: user.dados.userId,
                name: user.dados.fullName,
            },
            dueDate: endDatePicker,
            items: boletosToPaylod,
        }).catch((e) => {
            toast.error('Erro ao gerar link');
        });

        if (res && res.data) {
            history.push(`/link/boletos/${res.data.id}`)
        }


        setLoadingRequest(0);
    }

    return (<Container>
        <HeaderLogged />
        {
            !formUserActive
                ? (<Content>
                    <h1>Boletos</h1>
                    <FormBarCode onSubmit={addBoleto}>
                        <input required minLength={43} maxLength={100} type="tel" value={barCode} onChange={(e) => {
                            setBarCode(e.target.value);
                        }}
                            placeholder="Digite o código de barras" />

                        {
                            loading
                                ? (<button disabled><Spinner animation="border" variant="light" size="sm" /></button>)
                                : (<button type="submit">Adicionar</button>)
                        }

                    </FormBarCode>
                    <TableOverFlow>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Boletos</th>
                                    <th>Remover</th>
                                </tr>
                            </thead>
                            <tbody value={render}>
                                {
                                    boletos.map((item, index) => (
                                        <tr key={item.barCode}>
                                            <td>
                                                <BoletoDiv>
                                                    <span><strong>Código: </strong>{item.barCode}</span>
                                                    <span><strong>Vencimento: </strong>{item.dueDate ? formatDateBar(item.dueDate) : ''}</span>
                                                    <span><strong>Valor: </strong>{formatPrice(item.totalValue)}</span>
                                                </BoletoDiv>
                                            </td>
                                            <td><RemoveButton onClick={() => removeBoleto(index)}><FaTimes color="#ee0066" size={16} /></RemoveButton></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table >
                    </TableOverFlow>
                    <TwoButtons>
                        {
                            loadingSimulation
                                ? (<Button disabled><Spinner animation="border" variant="light" size="sm" /></Button>)
                                : (<Button onClick={() => sendRequestSimulation()}>Simular</Button>)
                        }
                        {
                            validateRole('transactionLinkCreate')
                                ? (<Button type="button" onClick={() => {
                                    if (boletos.length > 0) {
                                        setFormUserActive(true);
                                    } else {
                                        toast.error('Insira pelo menos um boleto');
                                    }
                                }}>Próximo</Button>)
                                : (<></>)
                        }
                    </TwoButtons>
                </Content>)
                : (<Content>

                    <h1>Dados do cliente</h1>
                    <FormData onSubmit={sendRequestGenerateLink}>
                        <LabelDiv>
                            <label>Nome: </label>
                            <input required value={name} maxLength="45" onChange={(e) => setName(e.target.value)} placeholder="Ex: José dos Santos" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>CPF: </label>
                            <input required type="tel" minLength={14} maxLength={14} value={cpfMask(cpf)} onChange={(e) => setCpf(e.target.value)} placeholder="Ex: 000.000.000-00" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>E-mail: </label>
                            <input required type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="contato@takipay.com.br" />
                        </LabelDiv>

                        <LabelDiv>
                            <label>Telefone: </label>
                            <input required type="tel" minLength={14} maxLength={15} value={phoneMask(phone)} onChange={(e) => setPhone(e.target.value)} placeholder="Ex: (11) 0000-0000" />
                        </LabelDiv>

                        <DatePickerDiv>
                            <label>Expira em:</label>
                            <DatePicker showTimeInput minDate={minDatePicker} dateFormat="dd/MM/yyyy" required selected={endDatePicker} onChange={(date) => setEndDatePicker(date)} />
                        </DatePickerDiv>

                        <TwoButtons>
                            <Button type="button" onClick={() => setFormUserActive(false)}>Voltar</Button>
                            {
                                loadingRequest
                                    ? (<Button disabled><Spinner animation="border" variant="light" size="sm" /></Button>)
                                    : (<Button type="submit">Gerar link</Button>)
                            }
                        </TwoButtons>

                    </FormData>

                </Content >)
        }
    </Container >);
}

export default connect((state) => ({
    user: state.user,

}))(Boletos);

import React, { useState, useEffect } from 'react';
import { Table, Popover, OverlayTrigger, Spinner, Pagination, Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import DatePicker from 'react-datepicker';
import { startOfDay, endOfDay } from 'date-fns';
import { FaFilter, FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import {
    Container,
    Content,
    FiltroButton,
    InputsDiv,
    Input,
    ItemDescription,
    AplicarButton,
    LimparFiltro,
    DateDiv,
    LimitsDiv,
    TableDiv,
    TotalPorStatus,
    SpanStatus,
    RowFilter
} from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiTransacao from '../../../../services/transacao';
import { formatDateBarWithHours, formatPrice, formatPriceSimple } from '../../../../helpers/format';
import { renderLabelStatusV2, renderStatusSum } from '../../../../helpers/labels/statusTransaction';
import { renderIdentificationDebits } from '../../../../helpers/labels/renders';


function Transacoes() {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageActive, setPageActive] = useState(1);
    const [limitActive, setLimitActive] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPorStatus, setTotalPorStatus] = useState([]);

    //filters
    const [filters, setFilters] = useState("");
    const [protocol, setProtocol] = useState("");
    const [email, setEmail] = useState("");
    const [authorizationCode, setAuthorizationCode] = useState("");
    const [description, setDescription] = useState("");
    const [startDatePicker, setStartDatePicker] = useState();
    const [endDatePicker, setEndDatePicker] = useState("");
    const [startDateCapture, setStartDateCapture] = useState("");
    const [endDateCapture, setEndDateCapture] = useState("");
    // const [actionSelected, setActionSelected] = useState("");
    const [statusSelected, setStatusSelected] = useState("");
    const filtroInicial = `&dt_tran_ini=${startOfDay(new Date()).toISOString()}&dt_tran_fin=${endOfDay(new Date()).toISOString()}`;

    useEffect(() => {
        // primeiro loading
        setFilters(filtroInicial);
        loadData(filtroInicial, pageActive, limitActive);
        //eslint-disable-next-line
    }, [])

    function aplicarFiltros() {
        setLoading(1);

        let filter = "";

        if (protocol) {
            filter += `&guid=${protocol}`
        }
        if (email) {
            filter += `&email=${email}`
        }
        if (authorizationCode) {
            filter += `&authorization_code=${authorizationCode}`
        }
        // if (actionSelected && statusSelected) {
        //     filter += `&action=${actionSelected}&status=${statusSelected}`
        // }
        if (statusSelected) {
            filter += `&status=${statusSelected}`
        }
        if (description) {
            filter += `&items=${description}`
        }
        if (startDatePicker && endDatePicker) {
            filter += `&dt_tran_ini=${startDatePicker.toISOString()}&dt_tran_fin=${endOfDay(endDatePicker).toISOString()}`
        }
        if (startDateCapture && endDateCapture) {
            filter += `&dt_cpt_ini=${startDateCapture.toISOString()}&dt_cpt_fin=${endOfDay(endDateCapture).toISOString()}`
        }

        setFilters(filter);
        setPageActive(1);
        loadData(filter, 1, limitActive);
    }

    function limparFiltros() {
        setFilters("");
        setProtocol("");
        setEmail("");
        setAuthorizationCode("");
        setDescription("");
        setStartDatePicker("");
        setEndDatePicker("");
        setStartDateCapture("");
        setEndDateCapture("");
        setStatusSelected("");
        // setActionSelected("");
        setPageActive(1);
        loadData(filtroInicial, 1, limitActive);
    }

    // function renderStatusOptions(action) {
    //     switch (action) {
    //         case 'reserve': return (<>
    //             <option value=""></option>
    //             <option value="approved">Aprovado</option>
    //             <option value="declined">Recusado</option>
    //             <option value="error">Erro</option>
    //         </>);
    //         case 'capture': return (<>
    //             <option value=""></option>
    //             <option value="approved">Aprovado</option>
    //             <option value="declined">Recusado</option>
    //             <option value="error">Erro</option>
    //         </>);
    //         case 'analyze': return (<>
    //             <option value=""></option>
    //             <option value="approved">Aprovado</option>
    //             <option value="declined">Recusado</option>
    //             <option value="pending">Pendente</option>
    //             <option value="canceled">Cancelado</option>
    //             <option value="fraud">Fraude</option>
    //             <option value="not_authotized">Não Autorizado</option>
    //             <option value="error">Erro</option>
    //         </>);
    //         default: break;
    //     }
    // }

    async function loadData(filters, pageNumber, limit) {
        setLoading(1);
        const res = await apiTransacao.get(`/ecommerce/transaction?limit=${limit}&page=${pageNumber}${filters}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e => Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao buscar dados!',
            }));

        if (res && res.data && res.data.records) {
            setRecords(res.data.records);
            setTotalCount(res.data.totalCount);
            const pagesData = [];
            const pagesLimit = Math.ceil(res.data.totalCount / res.data.limit)
            for (let i = 1; i <= pagesLimit; i++) {
                pagesData.push(<Pagination.Item
                    key={i}
                    active={pageNumber === i}
                    onClick={() => {
                        setPageActive(i);
                        loadData(filters, i, limit);
                    }}>{i}</Pagination.Item>)
            }
            setPages(pagesData);
            loadSum(filters)
            // calculaTotalPorStatus(res.data.records);

        }
        setLoading(0);
    }

    async function loadSum(filters) {
        const res = await apiTransacao.get(`/ecommerce/transaction/sum?${filters}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e => Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao buscar dados!',
            }));

        const { data } = res;
        const array = []
        for (var property in data) {
            array.push({ action: property, value: data[property] })
        }
        setTotalPorStatus(data);
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Filtros</Popover.Title>
            <Popover.Content>
                <Input value={protocol} onChange={e => setProtocol(e.target.value)} placeholder="Protocolo" />
                <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                <InputsDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Início" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={startDatePicker} onChange={date => setStartDatePicker(date)} />
                    </DateDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Fim" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDatePicker} onChange={date => setEndDatePicker(date)} />
                    </DateDiv>
                </InputsDiv>
                <Input value={description} onChange={e => setDescription(e.target.value)} placeholder="Descrição Item" />
                <InputsDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Início Captura" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={startDateCapture} onChange={date => setStartDateCapture(date)} />
                    </DateDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Fim Captura" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDateCapture} onChange={date => setEndDateCapture(date)} />
                    </DateDiv>
                </InputsDiv>
                <Input value={authorizationCode} onChange={e => setAuthorizationCode(e.target.value)} placeholder="Código de Autorização" />
                <InputsDiv>
     
                    <select value={statusSelected} onChange={e => setStatusSelected(e.target.value)} >
                        <option></option>
                        <option value="Approved">Aprovado</option>
                        <option value="Approved Manually">Aprovado Manual</option>
                        <option value="Analyzing">Análise</option>
                        <option value="Bank Declined">Banco</option>
                        <option value="Canceled Manually">Cancelado Manual</option>
                        <option value="Canceled">Antifraude</option>
                        <option value="Cancel Declined">Não cancelado</option>
                        <option value="Capture Declined">Não capturado</option>
                        <option value="Error Capturing">Erro Captura</option>
                        <option value="Error Reserving">Erro Reserva</option>
                        <option value="Validate Declined">Erro Validação</option>
                        <option value="Error Validating Data">Erro</option>                        
                    </select>
                </InputsDiv>
                <LimparFiltro onClick={() => limparFiltros()}>limpar filtros</LimparFiltro>
                <AplicarButton onClick={() => aplicarFiltros()}>Aplicar</AplicarButton>
            </Popover.Content>
        </Popover>
    );


    return <Container>
        <HeaderLogged />
        <Content>
            <h1>Transações</h1>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Transações</Breadcrumb.Item>
            </Breadcrumb>

            <RowFilter>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <FiltroButton type="button"><FaFilter color="#fff" />Filtros</FiltroButton>
                </OverlayTrigger>
                <TotalPorStatus
                    visible={totalPorStatus ? true : false}
                >
                    <span><FaMoneyBillAlt size={16} color="#2ecc71" /></span>
                    {totalPorStatus['Approved'] && <SpanStatus important>{renderStatusSum('Approved')} {formatPriceSimple(totalPorStatus['Approved'])}</SpanStatus>}
                    {totalPorStatus['Approved Manually'] && <SpanStatus important>{renderStatusSum('Approved Manually')} {formatPriceSimple(totalPorStatus['Approved Manually'])}</SpanStatus>}
                    {totalPorStatus['Analyzing'] && <SpanStatus important>{renderStatusSum('Analyzing')} {formatPriceSimple(totalPorStatus['Analyzing'])}</SpanStatus>}
                    {totalPorStatus['Bank Declined'] && <SpanStatus>{renderStatusSum('Bank Declined')} {formatPriceSimple(totalPorStatus['Bank Declined'])}</SpanStatus>}
                    {totalPorStatus['Canceled'] && <SpanStatus>{renderStatusSum('Canceled')} {formatPriceSimple(totalPorStatus['Canceled'])}</SpanStatus>}
                    {totalPorStatus['Canceled Manually'] && <SpanStatus>{renderStatusSum('Canceled Manually')} {formatPriceSimple(totalPorStatus['Canceled Manually'])}</SpanStatus>}
                    {totalPorStatus['Cancel Declined'] && <SpanStatus>{renderStatusSum('Cancel Declined')} {formatPriceSimple(totalPorStatus['Cancel Declined'])}</SpanStatus>}
                    {totalPorStatus['Capture Declined'] && <SpanStatus>{renderStatusSum('Capture Declined')} {formatPriceSimple(totalPorStatus['Capture Declined'])}</SpanStatus>}
                    {totalPorStatus['Error Capturing'] && <SpanStatus>{renderStatusSum('Error Capturing')} {formatPriceSimple(totalPorStatus['Error Capturing'])}</SpanStatus>}
                    {totalPorStatus['Error Reserving'] && <SpanStatus>{renderStatusSum('Error Reserving')} {formatPriceSimple(totalPorStatus['Error Reserving'])}</SpanStatus>}
                    {totalPorStatus['Error Validating Data'] && <SpanStatus>{renderStatusSum('Error Validating Data')} {formatPriceSimple(totalPorStatus['Error Validating Data'])}</SpanStatus>}
                    {totalPorStatus['Validate Declined'] && <SpanStatus>{renderStatusSum('Validate Declined')} {formatPriceSimple(totalPorStatus['Validate Declined'])}</SpanStatus>}
                    {totalPorStatus['total'] && <SpanStatus>{renderStatusSum('total')} {formatPriceSimple(totalPorStatus['total'])}</SpanStatus>}
                </TotalPorStatus>
            </RowFilter>


            {
                loading ?
                    (<Spinner animation="border" variant="secondary" />)
                    :
                    (
                        <>

                            <TableDiv>
                                <Table responsive="xl" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Protocolo</th>
                                            <th>Data Transação</th>
                                            <th>Itens</th>
                                            <th>Valor Original</th>
                                            <th>Valor Pago</th>
                                            <th>Parcelas</th>
                                            <th>Data Captura</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            records.map(record => (
                                                <tr key={record.guid}>
                                                    <td><Link to={`/portal/transacoes/${record.guid}`}>{record.guid}</Link></td>
                                                    <td>{formatDateBarWithHours(record.date)}</td>
                                                    <td>{
                                                        record.items && record.items.map((item, i) => (
                                                            <div key={i}>
                                                                <ItemDescription><strong>Item:</strong> {renderIdentificationDebits(item.identification)}</ItemDescription>
                                                                <br />
                                                            </div>
                                                        ))
                                                    }</td>
                                                    <td>{formatPrice(record.payment.originalAmount ? record.payment.originalAmount : 0)}</td>
                                                    <td>{formatPrice(record.payment.amount)}</td>
                                                    <td>{record.payment.installments}x</td>
                                                    <td>{
                                                        Array.isArray(record.status) &&
                                                            record.status.filter(item => item.action === 'capture')[0] ?
                                                            formatDateBarWithHours(record.status.filter(item => item.action === 'capture')[0].date)
                                                            :
                                                            (<>Não capturado</>)
                                                    }</td>
                                                    <td>{
                                                        record && record.prominentStatus && renderLabelStatusV2(record.prominentStatus.description)
                                                    }</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </TableDiv>
                            <LimitsDiv>
                                <label>visualizando</label>
                                <input value={limitActive} onChange={e => {
                                    if (e.target.value > 0 && e.target.value <= totalCount) {
                                        setLimitActive(e.target.value);
                                        loadData(filters, 1, e.target.value);
                                    }
                                }} type="number" />
                                <label>de {totalCount} registros</label>
                            </LimitsDiv>

                            <Pagination>
                                <Pagination.Prev disabled={pageActive > 1 ? false : true} onClick={() => {
                                    const p = pageActive > 1 ? (pageActive - 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }} />
                                {pages[pageActive - 1]}
                                {pages[pageActive]}
                                {pages[pageActive + 1]}
                                <Pagination.Next disabled={pageActive < pages.length ? false : true} onClick={() => {
                                    const p = pageActive < pages.length ? (pageActive + 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }}
                                />
                            </Pagination>
                        </>)
            }

        </Content>
    </Container>;
}

export default Transacoes;
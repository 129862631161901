import React, { useState, useEffect } from 'react';
import {
  FaUserCircle, FaLock, FaMapMarkerAlt, FaFileAlt, FaCreditCard, FaIdCard
} from 'react-icons/fa';

import { Container, BarItem } from './styles';

const icons = {
  file: <FaFileAlt size={16} />,
  payment: <FaCreditCard size={16} />,
  user: <FaUserCircle size={16} />,
  payer: <FaIdCard size={16} />,
  address: <FaMapMarkerAlt size={16} />,
  lock: <FaLock size={16} />,
}

function StepsIndicator(props) {
  const [steps, setSteps] = useState([true])

  useEffect(() => {
    const array = [];
    for (let i = 0; i < props.stepsDone; i++) {
      array.push(true);
    }
    setSteps(array)

    //eslint-disable-next-line
  }, [props])

  return <Container>
    {
      props.steps.map((item, i) => (
        <BarItem key={i} done={steps[i]} onClick={() => {
          i++
          if(i <= props.stepsDone && props.callbackChange){
            props.callbackChange(i)
          }
        }}>{icons[`${item}`]}</BarItem>
      ))
    }
  </Container>;
}

export default StepsIndicator;

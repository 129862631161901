import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    min-height: 100%;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    width: 60%;
    img{
        height: 80px;
    }
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    img{
        margin-right: 30px;
    }
    div{
        h1{
            text-align: left;
            font-size: 22px;
            font-weight: bold;
        }
        h2{
            text-align: left;
            font-size: 14px;
        }
    }


    @media(max-width: 700px){
        flex-direction: column;
        img{
            margin-bottom: 20px;
        }
       div{
        h1, h2{
            text-align: center;
        }
       }
    }
`;

export const LoadingDiv = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DivPayer = styled.div`
    margin: 20px;
    width: 100%;
    h3{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        text-align: left;
        border-bottom: 2px solid #c3c3c3;
    }
    label{
        margin: 2px;
    }

    div{
        display: flex;
        flex-direction: column;
    }
`;

export const DivItems = styled.div`
    width: 100%;
    background-color: #eee;
    border-radius: 5px;
    padding: 20px;
    h3{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        text-align: left;
        border-bottom: 2px solid #c3c3c3;
    }
`;

export const DivPayment = styled.div`
    margin: 20px;
    width: 100%;
    h3{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        text-align: left;
        border-bottom: 2px solid #c3c3c3;
    }
    
`;

export const DivPaymentContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    div{
        label{
            margin: 2px;
        }
        width: 50%;
        display: flex;
        flex-direction: column;
    }
`;

export const ItemRow = styled.div`
    display: flex;
    border-bottom: 1px solid #c3c3c3;
    justify-content: space-between;
    padding: 5px;

    @media(max-width: 700px){
        flex-direction: column;
    }
`;

export const LabelsItem = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.sm ? 20 : 60}%;
    label{
        margin: 0 10px;
    }
    p{
        margin: 2px 10px;
        word-break: break-all;
    }
    @media(max-width: 700px){
        width: 100%;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    text-align: center;
`;

export const DivError = styled.h3`
    margin: 100px 0pc;
`;

export const DownloadCRLVButton = styled.button`
    background-color: #28a745;
    color: #fff;
    width: auto;

    &:hover{
        background-color: ${darken(0.08, '#28a745')};
    }
`;

import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';
import './styles-sweet-alert2.css'

export default createGlobalStyle`
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Work+Sans:wght@400;500;700&display=swap'); */



* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus{
  outline: 0;
}
html, body, #root{
  height: 100%;
  /* background-color: #441188; */
  background-color: #fff;
}
body, span, label{
  color: #666;
  -webkit-font-smoothing: antialiased;
}
body, input, button{
  /* font: 14px 'Montserrat', sans-serif; */
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
}
h1, h2, h3, h4 {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color:  #666;
}

h1{
  font-size: 40px;
}

h2{
  font-size: 32px;
}

a{
  text-decoration: none;
  color:  #441188;
  font-weight: bold;
}
ul{
  list-style: none;
}

input{
  border: 1px solid #666;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  text-align: center;

  @media(max-width: 1024px){
    font-size: 16px;
  }
}

button{
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  background-color: #441188;
  margin: 10px 0;
  color: #fff;
  font-weight: bold;
  cursor: pointer;

  &:hover{
    background-color: ${lighten(0.08, '#441188')};
  }
}

.Toastify__toast {
  button{
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
    &:hover{
    background-color: transparent;
  }
  }
}

`;




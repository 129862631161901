import React, { useState, useEffect } from 'react';
import { Spinner, Breadcrumb, Table } from 'react-bootstrap';
import { FaInfoCircle, FaArrowDown, FaPaperPlane, FaTrash, FaBan, FaRedo } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Label } from '../../../../helpers/labels/labelStyles';
import { Container, Content, Card, CardItem, TableDiv, Button, BanButton, ButtonsDiv } from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiCidadetran from '../../../../services/cidadetran';
import { formatDateBarWithHours, formatPrice } from '../../../../helpers/format';

function DetalheCidadetran ({ match, history }) {
    const token = localStorage.getItem('token');
    const [file, setFile] = useState(Object);
    const [loading, setLoading] = useState(0);


    useEffect(() => {
        async function loadData() {
            setLoading(1);
            const res = await apiCidadetran.get(`/cidadetran/records/${match.params.guid}`, { headers: { Authorization: `Bearer ${token}` } })
                .catch(e => Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Erro ao buscar dados!',
                }));

            if (res && res.data) {
                setFile(res.data);
            }
            setLoading(0);
        }
        loadData();
        //eslint-disable-next-line
    }, [])

    async function download() {
        await apiCidadetran.get(`/cidadetran/download/${match.params.guid}`, { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
            Swal.fire({
                icon: 'error',
                title: 'Arquivo não encontrado',
                text: 'Aguarde alguns minutos e tente novamente!',
            })
        }).then((result) => {
            if (result && result.data) {
                window.open(result.data, '_blank').focus()
            }
            // return
            // console.log(result)
            // let element = document.createElement('a');
            // element.href = result.data;
            // element.target = '_blank';
            // // element.download = 'teste.txt';
            // element.click();
            // return
            // const { name, content } = result.data
            // let element = document.createElement('a');
            // element.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(content);
            // // element.target = '_blank';
            // element.download = name;
            // element.click();
        })
    }

    async function block(guid) {
        Swal.fire({
            title: "Bloquear Pagamento",
            html: 'Deseja bloquear o protocolo ' + guid + '?',
            showCancelButton: true,
            confirmButtonText: `Bloquear`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                await apiCidadetran.get(`/cidadetran/block/${guid}`, { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possível bloquear o pagamento.',
                    })
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Protocolo Bloqueado',
                    'Clique em "Recriar Arquivo" para atualizar a modificação.',
                    'success'
                )
                const tr = document.querySelectorAll('.protocol_' + guid);
                tr.forEach(each => {
                    each.style.pointerEvents = 'none';
                    each.style.backgroundColor = '#aaa';
                })
                const download_button = document.querySelector('#download_button')
                if (download_button) {
                    download_button.style.visibility = 'hidden'
                }
                const send_button = document.querySelector('#send_button')
                if (send_button) {
                    send_button.style.visibility = 'hidden'
                }
                const delete_button = document.querySelector('#delete_button')
                if (delete_button) {
                    delete_button.style.visibility = 'hidden'
                }
                const recriate_button = document.querySelector('#recriate_button')
                if (recriate_button) {
                    recriate_button.style.visibility = 'visible'
                }
            }
        })
    }

    async function enviarComprovantes() {
        Swal.fire({
            title: "Comprovantes",
            html: 'Deseja enviar os comprovantes? <br> (O Arquivo deve estar pago no cidadetran)',
            showCancelButton: true,
            confirmButtonText: `Enviar`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiCidadetran.get(`/receipt/${match.params.guid}`,
                    { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                        Swal.showValidationMessage('Erro ao enviar os comprovantes')
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Comprovantes disparados!',
                    '',
                    'success'
                )
            }
        })
    }

    async function recriarArquivo() {
        Swal.fire({
            title: "Recriar Arquivo",
            html: 'O Arquivo será atualizado para excluir pagamentos bloqueados (ou incluir desbloqueados)',
            showCancelButton: true,
            confirmButtonText: `Recriar`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiCidadetran.delete(`/cidadetran/${match.params.guid}?recreate=true`,
                    { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                        Swal.showValidationMessage('Erro ao recriar o arquivo')
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'O Arquivo está sendo recriado',
                    '',
                    'success'
                )
                history.push('/portal/cidadetran');
            }
        })
    }

    async function excluirArquivo() {
        Swal.fire({
            title: "Excluir",
            html: 'Deseja excluir este arquivo?',
            showCancelButton: true,
            confirmButtonText: `Excluir`,
            cancelButtonText: `Fechar`,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await apiCidadetran.delete(`/cidadetran/${match.params.guid}`,
                    { headers: { Authorization: `Bearer ${token}` } }).catch(error => {
                        Swal.showValidationMessage('Erro ao excluir o arquivo')
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Arquivo Excluído com sucesso!',
                    '',
                    'success'
                ).then((result) => {
                    window.location.href = '/portal/cidadetran'
                })
            }
        })
    }

    return <Container>
        <HeaderLogged />
        <Content>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal/cidadetran" exact>
                    <Breadcrumb.Item>Cidadetran</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Detalhe</Breadcrumb.Item>
            </Breadcrumb>
            {
                !loading ?
                    (<>
                        {
                            file && file.guid ?
                                (
                                    <>
                                        <Card>
                                            <h3>Descrição <FaInfoCircle color="#666" /></h3>
                                            <CardItem>
                                                <div>
                                                    <h4><strong>Nome do Arquivo: </strong>{file.file.name}</h4>
                                                    <h4><strong>Gerado Em: </strong>{formatDateBarWithHours((file.date))}</h4>
                                                    <h4><strong>Valor Total do Arquivo: </strong>{formatPrice(file.file.amount.afterFees)}</h4>
                                                    <h4><strong>Valor Total de Juros: </strong>{formatPrice(file.file.amount.afterFees - file.file.amount.beforeFees)}</h4>
                                                </div>
                                                <div>
                                                    <h4><strong>Status</strong>
                                                        {file.status && file.status === "receipts-sent" ? <Label color="#2eb82e">COMPROV. ENVIADOS</Label> : <Label color="#1d55db">GERADO</Label>}
                                                    </h4>
                                                    <h4><strong>Protocolos OK: </strong>{file.payments.new.total + file.payments.retry.total - file.payments.failed.total}</h4>
                                                    <h4 style={{color: file.payments.failed.total > 0 ? 'red' : ''}}><strong>Protocolos com Erro: </strong>{file.payments.failed.total}</h4>
                                                </div>
                                            </CardItem>
                                        </Card>
                                        <ButtonsDiv>
                                        {
                                            file.blockedPayments && file.blockedPayments.length > 0 ? (<>
                                                <Button id="recriate_button" type="button" onClick={recriarArquivo}><FaRedo style={{marginRight: 10}} />Recriar</Button>
                                            </>) : 
                                            (<>
                                            <Button id="download_button" type="button" onClick={download}><FaArrowDown style={{marginRight: 10}} />Download</Button>
                                            {
                                                file.status && file.status === "created" ? (<>
                                                    <Button id="send_button" type="button" color="#2eb82e" onClick={enviarComprovantes}><FaPaperPlane style={{marginRight: 10}} />Enviar Comprov.</Button>
                                                    <Button id="delete_button" type="button" color="#ff4d4d" onClick={excluirArquivo}><FaTrash style={{marginRight: 10}} />Excluir</Button>
                                                </>)
                                                : (<></>)
                                            }
                                                <Button id="recriate_button" style={{visibility: 'hidden'}} type="button" onClick={recriarArquivo}><FaRedo style={{marginRight: 10}} />Recriar</Button>
                                            </>)
                                        }
                                        </ButtonsDiv>
                                        <br/><br/><h1><strong>Protocolos OK</strong></h1>
                                        <TableDiv>
                                            <Table responsive="xl" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Protocolo</th>
                                                        <th>Renavam</th>
                                                        <th>Débito</th>
                                                        <th>Valor no Arquivo</th>
                                                        <th>Juros de Débitos</th>
                                                        <th>Ações</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        file.thrived.map(each => (
                                                            <tr key={each.protocol} class={`protocol_${each.protocol}`} style={ file.blockedPayments.includes(each.protocol) ? {pointerEvents: 'none', backgroundColor: '#aaa'} : {}}>
                                                            <td><Link to={`/portal/transacoes/${each.protocol}`}>{each.protocol}</Link></td>
                                                            <td>{each.renavam}</td>
                                                            <td>{each.type}</td>
                                                            <td>{formatPrice(each.total)}</td>
                                                            <td style={{color: each.extra > 0 ? 'red' : '', fontWeight: each.extra > 0 ? 'bold' : ''}}>{formatPrice(each.extra)}</td>

                                                            <td style={{textAlign: 'center', padding: '3px', display: 'block', margin: 'auto'}}>

                                                            <BanButton color="#ff4d4d" style={ file.status && file.status === "receipts-sent" ? {pointerEvents: 'none', backgroundColor: '#aaa'} : {}}
                                                                type="button" onClick={() => block(`${each.protocol}`)}><FaBan /></BanButton>
                                                            </td>


                                                            
                                                            
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </TableDiv>
                                        {
                                            file.erred.length ?
                                            (<><br/><br/><h1><strong>Protocolos com Erro</strong></h1>
                                            <TableDiv>
                                                <Table responsive="xl" striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Protocolo</th>
                                                            <th>Renavam</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            file.erred.map(each => (
                                                                <tr key={each.protocol}>
                                                                <td><Link to={`/portal/transacoes/${each.protocol}`}>{each.protocol}</Link></td>
                                                                <td>{each.renavam}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </TableDiv></>)
                                            : (<></>)
                                        }
                                    </>
                                )
                                :
                                (<h2>Arquivo não encontrado</h2>)
                        }
                    </>)
                    :
                    (<Spinner animation="border" variant="secondary" />)
            }
        </Content>
    </Container>;
}

export default DetalheCidadetran;
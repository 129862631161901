import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BarItem = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color:  ${props => props.done ? '#ee0066' : '#EEE'};
    svg{
      color: ${props => props.done ? '#fff' : '#666'} !important;
    }

    &:hover{
      background-color: #ee0066!important;
      svg{
        color: #FFF !important;
      }
    }


`;

import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    max-width: 550px;
`;

export const Cards = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    flex-wrap: wrap;
`;

export const CardItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 160px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    font-weight: bold;

    border-radius: 6px;
    border: solid 2px #441188;
    text-align: center;
    img{
        height: 50px;
    }

    span{
        margin: 10px 0px;
        font-size: 14px;
        color: #441188;
    }

    &:hover{
      border: 2px solid #EE0066;
      span{
        color: #EE0066;
      }
    }
`;

export const CacheForm = styled.form`
    margin-top: 20px;
    margin-bottom: 100px;
    width: 350px;
    button{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    select{
        width: 100%;
        border-radius: 4px;
        padding: 10px;
        margin: 10px 0;
        text-align-last:center;
    }
`;
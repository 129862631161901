import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background-color: #EEE;
  min-height: 100%;
`;

export const PagamentoProcessado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


export const LoadingSimulacao = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
height: 80vh;
img{
  color: #441188;
}
span{
  margin-top: 15px;
  font-size: 18px;
}
`;

export const ErrorSimulacao = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;

  span, h1{
    text-align: center;
  }

  span{
    font-size: 16px;
  }

`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1200px;

  margin-top: 50px;

  @media (max-width: 1300px) {
    width: 1000px;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 880px) {
    width: 800px;
  }

  @media (max-width: 768px) {
    width: 580px;
  }

  @media (max-width: 414px) {
    width: 100%;
  }

`;

export const Simulacao = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  width: 60%;

  @media (max-width: 1080px) {
    width: 86%;
  }



`;

export const SimulacaoHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 2px solid #c3c3c3;
  margin-bottom: 15px;
  h4 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  div{
    display: flex;
    align-items: center;
    color: #c3c3c3;

    span{
      font-size: 12px;
    }

    img {
      margin-right: 5px;
      height: 20px;
    }
  }
`;

export const Parcelas = styled.ul`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 400px;

  flex-wrap: wrap;
  align-items: center;

  input {
    margin-right: 10px;
  }

  @media (max-width: 414px) {
    flex-wrap: nowrap;
    height: 750px;
  }

`;

export const ParcelasMobile = styled.ul`
  display: flex;
  flex-direction: column;
  height: 240px;
  flex-wrap: nowrap;
  align-items: center !important;
  justify-content: center !important;


`;

export const LinkMaisOpcoes = styled.span`
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;

    &:hover{
      color: #441188;
    }
`;

export const ParcelaItem = styled.button.attrs((props) => ({
  ative: props.ative,
  alter: props.alter,
}))`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  width: 280px;
  background-color: #dadee0;
  border-radius: 4px;
  margin: 5px;
  height: 50px;
  svg {
        color: #441188;
      }

  img {
    margin-left: 5px;
  }

  @media (max-width: 1300px) {
    width: 280px;
    img{
      display: none;
    }
  }

  @media (max-width: 700px) {
    width: 180px;
  }

  @media (max-width: 414px) {
    width: 230px;
  }

  &:hover{
    span{
      color: #FFF;
    }
  }

  ${(props) => props.ative
    && css`
      background-color: rgba(46, 204, 113, 0.2) !important;
      border: 2px solid #2ecc71;
      span{
      /* color: #FFF; */
    }
      svg {
        color: #fff;
      }
    `}

  ${(props) => props.alter
    && css`
      background-color: #fff;
    `}
`;

export const PagarButton = styled.button`
  padding: 10px;
  width: auto;
  height: 50px;
  background: #28a745;
  &:hover{
    background-color: ${darken(0.08, '#28a745')};
  }

  @media (max-width: 1080px) {
    align-self: center;
    width: 100%;
    margin-right: 0px;
    font-size: 12px;
  }

`;

export const ColumnDebits = styled.div`
    display: flex;
    flex-direction: column; 
    width: 35%;

    @media (max-width: 1080px) {
    width: 86%;
  }
`;

export const DataInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 25px;
  width: 100%;

  img{
    height: 70px;
    width: 70px;
  }


`;


export const Debitos = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  div{
    width: 100%;

    label{
      margin-top: 5px;
      color: #212529;
      font-weight: bold;
    }
    p{
      word-break: break-all;
    }
    div{
      display: flex;
      div{
        display: flex;
        flex-direction: column;
      }
    }
  }



`;


export const TitleColumn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    font-size: 12px;

`;

export const BorderDiv = styled.div`
    margin: 10px 0;
    width: 100%;
    border-bottom: 2px solid #441188;
`;

export const TotalColumn = styled.div`
    width: 100%;
    /* padding: 20px; */
    /* margin-top: 10px; */
    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const SpanHonorario = styled.span`
    color: #28a745;
`;

export const SeparatorDiv = styled.div`
    width: 100%;
    border-bottom: 1px solid #eee;
    margin: 10px 0;
`;

export const TotalPagarSpan = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: #1f1fc3;
    
`;

export const DebitoRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #EEE;

  span{
      word-break: break-all;
    }

  div{
    display: flex;
    justify-content: space-between;

    @media(max-width: 414px){
      flex-direction: column;
    }
  }
`;


export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  /* background-color: #441188; */
  /* margin-top: 10px; */
  border-radius: 4px;
  /* border-top: 1px solid #eee; */

  span {
    
    font-weight: bold;
    font-size: 16px;
    /* text-transform: uppercase; */
  }
`;

export const PagamentoAprovado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 700px;
  margin-bottom: 40px;
  margin-top: 60px;
  text-align: center;

  img{
    height: 130px;
  }
  span{
    color: #441188;
    background-color: #F0FF0F;
  }

  h2{
    font-weight: bold;
    text-transform: uppercase;
    color: #441188;
    /* font-size: 40px; */
  }
  p{
    font-size: 22px;
  }

  @media (max-width: 414px){
    width: 300px;
    img {
    height: 80px;
   }
   h3{
    font-size: 30px;
   }
    p{
    font-size: 16px;
    }
  }
`;

export const H1Falha = styled.h1`
  color: #ee0066;
`;

export const LinkTryAgain = styled.a`
  margin-top: 20px;
  text-decoration: underline;

  &:hover{
    color: #3EA1B5;
  }
`;

export const LabelDesconto = styled.label`
  padding: 0 30px;

  @media (max-width: 1080px){
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

export const CupomDiv = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  input, button{
    width: 130px;
  }

  input{
    height: 41px;
    margin-right: 10px;
  }

  button{
    background-color: #441188;
    height: auto;
  }

  @media (max-width: 1080px){
    input, button{
    width: 80px;
  }
   }

`;

export const FooterSimulacao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 30px;

  @media (max-width: 1080px){
    flex-direction: column;
   }
`;

export const RemoverItens = styled.button`
    text-decoration: underline;
    color: #441188;
    width: auto;
    background-color: transparent;
    margin: 0px;
    &:hover{
        background-color: transparent;
        color: #ee0066;
    }
`;

export const AccordionDiv = styled.div`
    width: 100%;
`;

export const AccordionButton = styled.button`
    display: flex;
    color: #ee0066;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    &:hover{
        background-color: transparent;
    }
`;
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 414px) {
    margin-bottom: 100px;
    form{
    width: 280px !important;
   }
  }

  form{
    display: flex;
    flex-direction: column;
    width: 350px;

    span{
      margin-top: 10px;
      cursor: pointer;
      align-self: center;
      text-decoration: underline;
    }

    div{
      display: flex;
      justify-content: space-between;
      input{
        width: 170px;
        @media (max-width: 414px) {
          width: 135px !important;
        }
      }
    }


    input, button{
      margin: 5px 0;
    }
  }

`;

export const CartaoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #666;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  height: 80px;
  margin: 10px 0;

  img{
    height: 40px;
  }

  span{
    text-align: center;
  }

  &:hover{
    border: 2px solid #441188;
  }

  @media (max-width: 414px) {
     width: 280px !important;
  }


`;

export const NovoCartaoButton = styled.button`
  margin-top: 10px;
`;

export const CardImg = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 160px;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #C4C4C4;
  border-radius: 20px;

  img {
    height: 40px;
  }

  span{
    text-align: center;
    align-self: center;
    text-decoration: none;
    color: #484848;
    font-size: 12px;
    margin: 5px 0;
  }

  div{
    display: flex;
    justify-content: space-between;
  }
`;

export const RemoveCartaoButton = styled.button`
      margin: 5px 0;
      border-radius: 4px;
      background-color: #FF4D4D !important;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
`;


import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

function formatPrice(numero) {

    // if(typeof numero !== number){
    //   return " - "
    // }
    var texto = (Math.round(Math.round(numero)) / 100).toFixed(2).replace(".", ",");
    var stringComPontos = texto.replace(/(\d)(?=(?:[0-9]{3})+\b)/g, "$1.")
    return "R$ " + stringComPontos;

}

function formatPriceSimple(numero) {
  var texto = (Math.round(Math.round(numero)) / 100).toFixed(2).replace(".", ",");
  var stringComPontos = texto.replace(/(\d)(?=(?:[0-9]{3})+\b)/g, "$1.")
  return stringComPontos;
}

function formatDate(date) {
  // return format(parseISO(date), "d 'de' MM 'de' yyyy", {
  try {
    return format(parseISO(date), "yyyy - d 'de' MMMM", {
      locale: pt,
    });
  }
  catch (e) {
    return ''
  }
}

function formatDateBar(date) {
  try {
    return format(parseISO(date), "dd/MM/yyyy", {
      locale: pt,
    });
  }
  catch (e) {
    return ''
  }
}

function formatDateBarWithHours(date) {
  try {
    return format(parseISO(date), "dd/MM/yyyy HH:mm", {
      locale: pt,
    });
  }
  catch (e) {
    return 'Data inválida';
  }

}

const formatPriceOld = (price) => {
  try{
    return "R$ " + price.toString().replace('.', ',');
  }
  catch(e){
    return ''
  }


}


export { formatPrice, formatDate, formatDateBar, formatDateBarWithHours, formatPriceSimple, formatPriceOld }

import React, { useState, useEffect } from 'react';
import { Table, Popover, OverlayTrigger, Spinner, Pagination, Breadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import DatePicker from 'react-datepicker';
import { startOfDay, endOfDay } from 'date-fns';
import { FaFilter, FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
 
import {
    Container,
    Content,
    FiltroButton,
    InputsDiv,
    Input,
    AplicarButton,
    LimparFiltro,
    DateDiv,
    LimitsDiv,
    TableDiv,
    TotalPorStatus,
    RowFilter
} from './styles';
import HeaderLogged from '../../../../components/HeaderLogged';
import apiRecurrences from '../../../../services/api-recurrences';
import { formatDateBarWithHours, formatPrice, formatPriceSimple } from '../../../../helpers/format';
import { renderLabelStatus } from '../../../../helpers/labels/statusTransaction';
import { renderStatusAction } from '../../../../helpers/labels/renders';

function Recorrencia() {
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageActive, setPageActive] = useState(1);
    const [limitActive, setLimitActive] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPorStatus, setTotalPorStatus] = useState([]);

    //filters
    const [filters, setFilters] = useState("");
    const [protocol, setProtocol] = useState("");
    const [startDatePicker, setStartDatePicker] = useState();
    const [planName, setPlanName] = useState("");
    const [endDatePicker, setEndDatePicker] = useState("");
    const [actionSelected, setActionSelected] = useState("");
    const [statusSelected, setStatusSelected] = useState("");
    const filtroInicial = `&dt_tran_ini=${startOfDay(new Date()).toISOString()}&dt_tran_fin=${endOfDay(new Date()).toISOString()}`;


    useEffect(() => {
        // primeiro loading
        setFilters(filtroInicial);
        loadData(filtroInicial, pageActive, limitActive);
        //eslint-disable-next-line
    }, [])

    const popover = (
        <Popover id="popover-basic">
            <Popover.Title as="h3">Filtros</Popover.Title>
            <Popover.Content>
                <Input value={protocol} onChange={e => setProtocol(e.target.value)} placeholder="Protocolo" />
                <InputsDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Início" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={startDatePicker} onChange={date => setStartDatePicker(date)} />
                    </DateDiv>
                    <DateDiv>
                        <DatePicker placeholderText="Fim" maxDate={new Date()} dateFormat="dd/MM/yyyy" selected={endDatePicker} onChange={date => setEndDatePicker(date)} />
                    </DateDiv>
                </InputsDiv>
                <Input value={planName} onChange={e => setPlanName(e.target.value)} placeholder="Nome do plano" />
                <InputsDiv>
                    <select value={actionSelected} onChange={e => setActionSelected(e.target.value)}>
                        <option value=""></option>
                        <option value="subscription">Assinatura</option>
                        <option value="validate">Validação</option>
                    </select>
                    <select value={statusSelected} onChange={e => setStatusSelected(e.target.value)} >
                        {renderStatusOptions(actionSelected)}
                    </select>
                </InputsDiv>

                <LimparFiltro onClick={() => limparFiltros()}>limpar filtros</LimparFiltro>
                <AplicarButton onClick={() => aplicarFiltros()}>Aplicar</AplicarButton>
            </Popover.Content>
        </Popover>
    );

    async function loadSum(filters) {
        const res = await apiRecurrences.get(`/ecommerce/recurrence/sum?${filters}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e =>  Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao buscar dados!',
              }));

        const { data } = res;
        const array = []
        for (var property in data) {
            array.push({ action: property, value: data[property] })
        }
        setTotalPorStatus(array);
    }

    function limparFiltros() {
        setFilters("");
        setProtocol("");
        setStartDatePicker("");
        setEndDatePicker("");
        setStatusSelected("");
        setActionSelected("");
        setPlanName("");
        loadData(filtroInicial, 1, limitActive);
    }

    function aplicarFiltros() {
        setLoading(1);

        let filter = "";

        if (protocol) {
            filter += `&guid=${protocol}`
        }
        if (planName) {
            filter += `&planName=${planName}`
        }
        if (actionSelected && statusSelected) {
            filter += `&action=${actionSelected}&status=${statusSelected}`
        }
        if (startDatePicker && endDatePicker) {
            filter += `&dt_tran_ini=${startDatePicker.toISOString()}&dt_tran_fin=${endOfDay(endDatePicker).toISOString()}`
        }

        setFilters(filter);
        setPageActive(1);
        loadData(filter, 1, limitActive);
    }

    function renderStatusOptions(action) {
        switch (action) {
            case 'subscription': return (<>
                <option value=""></option>
                <option value="approved">Aprovado</option>
                <option value="pending">Pendente</option>
                <option value="declined">Recusado</option>
                <option value="error">Erro</option>
            </>);
            case 'validate': return (<>
                <option value=""></option>
                <option value="declined">Recusado</option>
                <option value="error">Erro</option>
            </>);
            default: break;
        }
    }

    async function loadData(filters, pageNumber, limit) {
        setLoading(1);
        const res = await apiRecurrences.get(`/ecommerce/recurrence?limit=${limit}&page=${pageNumber}${filters}`, { headers: { Authorization: `Bearer ${token}` } })
            .catch(e => Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erro ao buscar dados!',
              }));

        if (res && res.data && res.data.records) {
            setRecords(res.data.records);
            setTotalCount(res.data.totalCount);
            const pagesData = [];
            const pagesLimit = Math.ceil(res.data.totalCount / res.data.limit)
            for (let i = 1; i <= pagesLimit; i++) {
                pagesData.push(<Pagination.Item
                    key={i}
                    active={pageNumber === i}
                    onClick={() => {
                        setPageActive(i);
                        loadData(filters, i, limit);
                    }}>{i}</Pagination.Item>)
            }
            setPages(pagesData);
            loadSum(filters)
            // calculaTotalPorStatus(res.data.records);

        }
        setLoading(0);
    }

    return <Container>
        <HeaderLogged />
        <Content>
            <h1>Recorrência</h1>
            <Breadcrumb>
                <LinkContainer to="/" exact>
                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                </LinkContainer>
                <LinkContainer to="/portal" exact>
                    <Breadcrumb.Item>Portal</Breadcrumb.Item>
                </LinkContainer>
                <Breadcrumb.Item active>Recorrência</Breadcrumb.Item>
            </Breadcrumb>
            <RowFilter>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <FiltroButton type="button"><FaFilter color="#fff" />Filtros</FiltroButton>
                </OverlayTrigger>
                <TotalPorStatus
                    visible={totalPorStatus.length > 0 ? true : false}
                >
                    <span><FaMoneyBillAlt size={16} color="#2ecc71" /></span>
                    {
                        totalPorStatus.map(item =>
                            <span key={item.action}><strong>{renderStatusAction(item.action)}:</strong> {formatPriceSimple(item.value)}</span>)
                    }
                </TotalPorStatus>
            </RowFilter>
            {
                loading ?
                    (<Spinner animation="border" variant="secondary" />)
                    :
                    (
                        <>

                            <TableDiv>
                                <Table responsive="xl" striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Protocolo</th>
                                            <th>Data Transação</th>
                                            <th>Plano</th>
                                            <th>Valor Pago</th>
                                            <th>Parcelas</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            records.map(record => (
                                                <tr key={record.guid}>
                                                    <td><Link to={`/portal/transacoes/${record.guid}`}>{record.guid}</Link></td>
                                                    <td>{formatDateBarWithHours(record.date)}</td>
                                                    <td>{record.payment.planName}</td>
                                                    <td>{formatPrice(record.payment.amount)}</td>
                                                    <td>{record.payment.installments}x</td>
                                                    <td>{
                                                        Array.isArray(record.status) &&
                                                            record.status ?
                                                            renderLabelStatus(record.status.filter(item => item.prominent === true)[0])
                                                            :
                                                            (<></>)

                                                    }</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </TableDiv>
                            <LimitsDiv>
                                <label>visualizando</label>
                                <input value={limitActive} onChange={e => {
                                    if (e.target.value > 0 && e.target.value <= totalCount) {
                                        setLimitActive(e.target.value);
                                        loadData(filters, 1, e.target.value);
                                    }
                                }} type="number" />
                                <label>de {totalCount} registros</label>
                            </LimitsDiv>

                            <Pagination>
                                <Pagination.Prev disabled={pageActive > 1 ? false : true} onClick={() => {
                                    const p = pageActive > 1 ? (pageActive - 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }} />
                                {pages[pageActive - 1]}
                                {pages[pageActive]}
                                {pages[pageActive + 1]}
                                <Pagination.Next disabled={pageActive < pages.length ? false : true} onClick={() => {
                                    const p = pageActive < pages.length ? (pageActive + 1) : pageActive
                                    setPageActive(p)
                                    loadData(filters, p, limitActive);
                                }}
                                />
                            </Pagination>
                        </>)
            }

        </Content>

    </Container>;
}

export default Recorrencia;
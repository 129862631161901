export default function user(state = [], action) {
  switch (action.type) {
    case '@user/USER_LOGGED':
      return {
        ...state,
        signed: action.signed
      };
    case '@user/USER_DATA':
      return {
        ...state,
        dados: action.dados
      };
    default:
      return state;
  }

}
